import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import assets from 'src/assets'
import Loader from 'src/components/Loader'
import Typography from 'src/components/Typography'
import Drawer from 'src/components/ui/drawer'
import { TextField } from 'src/components/ui/textfield'
import useIsMobile from 'src/hook/useIsMobile'
import { useAppDispatch } from 'src/store'
import { setSelectedBrand } from 'src/store/brand/brand.slice'
import { Brand, BrandsWithCreativeCount, UserAssetWithProvider } from 'src/store/brand/brand.type'
import ImagePicker from '../Creative/ImagePicker'

interface BrandSelectorPropsType {
  isDrawerOpen: boolean
  brandList: BrandsWithCreativeCount[]
  handleClose: () => void
  isLoading: boolean
  selectedBrand: Brand | null
  handleChangeBrandName: (item: Brand | null) => void
  // setSelectedImage: (name: string) => void
  isBrand: boolean
  // handleRefetchBrand: () => void
}

const BrandSelector = ({
  isDrawerOpen,
  brandList,
  handleClose,
  isLoading,
  selectedBrand,
  handleChangeBrandName,
  // setSelectedImage,
  isBrand
}: BrandSelectorPropsType) => {
  const dispatch = useAppDispatch()
  const [isUploadImage, setIsUploadImage] = useState(false)
  const [aspectRatio, setAspectRatio] = useState<number>()
  const [brand, setBrand] = useState<string>()
  const isMobile = useIsMobile()
  const [userAsset, setUserAsset] = useState<UserAssetWithProvider | null>(null)
  // cnst [filterBrandList,]

  const handleOnChange = useCallback(
    debounce((value: string) => {
      setBrand(value)
    }, 500),
    []
  )

  useEffect(() => {
    if (!isBrand) {
      if (selectedBrand) {
        dispatch(setSelectedBrand(selectedBrand))
        setIsUploadImage(true)
      } else {
        setIsUploadImage(false)
      }
    } else {
      setIsUploadImage(false)
    }
  }, [selectedBrand, isBrand])

  useEffect(() => {
    if (userAsset && !isDrawerOpen) {
      setTimeout(() => {
        handleChangeBrandName(selectedBrand)
        if (!isBrand) {
          setUserAsset(null!)
        }
      }, 1000)
    }
  }, [userAsset, isDrawerOpen])

  const filteredBrands =
    brandList?.filter(i => i.name.toLowerCase().includes(brand?.toLowerCase() || '')) || []

  return (
    <Drawer
      size={isMobile ? '100%' : '50%'}
      header={!isUploadImage ? 'SELECT BRAND' : `Upload Image ${selectedBrand?.name}`}
      isOpen={isDrawerOpen}
      onClose={() => {
        handleClose()
      }}>
      {isLoading ? (
        <div className="h-[calc(100vh-136px)] flex items-center justify-center">
          <Loader background="var(--cream)" />
        </div>
      ) : (
        <>
          {isUploadImage ? (
            <div className="mx-[10px] my-[10px]">
              <ImagePicker
                handleClose={handleClose}
                setUserAsset={setUserAsset}
                userAsset={userAsset}
                setAspectRatio={setAspectRatio}
                aspectRatio={aspectRatio}
              />
            </div>
          ) : (
            <div className="flex  flex-col gap-[20px] mx-4">
              <div className="flex">
                <TextField
                  fullWidth
                  variant={'rounded'}
                  placeholder="Search brand"
                  className="bg-white"
                  id="fonts"
                  rightIcon={
                    <img alt="search-icon" src={assets.svgs.SEARCH} className="w-5 h-5 mr-2 " />
                  }
                  handleOnChange={(_, value) => handleOnChange(value)}
                />
              </div>
              <div className='flex  flex-col className="flex  flex-col gap-[20px] max-h-[calc(100vh-165px)]   overflow-scroll scrollbar-hide'>
                {filteredBrands && filteredBrands?.length > 0 ? (
                  <>
                    {isBrand && (
                      <div
                        className="flex gap-2 w-fit cursor-pointer"
                        onClick={() => {
                          handleChangeBrandName(null)
                          handleClose()
                        }}>
                        <div className="relative flex items-center min-h-[50px]">
                          <img
                            src={assets.svgs.CREATIVE_AD}
                            alt="brand_logo"
                            className="w-[70px] h-[55px] p-[10px] rounded-[5px] bg-white object-contain"
                          />
                          {!selectedBrand && (
                            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-[-6px]">
                              <img
                                src={assets.svgs.GREEN_TICK}
                                alt="tick"
                                className="w-[60%] m-auto"
                              />
                            </div>
                          )}
                        </div>
                        <Typography size={12} className="ml-1 font-[700] self-center">
                          All
                        </Typography>
                      </div>
                    )}
                    {filteredBrands.map(item => {
                      return (
                        <div
                          className="flex gap-2 w-fit cursor-pointer"
                          key={item.uuid}
                          onClick={() => {
                            if (!isBrand) {
                              if (selectedBrand) {
                                handleChangeBrandName(item)
                                setIsUploadImage(true)
                              } else {
                                handleChangeBrandName(item)
                              }
                            } else {
                              handleChangeBrandName(item)
                              handleClose()
                            }
                          }}>
                          <div className="relative flex items-center min-h-[50px]">
                            <img
                              src={item.logoAssets[0].presignedUrl}
                              alt="brand_logo"
                              className="w-[70px] h-[55px] object-contain"
                            />
                            {selectedBrand && selectedBrand.uuid === item.uuid && (
                              <div className="absolute left-1/2 transform -translate-x-1/2 bottom-[-6px]">
                                <img
                                  src={assets.svgs.GREEN_TICK}
                                  alt="tick"
                                  className="w-[60%] m-auto"
                                />
                              </div>
                            )}
                          </div>
                          <Typography size={12} className="font-[700] self-center">
                            {item.name}
                          </Typography>
                        </div>
                      )
                    })}
                  </>
                ) : (
                  <Typography size={12} className="font-[700] self-center">
                    No brand found
                  </Typography>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </Drawer>
  )
}

export default BrandSelector
