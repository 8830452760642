import { useFormik } from 'formik'
import { FC, SVGProps, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import assets from 'src/assets'
import FontField from 'src/components/FontField'
import HeaderTitle from 'src/components/HeaderTitle'
import ImageDropwdown from 'src/components/ImageDropdown'
import Loader from 'src/components/Loader/index.tsx'
import OptionalIcon from 'src/components/OptionalIcon'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button.tsx'
import Drawer from 'src/components/ui/drawer'
import IconButton from 'src/components/ui/iconButton'
import { TextField } from 'src/components/ui/textfield'
import { CREATIVE_ICONS_ARRAY } from 'src/constants'
import { AppRoutes } from 'src/constants/routes'
import useGetBrandById from 'src/hook/useGetBrandById'
import useHandleErrorMessage from 'src/hook/useIsErrorMessage'
import useIsMobile from 'src/hook/useIsMobile'
import { useAppDispatch } from 'src/store'
import { UserAsset, UserAssetWithProvider } from 'src/store/brand/brand.type'
import {
  useCreateCreativesMutation,
  useLazyGetGenerateCreativeTextQuery
} from 'src/store/creatives/creative.api'
import { setCurrentCreative } from 'src/store/creatives/creative.slice'
import { Creative, generateAdCopyTypes } from 'src/store/creatives/creative.type'
import { RtkError } from 'src/store/redux.types'
import { useStateSelector } from 'src/store/root.reducer'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { trackEvent } from '../../amplitude'
import { events } from '../../amplitude/data.ts'
import ImagePicker from './ImagePicker'
import PreviewCreative from './PreviewCreative'
// import { CallBackProps, STATUS } from 'react-joyride'
import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'
import { setCurrentOnboardingCreativeStep } from 'src/store/onboarding/onboarding.slice.ts'
import { addCookie, fetchCookie, isGuest } from 'src/utils/cookies.ts'
import Tour from '../../Tour'
import { creativeSteps, defaultOnboardingState } from '../../data/onboarding.ts'

enum section {
  LOGO = 'logo',
  FOREGROUND = 'foreground'
}

export interface generateAdCopyProps {
  id: number
  headline: string
  punchline: string
}

const GenerateCreative = () => {
  const { brandId } = useParams()
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const handleErrorMessage = useHandleErrorMessage()
  const dispatch = useAppDispatch()
  const { getBrandData } = useGetBrandById()
  const creativeDetails = useStateSelector(state => state.creativeSlice)
  const onboardingData = useStateSelector(state => state.OnboardingSlice)
  const brandData = useStateSelector(state => state.brandSlice)
  const defaultLogo = useMemo(
    () => brandData?.selectedBrand?.logoAssets && brandData.selectedBrand.logoAssets[0],
    [brandData]
  )
  const [onboardingStep, setOnboardingStep] = useState(onboardingData.currentCreativeStep ?? 0)
  const [run, setRun] = useState<boolean>(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedLogo, setSelectedLogo] = useState<string>('')
  const [createCreative] = useCreateCreativesMutation()
  const [selectedComp, setSelectedComp] = useState<string>()
  const [selectedId, setSelectedId] = useState<number>(0)
  const ProjectName = useStateSelector(state => state.creativeSlice.projectName)
  const [logoOption, setLogoOption] = useState<UserAsset[]>([])
  const [selectedAdCopyId, setSelectedAdCopyId] = useState<number | null>(null)
  const [foregroundImageAsset, setForegroundImageAsset] = useState<UserAssetWithProvider | null>(
    null
  )
  const [isAlternativeCreativeOpen, setAlternativeCreativeOpen] = useState(false)
  const [getGenerateCreativeTextQuery] = useLazyGetGenerateCreativeTextQuery()
  const [generateText, setGenerateText] = useState<generateAdCopyTypes[]>([])
  const [aspectRatio, setAspectRatio] = useState<number>()
  const [isLoadingAdCopy, setIsLoadingAdCopy] = useState(false)
  const onBoardingCookie = fetchCookie('onboarding') ?? ''
  const onboarding = onBoardingCookie ? JSON.parse(onBoardingCookie) : defaultOnboardingState
  const { values, setFieldValue, errors, touched, setFieldTouched, isValid, handleSubmit } =
    useFormik({
      initialValues: {
        // headline: '',
        // punchline: '',
        // ctaText: '',
        // logoAssetId: '',
        // foregroundAssetId: '',
        // ctaIcon: '',
        // aspectRatio: 1,
        aspectRatio: 1,
        ctaIcon: creativeDetails?.currentCreative?.ctaIcon as string,
        ctaText: creativeDetails?.currentCreative?.ctaText as string,
        foregroundAssetId: (creativeDetails?.currentCreative?.foregroundAssetId as string) ?? '',
        headline: creativeDetails?.currentCreative?.headline as string,
        logoAssetId: defaultLogo?.uuid ?? '',
        punchline: creativeDetails?.currentCreative?.punchline as string
      },
      validationSchema: toFormikValidationSchema(
        z.object({
          headline: z.string({ required_error: 'Please enter headline.' }),
          punchline: z.string({ required_error: 'Please enter punchline.' }),
          ctaText: z.string({ required_error: 'Please enter CTA.' }),
          logoAssetId: z.string({ required_error: 'Please select logo.' }),
          foregroundAssetId: z.string({ required_error: 'Please select foreground image.' }),
          ctaIcon: z.optional(z.string().nullable()),
          aspectRatio: z.optional(z.number().nullable())
        })
      ),
      onSubmit: values => {
        if (ProjectName !== '') {
          const updateValues = Object.assign({ projectName: ProjectName }, values)
          createCreative({ body: updateValues as Creative, id: brandId as string })
            .then(res => {
              if ('data' in res) {
                dispatch(setCurrentCreative(res.data))
                navigate(AppRoutes.CREATIVE_VARIANTS(String(res.data.uuid)), { replace: true })
              }
            })
            .catch((error: RtkError) => {
              if (error) {
                // dispatch(
                //   setSnackbar({ severity: SnackbarType.ERROR, message: error.data.error.message })
                // )
                handleErrorMessage(error.data.error.message, true)
              }
            })
        } else {
          // dispatch(
          //   setSnackbar({ severity: SnackbarType.ERROR, message: 'Please Enter Project Name' })
          // )
          handleErrorMessage('Please Enter Project Name', true)
        }
      },
      enableReinitialize: true,
      validateOnMount: true
    })

  const handleChange = (key: string, value: string | FC<SVGProps<SVGSVGElement>> | number) => {
    setFieldTouched(key, true)
    setFieldValue(key, value)
  }
  const handleClose = () => {
    setIsDrawerOpen(false)
    // if (selectedComp !== section.LOGO) {

    setOnboardingStep(onboardingData.currentCreativeStep)
    isGuest() && onboarding.creative && setRun(true)
    // }
  }
  useEffect(() => {
    if (foregroundImageAsset) {
      handleChange('foregroundAssetId', foregroundImageAsset.uuid)
    }
  }, [foregroundImageAsset])

  useEffect(() => {
    if (selectedLogo) {
      const selectedLogoId = logoOption.find(i => i.presignedUrl === selectedLogo)
      if (selectedLogoId?.uuid) {
        handleChange('logoAssetId', selectedLogoId.uuid)
      }
      trackEvent(events.AD_CREATION_LOGO_SELECTION_SUCCESS, {
        session_type: isGuest() ? 'guest' : 'registered',
        brand_id: brandId,
        project_name: creativeDetails.projectName
      })
    }
  }, [selectedLogo])

  useEffect(() => {
    if (brandId) {
      getBrandData(brandId).then(res => {
        res?.logoAssets && setLogoOption(res.logoAssets)
      })
    }
  }, [brandId])

  useEffect(() => {
    if (aspectRatio) {
      handleChange('aspectRatio', aspectRatio)
    }
  }, [aspectRatio])

  useEffect(() => {
    if (defaultLogo?.presignedUrl) {
      setSelectedLogo(defaultLogo?.presignedUrl)
    }

    if (defaultLogo?.uuid) {
      setFieldValue('logoAssetId', defaultLogo.uuid)
    }
  }, [defaultLogo])

  useEffect(() => {
    if (isGuest() && onboarding.creative) {
      onboardingData.currentCreativeStep > 0
        ? setOnboardingStep(onboardingData.currentCreativeStep)
        : 0
      setRun(true)
    }
    trackEvent(events.AD_CREATION_VIEWED, {
      brand_id: brandId,
      session_type: isGuest() ? 'guest' : 'registered'
    })
  }, [])

  useEffect(() => {
    // on key press listener
    function listener(): (this: Window, ev: globalThis.KeyboardEvent) => any {
      return e => {
        if (e.key === 'Escape') {
          setIsDrawerOpen(false)
        }
      }
    }

    window.addEventListener('keydown', listener())
    return () => {
      window.removeEventListener('keydown', listener())
    }
  }, [])

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const id = e.target.name
    const value = e.target.value

    trackEvent(events.AD_CREATION_TEXT_FIELD_ADDED, {
      text_field: id,
      text_length: value.length,
      session_type: isGuest() ? 'guest' : 'registered',
      value: value,
      brand_id: brandId,
      project_name: creativeDetails.projectName
    })
  }

  const handleGenerateAlternateAdCopy = async () => {
    setRun(false)
    setIsLoadingAdCopy(true)
    setSelectedAdCopyId(null)
    setAlternativeCreativeOpen(true)
    trackEvent(events.AD_CREATION_ALTERNATE_AD_COPY_REQUESTED, {
      original_adCopyPrimaryText: values?.headline,
      session_type: isGuest() ? 'guest' : 'registered',
      original_adCopySecondaryText: values?.punchline
    })
    const body = {
      headline: values.headline,
      punchline: values.punchline
    }
    await getGenerateCreativeTextQuery({ body }).then(res => {
      setIsLoadingAdCopy(false)
      if (res.data) {
        setGenerateText(res.data)
      }
    })
  }

  const handleChangeAdCopy = (i: number) => {
    setSelectedAdCopyId(i)
  }

  const handleCallback = (data: CallBackProps) => {
    const { action, index, type, status } = data

    if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      setOnboardingStep(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setOnboardingStep(0)
      setRun(false)
      addCookie('onboarding', JSON.stringify({ ...onboarding, creative: false }))
    }
  }

  // useEffect(() => {
  //   const foregroundImage = creativeDetails?.currentCreative?.foregroundImageUrl
  //   if (foregroundImage) {
  //     setForegroundImageAsset(foregroundImage)
  //   }
  // }, [brandData])

  useEffect(() => {
    if (creativeDetails.currentCreative && creativeDetails.currentCreative.foregroundImageUrl) {
      const newAsset: UserAssetWithProvider = {
        uuid: creativeDetails.currentCreative.foregroundAssetId ?? '',
        presignedUrl: creativeDetails.currentCreative.foregroundImageUrl,
        provider: '',
        brandId: null
      }

      setForegroundImageAsset(newAsset)
    }
  }, [creativeDetails.currentCreative])

  return (
    <>
      <Drawer
        id="eacel_onboarding_step_six"
        size={
          selectedComp === section.LOGO ? (isMobile ? '100%' : '40%') : isMobile ? '100%' : '50%'
        }
        header={
          selectedComp === section.LOGO ? (
            <HeaderTitle primaryName="SELECT" secondaryName="LOGO" />
          ) : (
            ''
          )
        }
        isOpen={isDrawerOpen}
        onClose={handleClose}>
        {selectedComp === section.LOGO ? (
          <ImageDropwdown
            options={logoOption}
            setSelectedImage={val => {
              setSelectedLogo(val)
              setOnboardingStep(onboardingData.currentCreativeStep + 1)
              isGuest() && onboarding.creative && setRun(true)
            }}
            selectedImage={selectedLogo}
            handleClose={handleClose}
          />
        ) : (
          <ImagePicker
            handleClose={handleClose}
            setUserAsset={val => {
              setForegroundImageAsset(val)
              if (isValid) {
                setOnboardingStep(onboardingData.currentCreativeStep + 1)
                setOnboardingStep(prev => prev + 1)
              }
              isGuest() && onboarding.creative && setRun(true)
            }}
            userAsset={foregroundImageAsset}
            setAspectRatio={setAspectRatio}
            aspectRatio={aspectRatio}
          />
        )}
      </Drawer>
      <Drawer
        size={isMobile ? '100%' : '40%'}
        header={'CHOOSE YOUR FAVOURITE AD COPY'}
        isOpen={isAlternativeCreativeOpen}
        onClose={() => {
          isGuest() && onboarding.creative && setRun(true)
          setAlternativeCreativeOpen(false)
        }}>
        <>
          {isLoadingAdCopy ? (
            <div className="mt-10">
              <Loader background="none" />
            </div>
          ) : (
            <>
              <div className="flex flex-col ">
                {generateText.length > 0 ? (
                  generateText.map((item, i) => (
                    <label
                      key={i}
                      className="inline-flex cursor-pointer items-center mt-2 gap-0 sm:gap-[26px] relative min-h-[67px]">
                      <input
                        id={item.headline + i}
                        type="checkbox"
                        className="mr-3 cursor-pointer appearance-none   rounded-full checked:bg-main-secondary w-[25px] h-[25px]"
                        value={i}
                        checked={selectedAdCopyId === i}
                        onChange={() => handleChangeAdCopy(i)}
                        style={{ border: '2px solid rgba(76, 116, 117, 0.4)' }}
                      />
                      {selectedAdCopyId === i && (
                        <label id={item.headline} className="absolute left-[5px] cursor-pointer">
                          <img src="/assets/svgs/tick.svg" alt="tick" />
                        </label>
                      )}
                      <div className="flex flex-col gap-1">
                        <Typography
                          size={14}
                          className="font-[500] leading-[18px] text-text-primary">
                          {item.headline}
                        </Typography>
                        <Typography
                          size={12}
                          className="font-[300] leading-[18px] text-text-primary">
                          {item.punchline}
                        </Typography>
                      </div>
                    </label>
                  ))
                ) : (
                  <Typography size={14} className="font-[600] leading-[18px] text-text-primary">
                    No data found
                  </Typography>
                )}
              </div>
              <Button
                label="SAVE CHANGES"
                className="w-full ml-2 px-5 mb-3 mt-5 align-bottom"
                disabled={selectedAdCopyId === null}
                onClick={() => {
                  trackEvent(events.AD_CREATION_ALTERNATE_AD_COPY_SELECTED, {
                    session_type: isGuest() ? 'guest' : 'registered',
                    selected_alternativePrimaryText:
                      generateText[selectedAdCopyId as number].headline,
                    selected_alternativeSecondaryText:
                      generateText[selectedAdCopyId as number].punchline
                  })
                  handleChange('headline', `${generateText[selectedAdCopyId as number].headline}`)
                  handleChange('punchline', `${generateText[selectedAdCopyId as number].punchline}`)
                  setAlternativeCreativeOpen(false)
                  isGuest() && onboarding.creative && setRun(true)
                }}
              />
            </>
          )}
        </>
      </Drawer>
      <form id="creative-form" name="creative-form" onSubmit={handleSubmit}>
        <div className="relative flex flex-col-reverse lg:grid   gap-4 lg:grid-cols-[70fr,30fr] bg-secondary-primary pl-[30px] pr-[40px] py-[40px] min-h-[705px] rounded-lg">
          <div className=" flex flex-col ">
            <div id="eacel_onboarding_step_two" className="flex flex-col gap-[10px] ">
              <Typography size={14} className="font-[900] leading-[18px] text-text-primary">
                AD COPY
              </Typography>

              <div className="flex items-center gap-[10px]">
                <TextField
                  placeholder="type your headline here"
                  id="headline"
                  name="headline"
                  className="bg-[transparent]"
                  showCount={true}
                  textLimit={30}
                  style={{ backgroundColor: 'transparent' }}
                  width={isMobile ? '100%' : '75%'}
                  value={values?.headline?.trimStart()}
                  onBlur={e => handleBlur(e)}
                  handleOnChange={(key, value) => handleChange(key, value)}
                  errorText={touched.headline && errors.headline}
                />
              </div>

              <div className="flex items-center gap-[10px]">
                <TextField
                  placeholder="type your punchline here"
                  id="punchline"
                  name="punchline"
                  className="bg-[transparent]"
                  showCount={true}
                  textLimit={90}
                  style={{ backgroundColor: 'transparent', width: '100%' }}
                  width={isMobile ? '100%' : '75%'}
                  value={values?.punchline?.trimStart()}
                  onBlur={e => handleBlur(e)}
                  handleOnChange={(key, value) => handleChange(key, value)}
                  errorText={touched.punchline && errors.punchline}
                />
                {/* <Tooltip text="COMING SOON" isCreative={true}>
                  <img
                    className="cursor-pointer relative"
                    style={{
                      bottom: touched.punchline && errors.punchline ? '12px' : '0px'
                    }}
                    src={assets.svgs.AIGENERATER}
                    alt="AI-text"
                  />
                </Tooltip> */}
              </div>

              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => {
                  if (values.headline !== '' && values.punchline !== '') {
                    handleGenerateAlternateAdCopy()
                  }
                }}>
                <img
                  className="cursor-pointer relative"
                  src={assets.svgs.AIGENERATER}
                  alt="AI-text"
                />

                <Typography
                  size={14}
                  className={
                    values.headline !== '' && values.punchline !== ''
                      ? 'font-[600] leading-[18px] text-text-primary'
                      : 'font-[600] leading-[18px] text-text-secondary'
                  }>
                  Generate Alternative Ad Copy
                </Typography>
              </div>
            </div>

            <div id="eacel_onboarding_step_three" className={'flex flex-col mt-[40px]'}>
              <div className="flex flex-col gap-[10px] ">
                <Typography size={14} className="font-[900] leading-[18px] text-text-primary">
                  CTA
                </Typography>
                <div className="flex items-center gap-[10px]">
                  <TextField
                    placeholder="call to action copy"
                    id="ctaText"
                    name="ctaText"
                    className="bg-[transparent]"
                    showCount={true}
                    textLimit={10}
                    style={{ backgroundColor: 'transparent' }}
                    width={isMobile ? '100%' : '75%'}
                    onBlur={e => handleBlur(e)}
                    value={values?.ctaText?.trimStart()}
                    errorText={touched.ctaText && (errors.ctaText as string)}
                    handleOnChange={(key, value) => handleChange(key, value)}
                  />
                  {/* <Tooltip text="COMING SOON" isCreative={true}>
                  <img
                    className="cursor-pointer relative"
                    src={assets.svgs.AIGENERATER}
                    alt="AI-text"
                    style={{ bottom: touched.ctaText && errors.ctaText ? '12px' : '0px' }}
                  />
                </Tooltip> */}
                </div>
              </div>

              <div className="flex flex-col gap-2 mt-[10px]">
                <Typography size={14} className="font-[300] italic">
                  select icon (optional)
                </Typography>

                <div className="grid gap-[20px] grid-cols-[repeat(4,50px)] sm:grid-cols-[repeat(6,50px)] ">
                  {CREATIVE_ICONS_ARRAY.map(item => {
                    return (
                      <div
                        key={item.id}
                        onClick={() => setSelectedId(item.id)}
                        className="flex flex-col gap-[6px] items-center w-[min-content]">
                        <OptionalIcon
                          iconPath={item.svg}
                          isSelected={item.id === selectedId}
                          handleChange={(element: string) => handleChange('ctaIcon', element)}
                        />

                        <Typography
                          size={12}
                          className="font-[300] text-center leading-[12px] px-1 w-[50px]">
                          {item.label}
                        </Typography>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="flex  flex-wrap md:flex-nowrap gap-4 mt-[40px]">
              <div id="eacel_onboarding_step_four" className="flex flex-col gap-2 w-[50%]">
                <Typography size={14} className="font-[900] leading-[18px] text-text-primary">
                  LOGO
                </Typography>

                <div>
                  <FontField
                    name={selectedLogo !== '' ? 'UPDATE' : 'SELECT'}
                    handleClick={() => {
                      setSelectedComp(section.LOGO)
                      setRun(false)
                      dispatch(
                        setCurrentOnboardingCreativeStep({
                          index: onboardingStep
                        })
                      )
                      setIsDrawerOpen(true)
                    }}
                  />
                </div>
                {!!touched.logoAssetId && errors.logoAssetId && (
                  <div className={'flex flex-1 text-error mt-1 text-[14px] px-2 '}>
                    {errors.logoAssetId as string}
                  </div>
                )}
                <div className="flex flex-wrap gap-3 mt-1">
                  {selectedLogo !== '' && (
                    <img
                      src={selectedLogo}
                      alt="logo"
                      className="w-[120px] h-[120px] object-contain rounded-md"
                    />
                  )}
                </div>
              </div>

              <div id="eacel_onboarding_step_five" className="flex flex-col gap-2 w-[50%]">
                <Typography size={14} className="font-[900] leading-[18px] text-text-primary">
                  FOREGROUND IMAGE
                </Typography>

                <div
                  onClick={() => {
                    setSelectedComp(section.FOREGROUND)
                    setIsDrawerOpen(true)
                    setRun(false)
                    dispatch(
                      setCurrentOnboardingCreativeStep({
                        index: onboardingStep
                      })
                    )

                    trackEvent(events.USER_FLOW_IMAGE_UPLOAD_INITIATED)
                  }}
                  className="flex cursor-pointer items-center gap-3 ">
                  <IconButton
                    path={assets.svgs.UPLOAD}
                    size={'lg'}
                    className="w-[40px] h-[40px] shadow-md"
                  />
                  <Typography size={14} className="font-[500] text-text-primary">
                    {!!foregroundImageAsset ? 'UPDATE' : 'UPLOAD'}
                  </Typography>
                </div>
                {isGuest() ? (
                  touched.foregroundAssetId && errors.foregroundAssetId ? (
                    <div className={'flex flex-1 text-error mt-1 text-[14px] px-2 '}>
                      {errors.foregroundAssetId as string}
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  <>
                    {errors.foregroundAssetId && touched.foregroundAssetId && (
                      <div className={'flex flex-1 text-error mt-1 text-[14px] px-2 '}>
                        {errors.foregroundAssetId as string}
                      </div>
                    )}
                  </>
                )}
                {!!foregroundImageAsset && (
                  <img
                    src={foregroundImageAsset.presignedUrl}
                    alt={'selected-creative-icon'}
                    className="w-[120px] h-[120px] object-contain "
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-3">
            <PreviewCreative
              creative={values as Creative}
              selectedLogo={selectedLogo}
              selectedForegroundImage={foregroundImageAsset?.presignedUrl ?? null}
            />
            <div className="flex  w-[80%] ">
              <Button
                label="Generate"
                // disabled={!isValid}
                className="mt-12  cursor-pointer  uppercase"
                id="eacel_onboarding_step_eight"
                type="submit"
                fullWidth
              />
            </div>
          </div>
        </div>
      </form>
      <Tour run={run} stepIndex={onboardingStep} step={creativeSteps} callback={handleCallback} />
    </>
  )
}

export default GenerateCreative
