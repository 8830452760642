import { useEffect, useState } from 'react'
import assets from 'src/assets'
import HeaderTitle from 'src/components/HeaderTitle'
import ImageGallery from 'src/components/ImageGallery'
import Loader from 'src/components/Loader'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import useHandleErrorMessage from 'src/hook/useIsErrorMessage'
import {
  useGetAssetsQuery,
  useGetBrandsQuery,
  useLazyGetAssetsByBrandIdQuery
} from 'src/store/brand/brand.api'
import { Brand, BrandsWithCreativeCount } from 'src/store/brand/brand.type'
import { RtkError } from 'src/store/redux.types'
import BrandSelector from './BrandSelector'
import { trackEvent } from '../../amplitude'
import { events } from '../../amplitude/data.ts'

const MyAssets = () => {
  const handleErrorMessage = useHandleErrorMessage()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>()
  const [selectedImage, setSelectedImage] = useState<string[]>([])
  const [isBrand, setIsBrand] = useState(false)

  const {
    data: brandList,
    isLoading,
    isError,
    error
  } = useGetBrandsQuery(undefined, {
    refetchOnMountOrArgChange: false
  })

  const [getAssetsById] = useLazyGetAssetsByBrandIdQuery()

  const {
    data: assetsList,
    isLoading: assetsLoading,
    refetch
  } = useGetAssetsQuery(undefined, {
    refetchOnMountOrArgChange: false
  })

  useEffect(() => {
    if (isError && error) {
      handleErrorMessage((error as RtkError).data.error.message, true)
    }
  }, [error, isError])

  const handleChangeBrandName = (brand: Brand | null) => {
    if (brand) {
      setSelectedBrand(brand)
      if (brand.uuid) {
        trackEvent(events.USER_FLOW_MY_ASSETS_BRAND_SELECTED, { brand_id: brand.uuid })
        const images: string[] = []
        getAssetsById(brand.uuid)
          .unwrap()
          .then(res => {
            res.map(i => {
              images.push(i.presignedUrl)
            })
            setSelectedImage(images)
          })
      }
    } else {
      refetch()
      setSelectedBrand(null)
      if (assetsList) {
        const images: string[] = []
        assetsList.map(i => {
          images.push(i.presignedUrl)
        })
        setSelectedImage(images)
      }
    }
  }

  useEffect(() => {
    if (assetsList) {
      const images: string[] = []
      assetsList.map(i => {
        images.push(i.presignedUrl)
      })
      setSelectedImage(images)
    }
  }, [assetsList])
  useEffect(() => {
    trackEvent(events.USER_FLOW_MY_ASSETS_PAGE_VIEWED)
  }, [])
  return (
    <>
      <BrandSelector
        isDrawerOpen={isDrawerOpen}
        brandList={brandList as BrandsWithCreativeCount[]}
        handleClose={() => setIsDrawerOpen(false)}
        isLoading={isLoading}
        selectedBrand={selectedBrand ?? null}
        handleChangeBrandName={handleChangeBrandName}
        // setSelectedImage={setSelectedImage}
        isBrand={isBrand}
      />
      <div className="flex flex-col gap-[30px] max-w-[1640px] m-5">
        <HeaderTitle primaryName={'MY'} secondaryName="ASSETS" />
        {/* <TextField
          variant={'rounded'}
          placeholder="Search image"
          width={isMobile ? '100%' : '40%'}
          className="bg-white max-w-[500px] "
          id="fonts"
          rightIcon={<img alt="search-icon" src={assets.svgs.SEARCH} className="w-5 h-5 mr-2 " />}
        /> */}

        <div>
          <div className="flex justify-between ">
            <div>
              <Typography size={10} className=" font-[600] leading-[12px] text-text-primary">
                BRAND
              </Typography>

              <div
                className="flex gap-2 cursor-pointer"
                onClick={() => {
                  setIsDrawerOpen(true)
                  setIsBrand(true)
                }}>
                <Typography size={12} className=" font-[900] leading-[18px] text-text-primary">
                  {selectedBrand ? selectedBrand.name : 'All'}
                </Typography>
                <img src={assets.svgs.DOWN_ARROW} alt="down" />
              </div>
            </div>

            <Button
              className="py-[5px]"
              onClick={() => {
                setIsDrawerOpen(true)
                setIsBrand(false)
                trackEvent(events.USER_FLOW_IMAGE_UPLOAD_INITIATED)
              }}
              label={'UPLOAD'}
            />
          </div>
          <div className="py-3">
            {assetsLoading ? (
              <div className="mt-[50px]">
                <Loader />
              </div>
            ) : (
              <>
                {selectedImage.length <= 0 ? (
                  <Typography
                    size={16}
                    className="flex justify-center items-center text-text-secondary mt-52">
                    No data found
                  </Typography>
                ) : (
                  <ImageGallery imageList={selectedImage} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MyAssets
