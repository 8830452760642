import { FC } from 'react'
import assets from 'src/assets'
import AddIcon from 'src/components/AddIcon'
import BellIcon from 'src/components/BellIcon'
import BuyIcon from 'src/components/BuyIcon'
import ChatIcon from 'src/components/ChatIcon'
import ClaimIcon from 'src/components/ClaimIcon'
import DownloadIcon from 'src/components/DownloadIcon'
import ExploreIcon from 'src/components/ExploreIcon'
import LikeIcon from 'src/components/LikeIcon'
import SendIcon from 'src/components/SendIcon'
import SwitchIcon from 'src/components/SwitchIcon'
import ViewMoreIcon from 'src/components/ViewMoreIcon'

// export const ACCEPTED_IMAGE_UPLOAD_FORMATS = '.jpg, .jpeg, .png, .svg, .webp'
export const ACCEPTED_IMAGE_UPLOAD_FORMATS = '.jpg, .jpeg, .png, .svg'
export interface CREATIVE_LIST_TYPE {
  id: number
  label: string
  date: Date
  imageUrl: string
  status: string
}

export const CreativeStepperOptions = [
  { id: 1, title: 'Generate Creative' },
  { id: 2, title: 'View Variants' },
  { id: 3, title: 'Render Dimensions' }
]

export const DUMMY_IMAGES = [
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-1.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-2.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-4.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-6.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-7.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-8.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-9.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-10.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-11.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-6.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-7.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-8.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-9.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-10.jpg',
  'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-11.jpg'
]

export const CREATIVE_ICONS_ARRAY = [
  {
    id: 1,
    label: 'Send Now',
    svg: SendIcon as FC<React.SVGProps<SVGSVGElement>>
  },
  {
    id: 2,
    label: 'Explore',
    svg: ExploreIcon as FC<React.SVGProps<SVGSVGElement>>
  },
  {
    id: 3,
    label: 'Buy Now',
    svg: BuyIcon as FC<React.SVGProps<SVGSVGElement>>
  },
  {
    id: 4,
    label: 'Express Interest',
    svg: LikeIcon as FC<React.SVGProps<SVGSVGElement>>
  },
  {
    id: 5,
    label: 'View More',
    svg: ViewMoreIcon as FC<React.SVGProps<SVGSVGElement>>
  },
  {
    id: 6,
    label: 'Send Message',
    svg: ChatIcon as FC<React.SVGProps<SVGSVGElement>>
  },
  {
    id: 7,
    label: 'Get Started',
    svg: SwitchIcon as FC<React.SVGProps<SVGSVGElement>>
  },
  {
    id: 8,
    path: assets.svgs.BELL,
    label: 'Subscribe',
    svg: BellIcon as FC<React.SVGProps<SVGSVGElement>>
  },
  {
    id: 9,
    path: assets.svgs.ADDICON,
    label: 'Sign Up',
    svg: AddIcon as FC<React.SVGProps<SVGSVGElement>>
  },
  {
    id: 10,
    label: 'Download',
    svg: DownloadIcon as FC<React.SVGProps<SVGSVGElement>>
  },
  {
    id: 11,
    label: 'Claim',
    svg: ClaimIcon as FC<React.SVGProps<SVGSVGElement>>
  }
]

export const DUMMY_COLOR_OPTIONS = [
  {
    id: 1,
    primary: '#28AFB0',
    secondary: '#77DFD8',
    accent: '#E1FFEE',
    name: 'Aqua Refresh'
  },
  {
    id: 2,
    primary: '#f58549',
    secondary: '#f2a65a',
    accent: '#eec170',
    name: 'Autumn Harvest'
  },
  {
    id: 3,
    primary: '#F49D6E',
    secondary: '#F7B05B',
    accent: '#D56F3E',
    name: 'Autumn Spice'
  },
  {
    id: 4,
    primary: '#a31621',
    secondary: '#bfdbf7',
    accent: '#053c5e',
    name: 'Bold Contrast'
  },
  {
    id: 5,
    primary: '#F95700',
    secondary: '#FFC60B',
    accent: '#FFE194',
    name: 'Citrus Punch'
  },
  {
    id: 6,
    primary: '#0D3B66',
    secondary: '#FAF0CA',
    accent: '#F4D35E',
    name: 'Classic Elegance'
  },
  {
    id: 7,
    primary: '#90dbf4',
    secondary: '#8eecf5',
    accent: '#98f5e1',
    name: 'Cool Waters'
  },
  {
    id: 8,
    primary: '#8C271E',
    secondary: '#F4A261',
    accent: '#C6A15B',
    name: 'Crisp Autumn'
  },
  {
    id: 9,
    primary: '#00a6fb',
    secondary: '#6cd8f9',
    accent: '#73BFB8',
    name: 'Deep Blue Sea'
  },
  {
    id: 10,
    primary: '#272643',
    secondary: '#E3F6F5',
    accent: '#BAE8E8',
    name: 'Deep Serenity'
  },
  {
    id: 11,
    primary: '#361D32',
    secondary: '#543C52',
    accent: '#F38181',
    name: 'Dusk Essence'
  },
  {
    id: 12,
    primary: '#7C9EB2',
    secondary: '#52528C',
    accent: '#372554',
    name: 'Dusky Skies'
  },
  {
    id: 13,
    primary: '#540D6E',
    secondary: '#FFD23F',
    accent: '#EE4266',
    name: 'Dynamic Dazzle'
  },
  {
    id: 14,
    primary: '#CB997E',
    secondary: '#FFE8D6',
    accent: '#E3CAA5',
    name: 'Earthy Essence'
  },
  {
    id: 15,
    primary: '#FF006E',
    secondary: '#8338EC',
    accent: '#3A86FF',
    name: 'Electric Fusion'
  },
  {
    id: 16,
    primary: '#ff7b00',
    secondary: '#ff8800',
    accent: '#ffa200',
    name: 'Fiery Blaze'
  },
  {
    id: 17,
    primary: '#ED6A5A',
    secondary: '#F4F1BB',
    accent: '#9BC1BC',
    name: 'Fresh Appeal'
  },
  {
    id: 18,
    primary: '#AEFFD8',
    secondary: '#8AFFC1',
    accent: '#8FDC97',
    name: 'Fresh Mint'
  },
  {
    id: 19,
    primary: '#E3E0A6',
    secondary: '#BF8098',
    accent: '#77A5B8',
    name: 'Gentle Glow'
  },
  {
    id: 20,
    primary: '#f35b04',
    secondary: '#f18701',
    accent: '#f7b801',
    name: 'Golden Hour'
  },
  {
    id: 21,
    primary: '#9381FF',
    secondary: '#B8B8FF',
    accent: '#f7aef8',
    name: 'Lavender Dream'
  },
  {
    id: 22,
    primary: '#dec9e9',
    secondary: '#d2b7e5',
    accent: '#b185db',
    name: 'Lilac Dream'
  },
  {
    id: 23,
    primary: '#1be7ff',
    secondary: '#6eeb83',
    accent: '#e4ff1a',
    name: 'Lime Twist'
  },
  {
    id: 24,
    primary: '#d3f8e2',
    secondary: '#ede7b1',
    accent: '#a9def9',
    name: 'Meadow Breeze'
  },
  {
    id: 25,
    primary: '#25283D',
    secondary: '#8F3985',
    accent: '#B79CED',
    name: 'Midnight Jewel'
  },
  {
    id: 26,
    primary: '#95E1D3',
    secondary: '#FCE38A',
    accent: '#F38181',
    name: 'Mint Refresh'
  },
  {
    id: 27,
    primary: '#89B9AD',
    secondary: '#C7DCA7',
    accent: '#FFC5C5',
    name: 'Misty Meadow'
  },
  {
    id: 28,
    primary: '#63474D',
    secondary: '#AA767C',
    accent: '#D6A184',
    name: 'Mocha Rose'
  },
  {
    id: 29,
    primary: '#393E41',
    secondary: '#FFD369',
    accent: '#EEEEEE',
    name: 'Modern Grey'
  },
  {
    id: 30,
    primary: '#ED6A5A',
    secondary: '#F4F1BB',
    accent: '#86B4A9',
    name: 'Modern Sophisticate'
  },
  {
    id: 31,
    primary: '#88B04B',
    secondary: '#EFEE69',
    accent: '#43aa8b',
    name: 'Morning Dew'
  },
  {
    id: 32,
    primary: '#606C38',
    secondary: '#7CA982',
    accent: '#a6c48a',
    name: "Nature's Palette"
  },
  {
    id: 33,
    primary: '#219ebc',
    secondary: '#ffb703',
    accent: '#fb8500',
    name: 'Ocean Depths'
  },
  {
    id: 34,
    primary: '#00509D',
    secondary: '#FDC500',
    accent: '#FF6363',
    name: 'Oceanic Sunset'
  },
  {
    id: 35,
    primary: '#A2D2FF',
    secondary: '#BDE0FE',
    accent: '#FFC6FF',
    name: 'Pastel Skies'
  },
  {
    id: 36,
    primary: '#fb6f92',
    secondary: '#FB9AD1',
    accent: '#BC7FCD',
    name: 'Pink Harmony'
  },
  {
    id: 37,
    primary: '#84FFC9',
    secondary: '#AAB2FF',
    accent: '#ECA0FF',
    name: 'Playful Pastels'
  },
  {
    id: 38,
    primary: '#4B3F72',
    secondary: '#DEC1FF',
    accent: '#9B5DE5',
    name: 'Regal Opulence'
  },
  {
    id: 39,
    primary: '#3D155F',
    secondary: '#DF6589',
    accent: '#F2C14E',
    name: 'Royal Charm'
  },
  {
    id: 40,
    primary: '#f46036',
    secondary: '#2e294e',
    accent: '#1b998b',
    name: 'Rustic Red'
  },
  {
    id: 41,
    primary: '#456990',
    secondary: '#49BEAA',
    accent: '#F4E8C1',
    name: 'Seaside Calm'
  },
  {
    id: 42,
    primary: '#006D77',
    secondary: '#9ADFD4',
    accent: '#83C5BE',
    name: 'Serenity Seas'
  },
  {
    id: 43,
    primary: '#50C4ED',
    secondary: '#387ADF',
    accent: '#FBA834',
    name: 'Skyline Blue'
  },
  {
    id: 44,
    primary: '#9E788F',
    secondary: '#A9B3CE',
    accent: '#7CDEDC',
    name: 'Soft Pastel'
  },
  {
    id: 45,
    primary: '#AF8250',
    secondary: '#F2DDC1',
    accent: '#A88772',
    name: 'Spiced Latte'
  },
  {
    id: 46,
    primary: '#FFD166',
    secondary: '#FFB20F',
    accent: '#F3B391',
    name: 'Sunflower Field'
  },
  {
    id: 47,
    primary: '#FFD166',
    secondary: '#06D6A0',
    accent: '#EF476F',
    name: 'Sunrise Glow'
  },
  {
    id: 48,
    primary: '#FB8B24',
    secondary: '#D90368',
    accent: '#B70068',
    name: 'Sunset Boulevard'
  },
  {
    id: 49,
    primary: '#08363D',
    secondary: '#0B5351',
    accent: '#00A9A5',
    name: 'Teal Depth'
  },
  {
    id: 50,
    primary: '#dfe7fd',
    secondary: '#bee1e6',
    accent: '#fad2e1',
    name: 'Tranquil Frost'
  },
  {
    id: 51,
    primary: '#3B8EA5',
    secondary: '#F6F740',
    accent: '#F26430',
    name: 'Tropical Heat'
  },
  {
    id: 52,
    primary: '#FE4A49',
    secondary: '#FED766',
    accent: '#009FB7',
    name: 'Tropical Sunrise'
  },
  {
    id: 53,
    primary: '#1B648F',
    secondary: '#FAF0CA',
    accent: '#F4D35E',
    name: 'Trusted Depths'
  },
  {
    id: 54,
    primary: '#064789',
    secondary: '#EBF2FA',
    accent: '#6998AB',
    name: 'Trustworthy Blues'
  },
  {
    id: 55,
    primary: '#219650',
    secondary: '#21DB6B',
    accent: '#33C26C',
    name: 'Verdant Green'
  },
  {
    id: 56,
    primary: '#FE218B',
    secondary: '#FED700',
    accent: '#21B0FE',
    name: 'Vibrant Energy'
  },
  {
    id: 57,
    primary: '#555FF2',
    secondary: '#E8825D',
    accent: '#B57C67',
    name: 'Vibrant Twilight'
  },
  {
    id: 58,
    primary: '#08D9D6',
    secondary: '#FF2E63',
    accent: '#FCEC52',
    name: 'Vivid Splash'
  },
  {
    id: 59,
    primary: '#69306D',
    secondary: '#9E4770',
    accent: '#E6AACE',
    name: 'Wildberry Bliss'
  },
  {
    id: 60,
    primary: '#8ac926',
    secondary: '#ffca3a',
    accent: '#ff595e',
    name: 'Zesty Lemonade'
  }
]

export const maxAsyncApiCall = 120

export const FEEDBACK_LINK = 'https://meet.eacel.ai/#/feedback'
