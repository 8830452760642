import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ApiRoutes } from 'src/constants/routes'
import { prepareHeaders } from 'src/utils/headers'
import {
  BrandsWithCreativeCount,
  CreateAssetPayload,
  CreateBrandPayload,
  EditAssetArgs,
  EditBrandArgs,
  ImageSearchQueryParams,
  StockImagesPayload,
  UserAssetWithProvider,
  editAsset
} from './brand.type'

export const brandApi = createApi({
  reducerPath: 'brandApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ApiRoutes.BASE_URL,
    prepareHeaders: headers => prepareHeaders(headers)
  }),
  tagTypes: ['brands', 'analytics'],
  endpoints: builder => ({
    getBrands: builder.query<BrandsWithCreativeCount[], undefined>({
      query: () => ({ url: ApiRoutes.GET_BRANDS }),
      providesTags: () => ['brands']
    }),
    getBrandById: builder.query<BrandsWithCreativeCount, string>({
      query: brandId => {
        return {
          url: ApiRoutes.GET_BRAND(brandId),
          method: 'GET'
        }
      }
    }),
    createBrand: builder.mutation<BrandsWithCreativeCount, CreateBrandPayload>({
      query: body => ({
        url: ApiRoutes.CREATE_BRAND,
        method: 'POST',
        body
      }),
      invalidatesTags: ['brands', 'analytics']
    }),
    editBrand: builder.mutation<BrandsWithCreativeCount, EditBrandArgs>({
      query: ({ brandId, values }) => ({
        url: ApiRoutes.EDIT_BRAND(brandId),
        method: 'PATCH',
        body: values
      }),
      invalidatesTags: ['brands']
    }),
    createAsset: builder.mutation<UserAssetWithProvider[], CreateAssetPayload[]>({
      query: body => ({
        url: ApiRoutes.USER_ASSETS,
        method: 'POST',
        body
      })
    }),
    editAsset: builder.mutation<editAsset, EditAssetArgs>({
      query: ({ assetId, values }) => ({
        url: ApiRoutes.EDIT_ASSET(assetId),
        method: 'PATCH',
        body: values
      })
    }),
    getStockImages: builder.query<StockImagesPayload[], ImageSearchQueryParams>({
      query: ({ pageNo, value }) => ({
        url:
          value?.trim() === ''
            ? ApiRoutes.STOCK_IMAGES_WITHOUT_QUERY(pageNo)
            : ApiRoutes.STOCK_IMAGES(value as string, pageNo)
      })
    }),
    getAssets: builder.query<UserAssetWithProvider[], undefined>({
      query: () => ({
        url: ApiRoutes.USER_ASSETS
      })
    }),
    getAssetsByBrandId: builder.query<UserAssetWithProvider[], string>({
      query: brandId => {
        return {
          url: ApiRoutes.GET_IMAGE_BY_BRAND_ID(brandId),
          method: 'GET'
        }
      }
    })
  })
})

export const {
  useGetBrandsQuery,
  useLazyGetBrandByIdQuery,
  useCreateBrandMutation,
  useEditBrandMutation,
  useCreateAssetMutation,
  useEditAssetMutation,
  // useGetStockImagesQuery,
  useLazyGetAssetsQuery,
  useLazyGetStockImagesQuery,
  useGetAssetsQuery,
  useLazyGetAssetsByBrandIdQuery
} = brandApi
