import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  Brand,
  BrandSliceState,
  BrandsWithCreativeCount
  // UserAssetWithProvider
} from './brand.type'
const initialState: BrandSliceState = {
  selectedBrand: {}
  // creativeImage: null
}
const brandSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    setSelectedBrand: (state, { payload }: PayloadAction<Brand | BrandsWithCreativeCount>) => {
      state.selectedBrand = payload
    }
    // setCreativeImage: (state, { payload }: PayloadAction<UserAssetWithProvider | null>) => {
    //   state.creativeImage = payload
    // }
  }
})
export const { setSelectedBrand } = brandSlice.actions
export default brandSlice.reducer
