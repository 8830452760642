import assets from 'src/assets'
import Typography from 'src/components/Typography'
import { Creative } from 'src/store/creatives/creative.type'

interface Props {
  creative: Creative
  selectedLogo: string
  selectedForegroundImage: string | null
}
const PreviewCreative = ({ creative, selectedLogo, selectedForegroundImage }: Props) => {
  return (
    <div className="bg-white max-h-content min-w-[400px] rounded-md pb-4">
      <div className="pt-5">
        <img
          alt="logo-url"
          width={creative.logoAssetId ? 'unset' : 100}
          style={{ width: '100px' }}
          src={selectedLogo || assets.images.PLACEHOLDER}
          className="mx-auto  "
        />
      </div>
      <div className="pt-5">
        <img
          alt={'foreground-url'}
          src={selectedForegroundImage || assets.images.PLACEHOLDER}
          className=" w-full h-[300px] object-contain"
        />
      </div>
      <div className="mx-6 py-4 flex gap-5 justify-between flex-col ">
        <div>
          <Typography
            variant="h2"
            size={20}
            style={{ wordBreak: 'break-word' }}
            className=" leading-[20px]">
            {creative.headline
              ? creative.headline.trim() !== ''
                ? creative.headline
                : 'Headline here'
              : 'Headline here'}
          </Typography>
          <Typography
            variant="light"
            size={16}
            style={{ wordBreak: 'break-word' }}
            className=" leading-[20px]">
            {creative.punchline
              ? creative.punchline.trim() !== ''
                ? creative.punchline
                : 'Punchline here'
              : 'Punchline here'}
          </Typography>
        </div>

        {creative.ctaText && creative.ctaText.trim() && (
          <div className="bg-black min-w-max color-white min-h-[34px] w-fit rounded-full px-5 py-2 h-fit self-start flex gap-2 cursor-pointer mb-4 break-words ">
            {creative.ctaIcon && (
              <div
                className="white-svg self-center"
                dangerouslySetInnerHTML={{
                  __html: creative.ctaIcon
                }}
              />
            )}
            <Typography
              variant="body1"
              className="text-white  w-[fit-content] leading-[18px] self-center ">
              {creative.ctaText}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default PreviewCreative
