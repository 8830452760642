import { forwardRef, LegacyRef } from 'react'

interface Props {
  color: string
}

const AddIcon = ({ color = 'var(--black)' }: Props, ref: LegacyRef<SVGSVGElement>) => (
  <svg
    ref={ref}
    width={16}
    height={15}
    viewBox="0 0 16 15"
    fill={color}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00547 14.076C7.68814 14.076 7.44547 13.992 7.27747 13.824C7.10947 13.6373 7.02547 13.3853 7.02547 13.068V7.916H1.95747C1.64014 7.916 1.39747 7.832 1.22947 7.664C1.06147 7.496 0.977469 7.26267 0.977469 6.964C0.977469 6.66533 1.06147 6.432 1.22947 6.264C1.39747 6.096 1.64014 6.012 1.95747 6.012H7.02547V1.028C7.02547 0.692 7.10947 0.439999 7.27747 0.271999C7.44547 0.103999 7.69747 0.0199993 8.03347 0.0199993C8.3508 0.0199993 8.58414 0.103999 8.73347 0.271999C8.90147 0.439999 8.98547 0.692 8.98547 1.028V6.012H14.0535C14.3708 6.012 14.6135 6.096 14.7815 6.264C14.9495 6.432 15.0335 6.66533 15.0335 6.964C15.0335 7.26267 14.9495 7.496 14.7815 7.664C14.6135 7.832 14.3708 7.916 14.0535 7.916H8.98547V13.068C8.98547 13.3853 8.90147 13.6373 8.73347 13.824C8.58414 13.992 8.34147 14.076 8.00547 14.076Z"
      fill={color}
    />
  </svg>
)
export default forwardRef(AddIcon)
