import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loader from 'src/components/Loader'
import useHandleErrorMessage from 'src/hook/useIsErrorMessage'
import { useLazyGetAssetsByBrandIdQuery } from 'src/store/brand/brand.api.ts'
import {
  AssetImageSelection,
  ImageSelection,
  UserAssetWithProvider
} from 'src/store/brand/brand.type'
import { RtkError } from 'src/store/redux.types.ts'
import { trackEvent } from '../../../amplitude'
import { events } from '../../../amplitude/data.ts'
import { useStateSelector } from '../../../store/root.reducer.ts'
import { isGuest } from 'src/utils/cookies.ts'

interface MyAssetsSectionProps {
  setImageSelection: (img: ImageSelection) => void
}

const MyAssetsSection = ({ setImageSelection }: MyAssetsSectionProps) => {
  const [assetList, setAssetList] = useState<UserAssetWithProvider[]>([])
  const handleErrorMessage = useHandleErrorMessage()
  const { brandId } = useParams()
  const creativeDetails = useStateSelector(state => state.creativeSlice)
  // const [fetchAssets, { isLoading }] = useLazyGetAssetsQuery()
  const [getAssetsById, { isLoading }] = useLazyGetAssetsByBrandIdQuery()

  const handleImageClick = (i: UserAssetWithProvider, id: string) => {
    const selectedImage = { ...i, presignedUrl: i.presignedUrl + `&${Date.now()}` }
    setImageSelection(new AssetImageSelection(selectedImage))
    trackEvent(events.AD_CREATION_MY_ASSET_IMAGE_SELECTED, {
      brand_id: brandId,
      project_name: creativeDetails.projectName,
      assets_id: id,
      session_type: isGuest() ? 'guest' : 'registered'
    })
  }

  useEffect(() => {
    if (brandId) {
      getAssetsById(brandId)
        .unwrap()
        .then(res => {
          setAssetList(res)
        })
        .catch((error: RtkError) => {
          if (error) {
            handleErrorMessage(error.data.error.message, true)
          }
        })
    }
  }, [])

  return (
    <div className="flex flex-col" style={{ marginTop: '20px' }}>
      {/* <TextField
        // fullWidth
        width="66%"
        // handleOnChange={(_, value) => handleOnChange(value)}
        variant={'rounded'}
        placeholder="Search Here"
        className="bg-white"
        id="fonts"
        rightIcon={<img alt="search-icon" src={assets.svgs.SEARCH} className="w-5 h-5 mr-2 " />}
      /> */}

      <div
        className="py-3 mt-8 overflow-auto scrollbar-hide"
        style={{ height: 'calc(100vh - 150px)' }}>
        {isLoading ? (
          <div className="mt-10">
            <Loader background="none" />
          </div>
        ) : (
          <div className="">
            <div className="columns-1 gap-5 sm:columns-2 sm:gap-4 md:columns-3 lg:columns-4 [&>img:not(:first-child)]:mt-8">
              {assetList.map(image => {
                return (
                  <img
                    key={image.uuid}
                    src={image.presignedUrl}
                    alt={image.provider}
                    className="rounded-md cursor-pointer"
                    onClick={() => {
                      handleImageClick(image, image.uuid)
                    }}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MyAssetsSection
