export const getContrastColor = (backgroundColor: string) => {
  // Convert hex to RGB
  const hex = backgroundColor.replace(/^#/, '')
  const r = parseInt(hex.substr(0, 2), 16)
  const g = parseInt(hex.substr(2, 2), 16)
  const b = parseInt(hex.substr(4, 2), 16)

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  // Use a threshold to determine whether to use light or dark text
  const threshold = 0.5
  return luminance > threshold ? '#000000' : '#FFFFFF'
}

export const generateRandomNumber = (limit: number) => {
  const min = 10 ** (limit - 1)
  const max = 10 ** limit - 1

  return Math.floor(min + Math.random() * (max - min + 1))
}
