import { forwardRef, LegacyRef } from 'react'

interface Props {
  color: string
}

const ChatIcon = ({ color = 'var(--black)' }: Props, ref: LegacyRef<SVGSVGElement>) => (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}>
    <path
      d="M9.62183 1C9.61691 1.00509 9.60709 1.00509 9.59236 1.00509C4.6221 1.00509 0.580078 5.19473 0.580078 10.3465C0.580078 12.449 1.27257 14.4903 2.5397 16.1346L1.22837 19.2909C1.12032 19.5505 1.2382 19.8508 1.48376 19.9578C1.57217 19.9985 1.67039 20.0087 1.76371 19.9934L6.57189 19.1178C7.53451 19.4792 8.54625 19.6625 9.5678 19.6574C14.5381 19.6574 18.5801 15.4678 18.5801 10.316C18.5899 5.17946 14.5774 1.00509 9.62183 1ZM9.57271 18.6444C8.62483 18.6444 7.68676 18.4662 6.79781 18.12C6.71432 18.0844 6.62592 18.0793 6.53751 18.0946L2.49058 18.8276L3.56125 16.2466C3.63492 16.0685 3.60545 15.8597 3.48267 15.7121C2.90313 15.0147 2.44147 14.2205 2.11732 13.3602C1.75388 12.3981 1.56725 11.3748 1.56725 10.3414C1.56725 5.74962 5.17217 2.01814 9.59727 2.01814C14.0125 2.00796 17.5978 5.70889 17.6027 10.2854V10.316C17.6027 14.9129 13.9978 18.6444 9.57271 18.6444Z"
      fill={color}
      stroke={color}
      strokeWidth={1}
    />
    <path
      d="M9.18963 8.46805H6.53752C6.2674 8.46805 6.04639 8.69713 6.04639 8.97712C6.04639 9.25711 6.2674 9.48619 6.53752 9.48619H9.18963C9.45976 9.48619 9.68077 9.25711 9.68077 8.97712C9.68077 8.69713 9.45976 8.46805 9.18963 8.46805Z"
      fill={color}
      stroke={color}
      strokeWidth={1}
    />
    <path
      d="M12.6276 11.0134H6.53752C6.2674 11.0134 6.04639 11.2425 6.04639 11.5225C6.04639 11.8025 6.2674 12.0315 6.53752 12.0315H12.6276C12.8977 12.0315 13.1187 11.8025 13.1187 11.5225C13.1187 11.2425 12.8977 11.0134 12.6276 11.0134Z"
      fill={color}
      stroke={color}
      strokeWidth={1}
    />
  </svg>
)
export default forwardRef(ChatIcon)
