import { VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'
import { ReactNode, useState } from 'react'
import { cn } from 'src/lib/utils'
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  value?: string
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  textLimit?: number
  showCount?: boolean
  handleOnChange?: (key: string, value: string) => void
  id: string
  errorText?: string | boolean
  fullWidth?: boolean
  width?: string
  initialValue?: string
}
const inputVariants = cva(
  'text-black flex overflow-hidden  bg-transparent text-sm  transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium  disabled:cursor-not-allowed disabled:opacity-50 ',
  {
    variants: {
      variant: {
        default: 'w-full text-sm border-b-[1px] border-beige outline-none  focus:border-beige',
        rounded:
          'shadow-sm rounded-full  border border-input focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-secondary-primary '
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)

const TextField = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      variant = 'default',
      rightIcon,
      leftIcon,
      showCount = false,
      textLimit,
      className,
      type,
      fullWidth = false,
      width = '50%',
      handleOnChange,
      initialValue,
      id,
      errorText,
      value: inputValue,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState('')

    // updates the local state based on the initial value
    React.useEffect(() => {
      if (initialValue) {
        setValue(initialValue as string)
      }
    }, [initialValue])

    // updating the local state with incoming input value
    React.useEffect(() => {
      if (inputValue && value !== inputValue) {
        // using substring to pass only the text with text limit
        // it will create a substring starting from zero and ending with maximum text limit
        setValue(inputValue.substring(0, textLimit))
      }
    }, [inputValue])

    const onChange = (key: string, value: string) => {
      setValue(value)
      if (handleOnChange) handleOnChange(key, value)
    }

    return (
      <div style={{ width: fullWidth ? '100% ' : width }}>
        <div className={cn(inputVariants({ variant, className }))}>
          {leftIcon && <div className={'self-center pl-3'}>{leftIcon}</div>}
          <input
            ref={ref}
            type={type}
            value={value}
            className={`w-full bg-transparent  py-3 focus-visible:outline-none ${
              variant === 'rounded' ? 'px-6' : 'px-2'
            }`}
            onChange={e => onChange(id, e.target.value)}
            maxLength={textLimit}
            {...props}
          />
          {rightIcon && <div className={'self-center pr-3'}>{rightIcon}</div>}
          {variant !== 'rounded' && !leftIcon && !rightIcon && showCount && (
            <div className={' self-center pr-3 text-beige border-beige border-l-2 pl-2'}>
              {Number(textLimit) - Number(value.length || 0)}
            </div>
          )}
        </div>
        <div className="flex w-[100%]">
          {!!errorText && (
            <div className={'flex flex-1 text-error mt-1 text-[14px] px-2 '}>{errorText}</div>
          )}
          {variant === 'rounded' && showCount && (
            <div className="[text-align-last:end] text-text-secondary mt-1 mr-2 ml-auto">
              {Number(value.length || 0)} of {Number(textLimit)}
            </div>
          )}
        </div>
      </div>
    )
  }
)
TextField.displayName = 'TextField'
export { TextField }
