import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ApiRoutes } from 'src/constants/routes'
import { prepareHeaders } from 'src/utils/headers.ts'
import { Analytics } from './analytics.type.ts'

export const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ApiRoutes.BASE_URL,
    prepareHeaders: headers => prepareHeaders(headers)
  }),
  tagTypes: ['analytics'],
  endpoints: builder => ({
    analytics: builder.query<Analytics, undefined>({
      query: () => ({
        url: `${ApiRoutes.ANALYTICS}`
      }),
      providesTags: () => ['analytics']
    })
  })
})

export const { useAnalyticsQuery } = analyticsApi
