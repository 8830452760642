interface LoaderProps {
  background?: string
}
const Loader = ({ background = 'var(--white)' }: LoaderProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      preserveAspectRatio="xMidYMid"
      style={{
        margin: 'auto',
        background,
        display: 'block'
      }}
      viewBox="0 0 100 100">
      <g fill="none" strokeWidth={2} transform="translate(50 50)">
        <circle r={27.333} stroke="#fcda2c" strokeDasharray="16 10 12 10">
          <animateTransform
            attributeName="transform"
            begin={0}
            calcMode="spline"
            dur="1.5s"
            keySplines="0.2 0 0.8 1"
            repeatCount="indefinite"
            type="rotate"
            values="360 0 0;0 0 0"
          />
        </circle>

        <circle r={35.333} stroke="#e15b64" strokeDasharray="25">
          <animateTransform
            attributeName="transform"
            begin={-0.6}
            calcMode="spline"
            dur="1s"
            keySplines="0.2 0 0.8 1"
            repeatCount="indefinite"
            type="rotate"
            values="0 0 0;360 0 0"
          />
        </circle>
        <circle
          r={41.667}
          stroke="#089ffdd4"
          strokeDasharray="110.89969389957471 130.89969389957471">
          <animateTransform
            attributeName="transform"
            begin={-0.8}
            calcMode="spline"
            dur="1s"
            keySplines="0.2 0 0.8 1"
            repeatCount="indefinite"
            type="rotate"
            values="360 0 0;0 0 0"
          />
        </circle>
      </g>
    </svg>
  )
}

export default Loader
