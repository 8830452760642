export async function imageUrlToFile(imageUrl: string, fileName: string): Promise<File> {
  return new Promise((resolve, reject) => {
    // // Create an Image element
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    // Set the image source

    // Event listener for when the image has loaded
    img.onload = () => {
      // Create a canvas element
      const canvas = document.createElement('canvas')

      // Set canvas dimensions to match the image
      canvas.width = img.width
      canvas.height = img.height

      // Get the canvas context
      const ctx = canvas.getContext('2d')

      // Draw the image on the canvas
      ctx?.drawImage(img, 0, 0)

      // Convert the canvas content to a blob
      canvas.toBlob(blob => {
        if (blob) {
          // Create a File object from the blob
          const type = fileName.endsWith('.jpg') ? 'image/jpeg' : 'image/png'
          const file = new File([blob], fileName, { type: type })
          resolve(file)
        } else {
          reject(new Error('Unable to convert canvas content to blob'))
        }
      })
    }

    // Event listener for when there's an error loading the image
    img.onerror = () => {
      reject(new Error('Error loading image'))
    }

    img.src = imageUrl + `${imageUrl.includes('base64') ? '' : '&' + Date.now()}`
    // fetch(imageUrl, {
    //   method: 'GET',
    //   headers: { 'Cache-Control': 'no-cache' }
    // })
    //   .then(response => {
    //     // Check if the response is ok (status in the range 200-299)
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok')
    //     }
    //     return response.blob() // Convert response to blob
    //   })
    //   .then(blob => {
    //     // Create a file from the blob
    //     const file = new File([blob], fileName, { type: blob.type })
    //     resolve(file) // Resolve the promise with the file
    //   })
    //   .catch(error => {
    //     reject(error) // Reject the promise if there's an error
    //   })
  })
}
