import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OnboardingState } from './onboarding.type'

const initialState: OnboardingState = {
  isManuallyUpdated: false,
  currentCreativeStep: 0
}

const onboarding = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setCurrentOnboardingCreativeStep: (
      state,
      { payload }: PayloadAction<{ index: number; isManuallyUpdated?: boolean }>
    ) => {
      state.currentCreativeStep = payload.index
      state.isManuallyUpdated = payload.isManuallyUpdated ?? false
    },
    resetCreative: () => initialState
  }
})
export const { setCurrentOnboardingCreativeStep, resetCreative } = onboarding.actions
export default onboarding.reducer
