import { Dispatch, SetStateAction } from 'react'
import assets from 'src/assets'
import Loader from 'src/components/Loader'
import Typography from 'src/components/Typography'
import { generatedCreativesTypes } from 'src/store/creatives/creative.type'
import { isGuest } from 'src/utils/cookies'

interface DimensionTabProps {
  selectedDimensions: string[]
  isLoading: boolean
  handleVariantDimensions: (item: string) => void
  variantDimensions?: generatedCreativesTypes
  handleDownloadSingleImage: (imageUrl: string[], resolution: string, index: number) => void
  setRun: Dispatch<SetStateAction<boolean>>
  setIsGuestAuthModal: Dispatch<SetStateAction<boolean>>
}

const DimensionsTab = ({
  selectedDimensions,
  handleVariantDimensions,
  variantDimensions,
  handleDownloadSingleImage,
  isLoading,
  setIsGuestAuthModal,
  setRun
}: DimensionTabProps) => {
  return (
    <>
      {isLoading ? (
        <div className="absolute w-full h-full flex top-[-150px] justify-center">
          <Loader background="var(--cream)" />
        </div>
      ) : (
        <div className="pt-1  sm:pt-8">
          <div className="columns-1   gap-[15px] space-y-[15px] md:space-y-[10px] lg:space-y-[10px] sm:columns-2 lg:gap-[10px] md:gap-[10px] md:columns-2 lg:columns-2 [&>img:not(:first-child)]:mt-8">
            {variantDimensions &&
              Object.entries(variantDimensions.imageUrls).map((item, i) => {
                const resolution =
                  item[0] === 'SQUARE'
                    ? '1080 x 1080'
                    : item[0] === 'LANDSCAPE'
                      ? '1800 x 942'
                      : '1000 x 1500'

                return (
                  <div className="relative p-2 " key={i}>
                    <div
                      onClick={() => handleVariantDimensions(item[0])}
                      style={{
                        boxShadow: selectedDimensions.includes(String(item[0]))
                          ? '0px 0px 24px 6px rgba(0, 0, 0, 0.25)'
                          : 'none',
                        border: selectedDimensions.includes(item[0])
                          ? '2px solid #598081'
                          : '1px solid #efefef'
                      }}
                      className="   bg-white cursor-pointer border-[1px]  border-text-secondary  rounded-[5px] overflow-hidden">
                      <div className="relative h-[100%] w-[100%] ">
                        <img src={item[1]} alt={item[0]} className="  w-[100%]" />
                      </div>
                      <div className="flex items-center justify-between px-[10px] ">
                        <Typography size={10} className={'font-[900] py-[15px]'}>
                          {item[0]}
                          <br />
                          <span className="font-[200]">({resolution})</span>
                        </Typography>
                        <img
                          src={assets.svgs.CIRCULAR_DOWNLOAD}
                          alt="tick"
                          onClick={e => {
                            e.stopPropagation()
                            if (isGuest()) {
                              setRun(false)
                              setIsGuestAuthModal(true)
                            } else {
                              handleDownloadSingleImage(item, resolution, i)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      )}
    </>
  )
}

export default DimensionsTab
