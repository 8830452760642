export const assets = {
  svgs: {
    ADD: '/assets/svgs/add.svg',
    BOOK: '/assets/svgs/book.svg',
    CLOSE: '/assets/svgs/close.svg',
    BRIEFCASE: '/assets/svgs/briefcase.svg',
    CREATIVE_AD: '/assets/svgs/creative-ad.svg',
    DASHBOARD: '/assets/svgs/dashboard.svg',
    MONITOR: '/assets/svgs/monitor.svg',
    SEARCH: '/assets/svgs/search.svg',
    TICK: '/assets/svgs/tick.svg',
    AIGENERATER: '/assets/svgs/AIGenerater.svg',
    USER: '/assets/svgs/user.svg',
    OUser: '/assets/svgs/Ouser.svg',
    QUEASK: '/assets/svgs/que.svg',
    CHAT: '/assets/svgs/chat.svg',
    ODASHBOARD: '/assets/svgs/Odashboard.svg',
    UPLOAD: '/assets/svgs/upload.svg',
    NEXT: '/assets/svgs/next.svg',
    PENCIL: '/assets/svgs/pencil.svg',
    TRASH: '/assets/svgs/trash.svg',
    REVERSE: '/assets/svgs/reverse.svg',
    CROP: '/assets/svgs/crop.svg',
    AMAZON: '/assets/svgs/amazon.svg',
    META: '/assets/svgs/meta.svg',
    DRIVE: '/assets/svgs/Ads.svg',
    ADDICON: '/assets/svgs/addicon.svg',
    BELL: '/assets/svgs/bell.svg',
    BUY: '/assets/svgs/buy.svg',
    CLAIM: '/assets/svgs/claim.svg',
    COMPASS: '/assets/svgs/compass.svg',
    DOWNLOAD: '/assets/svgs/download.svg',
    LIKE: '/assets/svgs/like.svg',
    SEND: '/assets/svgs/send.svg',
    SWITCH: '/assets/svgs/switch.svg',
    VIEWMORE: '/assets/svgs/viewmore.svg',
    GREEN_TICK: '/assets/svgs/green_tick.svg',
    CIRCULAR_DOWNLOAD: '/assets/svgs/circular_download.svg',
    PREVIOUS: '/assets/svgs/prevoius.svg',
    LOGOUT: '/assets/svgs/logout.svg',
    BAG: '/assets/svgs/bag.svg',
    TENT: '/assets/svgs/tent.svg',
    DOWN_ARROW: '/assets/svgs/downArrow.svg',
    EDIT_DOT: '/assets/svgs/edit_dot.svg',
    GRAPH: '/assets/svgs/graph.svg',
    LOGO: '/assets/svgs/logo.svg',
    ARCHIEVE: '/assets/svgs/archieve.svg',
    CROSS_TRASH: '/assets/svgs/cross_trash.svg',
    BACKGROUND_REMOVER: '/assets/svgs/remove_background.svg',
    RESET: '/assets/svgs/reset.svg',
    CHECKED_ICON: '/assets/svgs/checkedIcon.svg',
    CROSS_ICON: '/assets/svgs/cross.svg',
    CIRCLE_BULLETS_ICON: '/assets/svgs/circleBullets.svg'
  },
  images: {
    DUMMY_IMAGE: '/assets/images/dummy.png',
    WOMEN_IMAGE: '/assets/images/women_image.png',
    SUCCESS_IMAGE: '/assets/images/success.png',
    PUNT_H_IMAGE: '/assets/images/puntH.png',
    PUNT_S_IMAGE: '/assets/images/puntS.png',
    PUNT_V_iMAGE: '/assets/images/puntV.png',
    DUMMY_iMAGE_1: '/assets/images/dummy_img1.png',
    DUMMY_iMAGE_2: '/assets/images/dummy_img2.png',
    DUMMY_iMAGE_3: '/assets/images/dummy_variant1.png',
    DUMMY_iMAGE_4: '/assets/images/dummy_variant2.png',
    DUMMY_TUTORIAL_IMAGE: '/assets/images/dummy_image.png',
    PLACEHOLDER: '/assets/images/placeholder.jpg',
    USER: '/assets/images/user.png',
    LOGO: '/assets/images/logo.png',
    DUMMY_1: '/assets/images/dummy1.png',
    DUMMY_2: '/assets/images/dummy2.png',
    DUMMY_3: '/assets/images/dummy3.png',
    DUMMY_4: '/assets/images/dummy4.png',
    ROBOT: '/assets/images/robot.png',
    BLANK_CANVAS: '/assets/images/blank_canvas.png',
    ERROR_VARIANT: '/assets/images/error-variant.png',
    PROGRESS_LOADER_VECTOR: '/assets/images/progress-loader-vector.png',
    COMING_SOON: '/assets/images/coming_soon.png',
    DEMO_CLIP: '/assets/images/demo_clip.png',
    DEMO_CLIP2: '/assets/images/demo_clip2.png'
  }
}

export default assets
