import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { maxAsyncApiCall } from 'src/constants/index.ts'
import { useLazyGetVariantDimensionsByIdQuery } from 'src/store/creatives/creative.api'
import { CreativeVariantStatus, generatedCreativesTypes } from 'src/store/creatives/creative.type'
import { RtkError } from 'src/store/redux.types'
import { trackEvent } from '../amplitude'
import { events } from '../amplitude/data.ts'
import { useStateSelector } from '../store/root.reducer.ts'
import useHandleErrorMessage from './useIsErrorMessage'
import { isGuest } from 'src/utils/cookies.ts'

const useGetVariantDimensions = () => {
  const handleErrorMessage = useHandleErrorMessage()
  const { creativeId, variantId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [getVariantDimensions, { error }] = useLazyGetVariantDimensionsByIdQuery()
  const [variantDimensions, setVariantDimensions] = useState<generatedCreativesTypes>()
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [count, setCount] = useState(0)
  const projectName = useStateSelector(state => state.creativeSlice.projectName)
  useEffect(() => {
    if (error) {
      setIsLoading(false)
      setIsError(true)
      setErrorMsg('Something went wrong. Please try again later')
    }
  }, [error])
  useEffect(() => {
    const fetchVariantDimensions = () => {
      setIsLoading(true)
      if (creativeId && variantId) {
        getVariantDimensions({ variantId })
          .then(res => {
            if (res && res.data) {
              if (
                res.data.status === CreativeVariantStatus.PROCESSED &&
                Object.keys(res.data.imageUrls).length === 3 &&
                Object.values(res.data.imageUrls).every(value => value !== null)
              ) {
                setVariantDimensions(res.data)
                trackEvent(events.AD_CREATION_DIMENSIONS_RENDERED, {
                  creative_id: creativeId,
                  session_type: isGuest() ? 'guest' : 'registered',
                  variant_id: variantId,
                  project_name: projectName,
                  number_of_dimension_viewed: Object.keys(res.data.imageUrls).length
                })
                setIsLoading(false)
              } else {
                setCount(count + 1)
              }
              if (
                res.data.status === CreativeVariantStatus.PENDING ||
                res.data.status === CreativeVariantStatus.PROCESSING
              ) {
                setCount(count + 1)
              }
              if (res.data.status === CreativeVariantStatus.FAILED) {
                setIsLoading(false)
                setIsError(true)
                setErrorMsg(res.data.errorMessage)
                const error = res as unknown as RtkError
                handleErrorMessage(error.data.error.message, true)
              }
            }
          })
          .catch(() => {
            trackEvent(events.AD_CREATION_VARIANTS_PAGE_ERRORED, {
              session_type: isGuest() ? 'guest' : 'registered',
              failure_reason: 'sessionTimeOut',
              project_name: projectName
            })
            setIsLoading(false)
            setIsError(true)
            setErrorMsg('No dimension found.')
            handleErrorMessage('No dimension found.', true)
          })
          .finally(() => {
            // setIsLoading(false)
          })
      }
    }
    if (count < maxAsyncApiCall) {
      setTimeout(() => {
        fetchVariantDimensions()
      }, 3000)
      // Retry fetching after 3 seconds if not processed
    } else {
      trackEvent(events.AD_CREATION_VARIANTS_PAGE_ERRORED, {
        failure_reason: 'sessionTimeOut',
        session_type: isGuest() ? 'guest' : 'registered',
        project_name: projectName
      })
      Sentry.captureMessage(`${creativeId}`, 'error')
      setIsLoading(false)
      setIsError(true)
      setErrorMsg('Time out.')
      handleErrorMessage('Time out.', true)
    }
  }, [variantId, creativeId, count])

  return { data: variantDimensions, isLoading, error, isError, errorMsg }
}

export default useGetVariantDimensions
