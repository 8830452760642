import Typography from 'src/components/Typography'

interface PerformanceCardType {
  count: number | undefined
  name: string
  icon: string
}

const PerformanceCard = ({ count, name, icon }: PerformanceCardType) => {
  return (
    <div className="flex justify-between rounded-[5px] p-[15px] bg-secondary-primary min-w-[230px] min-h-[130px]">
      <div className="flex flex-col justify-end p-[5px] gap-2">
        <Typography size={36} className="font-[900] leading-[18px] text-text-primary">
          {count}
        </Typography>
        <Typography size={14} className="font-[900] leading-[18px] text-text-primary">
          {name}
        </Typography>
      </div>
      <img src={icon} alt="bag" className="self-start" />
    </div>
  )
}

export default PerformanceCard
