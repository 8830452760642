import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { AppRoutes } from 'src/constants/routes'
import { fetchCookie } from 'src/utils/cookies'

const PublicRouteOutlet = () => {
  const authToken = fetchCookie('auth_token')
  const location = useLocation()

  return !authToken ? (
    <Outlet />
  ) : (
    <Navigate state={{ from: location.pathname }} to={AppRoutes.HOME} />
  )
}

export default PublicRouteOutlet
