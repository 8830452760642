import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Creative,
  CreativeSliceState,
  CreativeVariantStatus,
  generatedCreativesTypes
} from './creative.type'

const initialState: CreativeSliceState = {
  currentCreative: null,
  selectedVariantId: null,
  variants: null,
  projectName: null
}

const creativeSlice = createSlice({
  name: 'creative',
  initialState,
  reducers: {
    setCurrentCreative: (state, { payload }: PayloadAction<Creative>) => {
      state.currentCreative = payload
    },
    variants: (state, { payload }: PayloadAction<generatedCreativesTypes[]>) => {
      state.variants = payload
    },
    setSelectedVariantId: (state, { payload }: PayloadAction<number | undefined>) => {
      state.selectedVariantId = payload
    },
    setSelectedProjectName: (state, { payload }: PayloadAction<string | undefined>) => {
      state.projectName = payload
    },
    setCreativeStatus: (state, { payload }: PayloadAction<CreativeVariantStatus>) => {
      if (state?.currentCreative?.status) {
        state.currentCreative.status = payload
      }
    },

    resetCreative: () => initialState
  }
})
export const {
  setCurrentCreative,
  variants,
  setSelectedVariantId,
  setSelectedProjectName,
  setCreativeStatus,
  resetCreative
} = creativeSlice.actions
export default creativeSlice.reducer
