import { CreativeVariantStatus } from '../store/creatives/creative.type.ts'

export const getCreativeStatus = (params: CreativeVariantStatus) => {
  switch (params) {
    case CreativeVariantStatus.PROCESSED:
      return { name: 'Completed', style: 'bg-toast-error' }
    case CreativeVariantStatus.PROCESSING:
      return { name: 'Draft', style: 'bg-yellow-dark' }
    case CreativeVariantStatus.PENDING:
      return { name: 'Draft', style: 'bg-yellow-dark' }
    default:
      return { name: 'Error', style: 'bg-red' }
  }
}
