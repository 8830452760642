import { forwardRef, LegacyRef } from 'react'

interface Props {
  color: string
}

const BuyIcon = ({ color = 'var(--black)' }: Props, ref: LegacyRef<SVGSVGElement>) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}>
    <path
      d="M2.93265 8.92559C2.86306 8.92559 2.7954 8.93139 2.72581 8.94492C2.09565 9.05897 1.67619 9.66207 1.79024 10.2922L3.18587 18.0243C3.28639 18.5771 3.76578 18.9773 4.32635 18.9773H16.5971C17.1577 18.9773 17.639 18.5752 17.7376 18.0243L19.1332 10.2922C19.1448 10.2246 19.1526 10.155 19.1526 10.0854C19.1526 9.44557 18.6326 8.92559 17.9928 8.92559H2.93265ZM17.177 8.15239L13.7943 1.56275C13.6976 1.37331 13.7711 1.13942 13.9624 1.04277C14.1519 0.946116 14.3858 1.01957 14.4824 1.21094L18.0469 8.15432C19.0907 8.18138 19.9296 9.03577 19.9296 10.0873C19.9296 10.2033 19.92 10.3174 19.8987 10.4314L18.5031 18.1635C18.3368 19.0836 17.5366 19.7524 16.601 19.7524H4.33022C3.39464 19.7524 2.59437 19.0836 2.42813 18.1635L1.03056 10.4295C0.841126 9.37985 1.53894 8.37275 2.58857 8.18332C2.68522 8.16592 2.78381 8.15625 2.88239 8.15239L6.4488 1.21094C6.54545 1.0215 6.77934 0.946116 6.96878 1.04277C7.15822 1.13942 7.2336 1.37331 7.13695 1.56275L3.75224 8.15239H17.177Z"
      fill={color}
      stroke={color}
      strokeWidth={1}
    />
  </svg>
)
export default forwardRef(BuyIcon)
