import { cloneDeep } from 'lodash'
import * as React from 'react'
import assets from 'src/assets'
import CircularLoaderIcon from 'src/components/CircularLoaderIcon.tsx'
import { ACCEPTED_IMAGE_UPLOAD_FORMATS } from 'src/constants'
import useHandleErrorMessage from 'src/hook/useIsErrorMessage.ts'
import useUploadAsset from 'src/hook/useUploadAsset.ts'
import convertFilesToDataURL from 'src/utils/convertFileToDataURL.ts'
import Typography from '../../../components/Typography/index.tsx'
import IconButton from '../../../components/ui/iconButton.tsx'
import { CreateAssetPayload, UserAsset } from '../../../store/brand/brand.type.ts'

export interface ImageUploaderProps {
  onFileSelect: (file: UserAsset[]) => void
  maxImages: number
  assetList: Array<UserAsset>
  errorText: string
  setIsImageLoading: (isLoading: boolean) => void
}

export interface LogoImageSpec {
  name: string
  url: string
}

export enum assetsType {
  LOGO = 'logo',
  FOREGROUND = 'foreground_image',
  Other = 'other'
}

const mapAssetListToLogoImageSpec = (assetList: UserAsset[]): LogoImageSpec[] =>
  assetList.map(a => ({
    name: a.name || 'dummy',
    url: a.presignedUrl
  }))

const BrandLogoUploader = ({
  onFileSelect,
  assetList = [],
  setIsImageLoading,
  maxImages = Infinity // errorText
}: ImageUploaderProps) => {
  const fileInputRef = React.useRef<HTMLInputElement | null>(null)
  const [logoImageList, setLogoImageList] = React.useState<LogoImageSpec[]>(
    mapAssetListToLogoImageSpec(assetList)
  )
  const handleErrorMsg = useHandleErrorMessage()
  const { uploadAsset, isUploading } = useUploadAsset()

  React.useEffect(() => {
    const logoTuples = mapAssetListToLogoImageSpec(assetList)
    if (JSON.stringify(logoTuples) === JSON.stringify(logoImageList)) {
      return
    }
    setLogoImageList(logoTuples)
  }, [assetList])

  const onFilesSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsImageLoading(true)
    const fileList = event.target.files as FileList
    const totalImages = logoImageList.length + fileList.length

    if (totalImages > maxImages) {
      setIsImageLoading(false)
      handleErrorMsg(`You can upload maximum ${maxImages} images`, true)
      return
    }

    const createAssetPayloadList: CreateAssetPayload[] = []
    const newFilesArray: File[] = [] // Array to store new files
    const newFileList = []
    // Define a helper function to handle each file
    const handleFile = (file: File) => {
      return new Promise<void>(resolve => {
        const reader = new FileReader()

        reader.onload = async event => {
          const svgContent: string | ArrayBuffer | null | undefined = event.target?.result
          const parser = new DOMParser()
          const svgDocument = parser.parseFromString(svgContent as string, 'image/svg+xml')
          const svgString = new XMLSerializer().serializeToString(svgDocument)

          const img = new Image()
          img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            canvas.width = img.width
            canvas.height = img.height

            ctx?.drawImage(img, 0, 0)

            const pngDataUrl = canvas.toDataURL('image/png')

            const base64String = pngDataUrl.split(',')[1]
            const mimeType = 'image/png'
            const filename = `${file.name.split('.svg')[0]}.png`
            const byteCharacters = atob(base64String)
            const byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)
            const blob = new Blob([byteArray], { type: mimeType })
            const newFile = new File([blob], filename, { type: mimeType })

            // Push the newFile into the array
            newFilesArray.push(newFile)

            resolve() // Resolve the promise
          }

          img.src = 'data:image/svg+xml;base64,' + btoa(svgString)
        }

        reader.readAsText(file)
      })
    }

    // Iterate over each file and handle it
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].type === 'image/svg+xml') {
        await handleFile(fileList[i])
        createAssetPayloadList.push({
          fileName: newFilesArray[i].name,
          assetType: assetsType.LOGO,
          contentType: newFilesArray[i].type,
          provider: 'user',
          providerAttributionUrl: ''
        })
        // fileList[i] = newFilesArray[i]
        newFileList.push(newFilesArray[i])
      } else {
        createAssetPayloadList.push({
          fileName: fileList[i].name,
          assetType: assetsType.LOGO,
          contentType: fileList[i].type,
          provider: 'user',
          providerAttributionUrl: ''
        })
        newFilesArray.push(fileList[i])
      }
      // Wait for each file to be handled
    }

    const dataUrls = await convertFilesToDataURL(newFilesArray)

    const imageList = dataUrls.map((u, i) => ({
      name: newFilesArray[i].name,
      url: u
    }))
    setLogoImageList([...logoImageList, ...imageList])
    try {
      const uploadedAssets = await uploadAsset(createAssetPayloadList, newFilesArray)
      onFileSelect([...assetList, ...uploadedAssets])
    } catch (error) {
      handleErrorMsg('Unable to upload logos. Try again')
    } finally {
      setIsImageLoading(false)
    }
    if (fileInputRef?.current?.value) {
      fileInputRef.current.value = ''
    }
  }

  const handleClick = () => {
    fileInputRef?.current?.click()
  }

  const handleCancel = (index: number) => {
    const clonedList = cloneDeep(logoImageList)
    clonedList.splice(index, 1)
    setLogoImageList(clonedList)

    const copy = cloneDeep(assetList)
    copy.splice(index, 1)
    onFileSelect(copy)
  }
  return (
    <div className="flex d-flex gap-[40px] flex-wrap">
      {logoImageList.map((i, index) => (
        <div
          key={i.name}
          className=" w-[210px] h-[110px] bg-white rounded-[5px] relative shadow-lg">
          <img
            src={i.url}
            alt="image"
            className="object-contain rounded-md w-[230px] h-[110px] py-5"
          />
          <div className="w-[100%] flex d-flex justify-center">
            <IconButton
              path={assets.svgs.CLOSE}
              size={'sm'}
              className="absolute bottom-[-11px] p-[3px] shadow-md"
              onClick={() => {
                if (!isUploading) {
                  handleCancel(index)
                }
              }}
            />
          </div>
        </div>
      ))}

      <div className="flex flex-col items-center">
        {!isUploading ? (
          <div
            onClick={handleClick}
            className="flex justify-center items-center w-[210px] h-[110px] bg-white rounded-[5px] shadow-lg">
            <input
              type="file"
              id="input"
              accept={ACCEPTED_IMAGE_UPLOAD_FORMATS}
              multiple
              disabled={logoImageList.length >= maxImages}
              onChange={onFilesSelected}
              className="absolute opacity-0 z-[-1]"
              ref={fileInputRef}
            />
            <IconButton
              path={assets.svgs.ADD}
              disabled={logoImageList.length >= maxImages}
              size={'lg'}
              className="shadow-md flex justify-center items-center"
            />
          </div>
        ) : (
          <div
            onClick={handleClick}
            className="flex justify-center items-center w-[210px] h-[110px] bg-white rounded-[5px] shadow-lg">
            <CircularLoaderIcon />
          </div>
        )}

        {/* {!!errorMessage && ( */}
        <Typography variant="body1" size={13} className="mt-2 pl-1">
          {logoImageList.length}/{maxImages}
        </Typography>
        {/* // )} */}
      </div>
    </div>
  )
}

export default BrandLogoUploader
