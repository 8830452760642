import { useAppDispatch } from 'src/store'
import { setSnackbar } from 'src/store/snackbar/snackbar.slice'
import { SnackbarType } from 'src/store/snackbar/snackbar.type'

const useHandleErrorMessage = () => {
  const dispatch = useAppDispatch()

  const handleErrorMessage = (message: string, isSnackbarShow: boolean = false): void => {
    if (isSnackbarShow) {
      dispatch(
        setSnackbar({
          message: message,
          severity: SnackbarType.ERROR
        })
      )
    }
  }

  return handleErrorMessage
}

export default useHandleErrorMessage
