import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { fetchCookie } from './cookies'

export const loadDefaultHeaders = (
  store?: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'> | undefined
): Record<string, string> => {
  const auth_token = fetchCookie('auth_token') || ''
  if (store && store.endpoint === 'getRemoveBackground') {
    if (auth_token) {
      return {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth_token}`
      }
    } else {
      return {
        'Content-Type': 'multipart/form-data'
      }
    }
  } else {
    if (auth_token) {
      return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth_token}`
      }
    } else {
      return {
        'Content-Type': 'application/json'
      }
    }
  }
}
export const prepareHeaders = (
  headers: Headers,
  store?: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
) => {
  const newHeaders = loadDefaultHeaders(store)
  Object.keys(newHeaders).forEach(header => {
    headers.set(header, newHeaders[header])
  })
  return headers
}
