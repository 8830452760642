import {
  AnyAction,
  Dispatch,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI
} from '@reduxjs/toolkit'
import { removeCookie } from 'src/utils/cookies'
import { clearSession } from '../../amplitude'
import { setSnackbar } from '../snackbar/snackbar.slice.ts'
import { SnackbarType } from '../snackbar/snackbar.type.ts'

const rtkQueryErrorHandler: Middleware<any, any, Dispatch<AnyAction>> =
  (api: MiddlewareAPI) => next => async action => {
    if (action) {
      if (isRejectedWithValue(action)) {
        // const { dispatch, getState } = api
        const { dispatch } = api
        const { payload } = action
        // const state = getState()
        const status = payload && (payload.originalStatus || payload.status)
        if (status === 403 || status === 401) {
          removeCookie('auth_token')
          removeCookie('isGuest')
          clearSession()
          dispatch({ type: 'reset' })
          history.back()
        }

        if (status === 'FETCH_ERROR') {
          // const token = fetchCookie('auth_token')
          // if (!token) {
          dispatch(
            setSnackbar({
              severity: SnackbarType.ERROR,
              message: 'An internal server error occurred. Please try again later.'
            })
          )
          // } else {
          //   window.location.replace(AppRoutes.ERROR)
          // }
        }
      }
      return next(action)
    }
  }

export default rtkQueryErrorHandler
