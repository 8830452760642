import { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CreativeStepperOptions } from 'src/constants'
import './style.css'

const Stepper = () => {
  const path = useLocation()
  const [activeStep, setActiveStep] = useState(CreativeStepperOptions[0].id)
  const formatNumber = (num: number) => num.toString().padStart(2, '0')

  const pathName = path.pathname.split('/')[3]
  useEffect(() => {
    if (pathName === 'creative') {
      setActiveStep(CreativeStepperOptions[0].id)
    } else if (pathName === 'variants') {
      setActiveStep(CreativeStepperOptions[1].id)
    } else {
      setActiveStep(CreativeStepperOptions[2].id)
    }
  }, [path])
  return (
    <div>
      <div className="flex  items-center overflow-y-hidden overflow-x-auto scrollbar-hide min-h-[70px]">
        {CreativeStepperOptions.map(({ id, title }) => (
          <Fragment key={id}>
            {id > 1 && (
              <div
                className={`flex-shrink-0 h-0.5 w-[20px] ${
                  id < activeStep + 1 ? 'bg-main-primary' : 'bg-secondary-primary'
                }`}
              />
            )}
            <div
              className={`flex items-center relative space-x-2 cursor-pointer ${
                id < activeStep ? 'text-black' : 'text-secondary-primary'
              }`}>
              {id === activeStep ? (
                <div className="flex pr-[20px] pl-[5px] pt-[5px] pb-[5px] Selected rounded-full items-center gap-[10px] bg-gradient-to-b from-green-light to-green-dark after:content-[''] ">
                  <div className="flex items-center justify-center w-10 h-10  rounded-full shrink-0  text-grey-dark bg-white text-black-500 font-bold text">
                    <b className="text-[16px] text-green-dark  font-[900]">{formatNumber(id)}</b>
                  </div>
                  <h4 className="text-white  font-[900] w-[max-content]">{title}</h4>
                </div>
              ) : (
                <>
                  {id < activeStep ? (
                    <div className="flex items-center justify-center w-[50px] h-[50px]  rounded-full shrink-0 bg-gradient-to-b from-yellow-light to-yellow-dark text-black ">
                      <h2 className="text-black  font-[900]">{formatNumber(id)}</h2>
                    </div>
                  ) : (
                    <span className="flex items-center justify-center w-[50px] h-[50px]  rounded-full shrink-0  bg-secondary-primary text-black   font-[900]">
                      {formatNumber(id)}
                    </span>
                  )}
                </>
              )}
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default Stepper
