import { format } from 'date-fns/format'

import { useNavigate } from 'react-router-dom'
import assets from 'src/assets'
import Typography from 'src/components/Typography'
import VideoDialog from 'src/components/VideoDialog/index.tsx'
import { AppRoutes } from 'src/constants/routes'
import { trackEvent } from '../../amplitude'
import { events } from '../../amplitude/data.ts'
import PerformanceCard from './PerformanceList/PerformanceCard'

interface SamplePageProps {
  primaryText: string
  secondaryText: string
}

const SamplePage = ({ primaryText, secondaryText }: SamplePageProps) => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col lg:flex-row w-[100%] flex-wrap">
      <div className="flex flex-col gap-[20px] w-[40%]">
        <div className="flex gap-[20px] flex-wrap">
          <img src={assets.images.COMING_SOON} alt="" className={'max-w-[320px]'} />
          <div
            onClick={() => {
              trackEvent(events.BRAND_CREATION_INITIATED)
              navigate(AppRoutes.ADD_BRAND)
            }}
            className="flex flex-col items-center justify-end gap-2 cursor-pointer rounded-[5px] max-w-[1640px] max-h-130px bg-gradient-to-b from-main-primary to-yellow-dark p-[30px]">
            <img src={assets.svgs.ADD} alt="add" className="w-[22px]" />
            <Typography size={14} className="font-[900] text-text-primary">
              BRAND
            </Typography>
          </div>
        </div>
        <div className="flex flex-col gap-[10px] mt-[25px]">
          <Typography size={14} className=" font-[900] leading-[18px] text-text-primary">
            PERFORMANCE SUMMARY
          </Typography>
          <div className="flex gap-[30px] flex-wrap">
            <PerformanceCard count={0} icon={assets.svgs.BAG} name="CREATIVES" />
            <PerformanceCard count={0} icon={assets.svgs.TENT} name="BRANDS" />
          </div>
        </div>
        <div>
          <div className="flex flex-col gap-[15px] mt-[25px]">
            <Typography size={14} className=" font-[900] leading-[18px] text-text-primary">
              CREATIVES
            </Typography>

            <div>
              <Typography size={10} className=" font-[600] leading-[12px] text-text-primary">
                BRAND
              </Typography>

              <div className="flex w-max gap-2 cursor-pointer">
                <Typography size={12} className=" font-[900] leading-[18px] text-text-primary">
                  YOUR BRAND NAME
                </Typography>

                <img src={assets.svgs.DOWN_ARROW} alt="down" />
              </div>
            </div>
            <div className="flex flex-col gap-[10px] w-[fit-content]">
              <div className="relative w-[100%] flex flex-col p-[20px] rounded-[5px] gap-[10px] bg-secondary-primary ">
                <div className={'absolute top-0 right-5 rounded-b-[5px] px-[8px] bg-sample'}>
                  <Typography size={10} className="text-white font-[900] leading-[18px] ">
                    SAMPLE
                  </Typography>
                </div>

                <div>
                  <Typography size={12} className=" font-[700] leading-[15px] ">
                    NEW YEAR POST
                  </Typography>
                  <Typography size={10} className=" font-[400] leading-[18px] ">
                    Created on {format(new Date('01-01-24'), 'do')} day of{' '}
                    {format(new Date('01-01-24'), 'MMM-yy')}
                  </Typography>
                </div>
                <img
                  src={assets.images.DUMMY_4}
                  alt="static_image"
                  className=" object-cover w-[190px] h-[160px]"
                />

                <div id="one" className="flex gap-[10px] justify-between ">
                  <button className="flex flex-col w-[100%]  items-center px-[17px] pt-[11px] pb-[5px] rounded-[5px] bg-white">
                    <img src={assets.svgs.EDIT_DOT} alt="edit" className="m-auto" />
                    <Typography
                      size={10}
                      className="text-tooltip-primary font-[700] leading-[18px] ">
                      EDIT
                    </Typography>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[20px] w-[60%] border-l-[0px] lg:border-l-[3px]">
        <div className="flex justify-evenly flex-wrap">
          <div className="flex flex-col justify-center">
            <Typography size={24} className="font-[900] leading-[18px] mt-[28px] text-center">
              {primaryText}
            </Typography>

            <Typography size={14} className="font-[400] leading-[18px] mt-[15px] text-center">
              {secondaryText}
            </Typography>
          </div>
          <img
            src={assets.images.BLANK_CANVAS}
            alt="user"
            className={'max-w-[330px] max-h-[350px]'}
          />
        </div>
        <div className="relative m-auto w-[640px] h-[362px]">
          <VideoDialog isLargeImage={true} />
        </div>
      </div>
    </div>
  )
}

export default SamplePage
