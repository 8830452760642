interface Props {
  imageList: Array<string>
}

const ImageGallery = ({ imageList }: Props) => {
  return (
    <div className="">
      <div className="columns-1 gap-5 sm:columns-2 sm:gap-4 md:columns-3 lg:columns-3 xl:columns-3 [&>img:not(:first-child)]:mt-8">
        {imageList.map(image => {
          return <img src={image} alt={image} className="rounded-md" key={image} />
        })}
      </div>
    </div>
  )
}

export default ImageGallery
