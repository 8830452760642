import { useState } from 'react'
import assets from 'src/assets'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import { Skeleton } from 'src/components/ui/skeleton'

interface PreviewVariantProps {
  selectedVariant: string
  handleViewMoreDimensions: () => void
}

const PreviewVariant = ({ selectedVariant, handleViewMoreDimensions }: PreviewVariantProps) => {
  const [isLoadingSkeleton, setLoadingSkeleton] = useState(true)

  const handleImageLoad = () => {
    setLoadingSkeleton(false)
  }

  return (
    <div className="flex flex-col justify-start bg-secondary-primary  rounded-md px-[60px] py-[10px] h-full ">
      <div className="flex items-center justify-between mt-5">
        {!isLoadingSkeleton && (
          <Typography size={12} className={'font-[700] py-[15px]'}>
            {'SQUARE'} <span className="font-[400]">{'(1080x1080)'}</span>
          </Typography>
        )}

        {/* <img src={assets.svgs.CROSS_ICON} alt="tick" /> */}
      </div>
      <div className="rounded-[5px] overflow-hidden">
        {isLoadingSkeleton && selectedVariant && (
          <div className="flex flex-col gap-1 h-full  p-2  w-full  min-h-[280px]  rounded-[5px]">
            <Skeleton className="min-h-[180px] h-full w-full  bg-text-secondary" />
          </div>
        )}
        <img
          alt={'foreground-url'}
          src={selectedVariant ?? assets.images.PLACEHOLDER}
          className=" w-full h-full  object-contain"
          style={{ display: isLoadingSkeleton ? 'none' : 'block' }}
          onLoad={handleImageLoad}
        />
      </div>

      <div className="flex justify-center">
        <Button
          label="View more dimensions"
          id="eacel_onboarding_step_ten"
          //   disabled={loader}
          className="mt-12  uppercase"
          onClick={handleViewMoreDimensions}
        />
      </div>
    </div>
  )
}

export default PreviewVariant
