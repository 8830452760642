// VideoDialog.tsx

import { Dialog, DialogContent, DialogDescription } from '../ui/dialog.tsx'
import Typography from '../Typography/index.tsx'
import { Button } from '../ui/button.tsx'
import { FEEDBACK_LINK } from 'src/constants/index.ts'
import { useEffect } from 'react'
import { trackEvent } from 'src/amplitude/index.ts'
import { events } from 'src/amplitude/data.ts'

interface BookCallDialogProps {
  isOpen: boolean
  handleClose: () => void
}

const BookCallDialog = ({ isOpen, handleClose }: BookCallDialogProps) => {
  useEffect(() => {
    if (isOpen) {
      trackEvent(events.ONBOARDING_BOOKING_FORM_VIEWED)
    }
  }, [isOpen])
  return (
    <Dialog
      open={isOpen}
      onOpenChange={val => {
        if (!val) {
          handleClose()
        }
      }}>
      <DialogContent closable={false} className="GuestModal bg-white p-0 border-none max-w-lg ">
        <DialogDescription>
          <div className="flex flex-col gap-4 items-center p-4 ">
            <div className="flex gap-1 flex-col items-center">
              <Typography
                size={21}
                className="font-[800] text-center mt-10 leading-[31px] text-text-primary flex flex-wrap pr-[10px]">
                We’d hate to see you go
              </Typography>
              <Typography
                size={14}
                className="font-[400] text-center leading-[21px] text-text-primary flex flex-wrap pr-[10px]">
                We’d love to hear why you aren’t keen on joining
              </Typography>
            </div>
            <div className="flex flex-col gap-5 items-center my-[20px] xl:my-[25px] w-[80%] ">
              <Button
                label="BOOK A CALL NOW"
                type="submit"
                fullWidth={true}
                className=" px-[20px] py-3 text-[14px] font-[900] "
                onClick={() => {
                  handleClose()

                  trackEvent(events.ONBOARDING_BOOKING_FORM_STARTED)
                  window.open(FEEDBACK_LINK, '_blank')
                }}
              />

              <div>
                <Typography
                  size={14}
                  className="font-[400] leading-[21px] text-center text-text-primary ">
                  Book a call with us and tell us what didn’t work{' '}
                </Typography>
                <Typography
                  size={12}
                  className="font-[400] leading-[15px] capitalize text-center mt-1 text-text-primary ">
                  🎁 earn a free <b className="text-[14px] text-main-secondary"> $25 </b>Amazon gift
                  card
                </Typography>
              </div>
            </div>
          </div>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}

export default BookCallDialog
