export enum SnackbarType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning'
}

export interface SnackbarSliceState {
  isOpen?: boolean
  severity?: SnackbarType
  message?: string
  autoClose?: number
  position?:
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'top-center'
    | 'bottom-center'
}
