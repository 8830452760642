import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

export type ErrorType =
  | 'HTTP_ERROR'
  | 'FETCH_ERROR'
  | 'PARSING_ERROR'
  | 'CUSTOM_ERROR'
  | 'UNKNOWN_ERROR'

export type RTKError = {
  type: ErrorType
  httpStatus?: number
  error: string
  errorCode?: string
  details: unknown
}

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error
}

export function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  )
}

export function parseFetchBaseQueryError(error: FetchBaseQueryError): RTKError {
  if (error.status === 'FETCH_ERROR') {
    return {
      type: error.status,
      error: 'Network Request Failed',
      details: error.data
    }
  } else if (error.status === 'PARSING_ERROR') {
    return {
      type: error.status,
      httpStatus: error.originalStatus,
      error: `Error while parsing response: ${error.error}`,
      details: error.data
    }
  } else if (error.status === 'CUSTOM_ERROR') {
    return {
      type: error.status,
      error: `Custom Error: ${error.error}`,
      details: error.data
    }
  } else {
    return {
      type: 'HTTP_ERROR',
      error: `API Request Failed with status code: ${error.status} and response: ${error.data}`,
      details: error.data
    }
  }
}

export function parseRTKError(error: unknown): RTKError {
  if (isFetchBaseQueryError(error)) {
    return parseFetchBaseQueryError(error)
  } else if (isErrorWithMessage(error)) {
    return {
      type: 'UNKNOWN_ERROR',
      error: error.message,
      details: error
    }
  } else {
    return {
      type: 'UNKNOWN_ERROR',
      error: 'Something went wrong',
      details: error
    }
  }
}

// export function
