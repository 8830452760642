import Typography from '../../components/Typography'
import { TextField } from '../../components/ui/textfield.tsx'
import { Button } from '../../components/ui/button.tsx'
import GoogleIcon from '../../components/GoogleIcon.tsx'
import { ApiRoutes, AppRoutes } from '../../constants/routes.ts'
import { useFormik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { z } from 'zod'
import { googleLogout, useGoogleLogin } from '@react-oauth/google'
import * as Sentry from '@sentry/react'
import { addCookie, fetchCookie, isGuest, removeCookie } from '../../utils/cookies.ts'
import { RESET_STORE, RtkError } from '../../store/redux.types.ts'
import { clearSession, setUserForTracking, trackEvent } from '../../amplitude'
import { events } from '../../amplitude/data.ts'
import { useAppDispatch } from '../../store'
import { User } from '../../store/auth/auth.type.ts'
import { setAccount } from '../../store/auth/auth.slice.ts'
import LogRocket from 'logrocket'
import { useLocation, useNavigate } from 'react-router-dom'
import useHandleErrorMessage from '../../hook/useIsErrorMessage.ts'
import { useState } from 'react'
import CircularLoaderIcon from '../../components/CircularLoaderIcon.tsx'
import { useGuestSignupMutation } from '../../store/auth/auth.api.ts'

const GuestAuthForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const handleErrorMessage = useHandleErrorMessage()
  const [isGoogleLoading, setIsGoogleLoading] = useState(false)
  const [guestSignup, { isLoading }] = useGuestSignupMutation()
  const {
    values,
    setFieldValue,
    touched,
    errors,
    isValid,
    setFieldTouched,
    handleSubmit,
    isSubmitting
  } = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: toFormikValidationSchema(
      z
        .object({
          fullName: z.string({ required_error: 'Please enter full name' }),
          email: z.string({ required_error: 'Please enter email.' }).email({
            message: 'Invalid email'
          }),
          // password: z
          //   .string({ required_error: 'Please enter password' })
          //   .refine(value => passwordRegex.test(value), {
          //     message:
          //       'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character (!@#$%^&*)'
          //   }),
          password: z
            .string({ required_error: 'Please enter password' })
            .refine(value => value.length >= 8, {
              message: 'Password must be 8 or more characters long.'
            }),
          confirmPassword: z.string({ required_error: 'Please enter password' })
        })
        .refine(data => data.password === data.confirmPassword, {
          message: 'Password must be same',
          path: ['confirmPassword']
        })
    ),
    onSubmit: async values => {
      await guestSignup(values)
        .then(res => {
          if ('data' in res) {
            const { data } = res
            addCookie('auth_token', data.token)
            addCookie('isGuest', 'false')

            dispatch(setAccount(data))
            LogRocket.identify(data.uuid, {
              name: data.fullName,
              email: data.email
            })
            setUserForTracking(data.uuid, data.fullName, data.email)
            trackEvent(events.ONBOARDING_SIGNUP_SUCCESS, {
              method: 'email',
              session_type: isGuest() ? 'guest' : ''
            })
            navigate(AppRoutes.LOGIN, { replace: true })
          }
          if ('error' in res) {
            const error = res.error as RtkError
            handleErrorMessage(error.data.message, true)
            trackEvent(events.ONBOARDING_SIGNUP_FAILURE, {
              failure_reason: error.data.message,
              method: 'email'
            })
            if (error.data.errorCode === 'DUPLICATE_USER') {
              removeCookie('auth_token')
              removeCookie('isGuest')
              navigate(AppRoutes.LOGIN)
            }
          }
        })
        .catch(error => {
          if (error) {
            trackEvent(events.ONBOARDING_SIGNUP_FAILURE, {
              failure_reason: 'Something went wrong, please try again.',
              method: 'email'
            })
            handleErrorMessage('Something went wrong, please try again.', true)
          }
        })
    },
    enableReinitialize: true,
    validateOnMount: true
  })
  const handleChange = (key: string, value: string) => {
    setFieldValue(key, value)
  }

  const handleOnBlur = (key: string) => {
    setFieldTouched(key, true)
  }

  const registerGoogleApi = useGoogleLogin({
    onSuccess: tokenResponse => {
      // const tokenResponse = response.toJSON()
      const accessToken = tokenResponse.access_token
      fetch(`${import.meta.env.VITE_API_BASE_URL}${ApiRoutes.GUEST_GOOGLE_SIGNUP}`, {
        method: 'POST',
        body: JSON.stringify({ accessToken }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${fetchCookie('auth_token')}`
        }
      })
        .then(async res => {
          const response = (await res.json()) as User
          // const response = data
          if (res.status === 200) {
            if (response) {
              addCookie('auth_token', response.token)
              addCookie('isGuest', 'false')
              dispatch(setAccount(response))
              LogRocket.identify(response.uuid, {
                name: response.fullName,
                email: response.email
              })
              setUserForTracking(response.uuid, response.fullName, response.email)
              trackEvent(events.ONBOARDING_LOGIN_SUCCESS, {
                method: 'google_sso'
              })
              navigate(location.state ? location.state.from : AppRoutes.HOME)
            }
          } else {
            addCookie('auth_token', response.token)
            addCookie('isGuest', 'false')
            dispatch(setAccount(response))
            LogRocket.identify(response.uuid, {
              name: response.fullName,
              email: response.email
            })
            setUserForTracking(response.uuid, response.fullName, response.email)

            trackEvent(events.ONBOARDING_SIGNUP_SUCCESS, {
              session_type: isGuest() ? 'guest' : '',
              method: 'google_sso'
            })
          }

          if ((response && res.status !== 200) || res.status !== 201) {
            const errorResponse = (await res.json()) as RtkError
            trackEvent(events.ONBOARDING_LOGIN_FAILURE, {
              failure_reason: errorResponse.data.message,
              method: 'google_sso'
            })
            handleErrorMessage(errorResponse.data.message, true)
          }
        })
        .catch((error: RtkError) => {
          if (error && error.data && error.data.error && error.data.error.message) {
            trackEvent(events.ONBOARDING_LOGIN_FAILURE, {
              failure_reason: error.data.error.message,
              method: 'google_sso'
            })
            handleErrorMessage(error.data.error.message, true)
          }
        })
        .finally(() => {
          setIsGoogleLoading(false)
        })
    },
    onNonOAuthError: () => {
      setIsGoogleLoading(false)
    },
    onError: err => {
      setIsGoogleLoading(false)
      err.error && handleErrorMessage(err.error, true)
    }
  })

  return (
    <form id="signup-form" name="signup-form" onSubmit={handleSubmit} className="w-full pt-4 px-4">
      <div className="flex w-full flex-1 items-center flex-col mt-[24px] mb-[30px] gap-[10px]">
        <button
          type={'button'}
          className="flex w-full gap-[10px] items-center justify-center border-[1px] px-6 py-4 rounded-full"
          onClick={() => {
            setIsGoogleLoading(true)
            registerGoogleApi()
          }}>
          <GoogleIcon />
          <Typography
            size={13}
            className="flex justify-end font-[900] leading-[18px] text-text-primary ">
            Continue with Google
          </Typography>
          {isGoogleLoading && <CircularLoaderIcon />}
        </button>
        <div className={'relative w-full flex flex-1 items-center justify-center'}>
          <Typography
            size={14}
            className="flex p-6 bg-white text-center font-[600] py-4 leading-[18px] text-text-primary">
            OR
          </Typography>
          <div className={'absolute w-full h-[1px] z-[-1] bg-divider'}></div>
        </div>
        <TextField
          variant={'rounded'}
          placeholder="Enter Your Full Name"
          id="fullName"
          className="bg-white"
          value={values.fullName.trimStart()}
          width="100%"
          handleOnChange={(key, value) => handleChange(key, value)}
          onBlur={() => handleOnBlur('fullName')}
          errorText={touched.fullName && (errors.fullName as string)}
        />

        <TextField
          variant={'rounded'}
          placeholder="Enter Your Email ID"
          id="email"
          className="bg-white"
          value={values.email.trimStart()}
          width="100%"
          handleOnChange={(key, value) => handleChange(key, value)}
          onBlur={() => handleOnBlur('email')}
          errorText={touched.email && (errors.email as string)}
        />

        <TextField
          type="password"
          variant={'rounded'}
          placeholder="Enter Password"
          id="password"
          className="bg-white"
          value={values.password.trimStart()}
          width="100%"
          handleOnChange={(key, value) => handleChange(key, value)}
          onBlur={() => handleOnBlur('password')}
          errorText={touched.password && (errors.password as string)}
        />

        <TextField
          type="password"
          variant={'rounded'}
          placeholder="Confirm Password"
          id="confirmPassword"
          className="bg-white"
          value={values.confirmPassword.trimStart()}
          width="100%"
          handleOnChange={(key, value) => handleChange(key, value)}
          onBlur={() => handleOnBlur('confirmPassword')}
          errorText={touched.confirmPassword && (errors.confirmPassword as string)}
        />
      </div>

      <div className="flex flex-col gap-5 items-center my-[20px] xl:my-[40px] ">
        <Button
          label="SIGN UP"
          type="submit"
          disabled={!isValid}
          fullWidth={true}
          isLoading={isLoading || isSubmitting}
          className=" px-[20px] py-3 text-[14px] font-[900] "
        />
        <Typography size={12} className="font-[400] leading-[18px] text-text-primary ">
          Already A Member?{' '}
          <span
            className="underline font-[900] text-green-light cursor-pointer"
            onClick={() => {
              googleLogout()
              Sentry.setUser(null)
              removeCookie('auth_token')
              dispatch({ type: RESET_STORE })
              trackEvent(events.ONBOARDING_LOGOUT_FAILURE)
              clearSession()
              window.location.replace(AppRoutes.LOGIN)
            }}>
            {' '}
            Login Here
          </span>
        </Typography>
      </div>
    </form>
  )
}
export default GuestAuthForm
