import { useLocation, useNavigate } from 'react-router-dom'
import { NavigationMenuOptions } from 'src/constants/menu'
import { Tooltip } from '../ui/tooltip'
import { trackEvent } from '../../amplitude'

const NavigationMenu = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  return (
    <div className="bg-gradient-to-b from-main-primary to-yellow-dark rounded-full flex flex-col gap-3 py-5 mt-4 w-[50px]">
      {NavigationMenuOptions.map(({ id, icon, name, route, trackEvent: trackEventType }) => {
        return (
          <Tooltip text={name.toUpperCase()} key={id}>
            <div
              key={id}
              onClick={() => {
                trackEventType && trackEvent(trackEventType)
                navigate(route)
              }}
              className={`${
                pathname === route && 'bg-white'
              } rounded-full w-fit mx-auto p-2 cursor-pointer relative`}>
              <img src={icon} alt={name} className="" />
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}
export default NavigationMenu
