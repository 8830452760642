import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import assets from 'src/assets'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import { TextField } from 'src/components/ui/textfield'
import { AppRoutes } from 'src/constants/routes'
import { useAppDispatch } from 'src/store'
import { useForgotPasswordMutation } from 'src/store/auth/auth.api'
import { RtkError } from 'src/store/redux.types'
import { setSnackbar } from 'src/store/snackbar/snackbar.slice'
import { SnackbarType } from 'src/store/snackbar/snackbar.type'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [forgotPassword] = useForgotPasswordMutation()

  const handleChange = (key: string, value: string) => {
    setFieldValue(key, value)
  }
  const handleOnBlur = (key: string) => {
    setFieldTouched(key, true)
  }

  const {
    values,
    setFieldValue,
    touched,
    errors,
    isValid,
    setFieldTouched,
    handleSubmit,
    isSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: toFormikValidationSchema(
      z.object({
        email: z.string({ required_error: 'Please enter email.' }).email({
          message: 'Invalid email  '
        })
      })
    ),
    onSubmit: values => {
      forgotPassword(values)
        .then(() => {
          dispatch(
            setSnackbar({
              message: `Check your email for instructions to reset your password.`,
              severity: SnackbarType.SUCCESS
            })
          )

          navigate(AppRoutes.LOGIN)
          resetForm()
        })
        .catch((error: RtkError) => {
          if (error) {
            dispatch(
              setSnackbar({
                message: error.data.error.message,
                severity: SnackbarType.ERROR
              })
            )
          }
        })
    },
    enableReinitialize: true,
    validateOnMount: true
  })

  return (
    <div className="flex m-auto items-center h-[100vh] px-[0px] sm:px-[80px] py-[0px] sm:py-[90px] max-w-[1640px] overflow-auto">
      <div className="relative h-fit flex flex-col xl:flex-row justify-end gap-4 xl:gap-0 rounded-[5px] bg-paper w-full p-[30px] ">
        <div className="relative xl:absolute top-0 left-[0px] w-[50%]">
          <img src={assets.images.ROBOT} alt="user" className="w-[75%]" />
        </div>
        <div className="h-full min-h-[450px] w-[100%] xl:w-[68%] rounded-[5px] flex flex-col md:flex-row justify-between bg-white pt-[50px] pr-[20px] sm:pr-[60px] pb-[10px] sm:pb-[50px] pl-[20px] md:pl-[3rem] xl:pl-[8rem]">
          <div className="py-3 h-full w-[100%]">
            <img src={assets.svgs.LOGO} alt="img" />
            <Typography
              size={26}
              className="font-[400]  mt-10 leading-[36px] text-text-primary flex flex-wrap pr-[10px]">
              Welcome to Eacel
            </Typography>
            <Typography
              size={16}
              className=" text-text-primary font-[300] pr-[20px]  leading-[20px] max-w-[360px] break-all">
              Login to generate your newest batch of ad creatives
            </Typography>
          </div>
          <div className="h-[auto]  border-[0px] md:border-[1px] border-divider md:min-h-[33px] min-h-0 md:none" />
          <div className="w-[100%] py-[30px] md:py-[10px] pl-[0px] md:pl-[50px] flex flex-col justify-between">
            <form
              id="forget-password-form"
              name="forget-password-form"
              className=""
              onSubmit={e => {
                e.preventDefault()
                handleSubmit()
              }}>
              <Typography size={14} className="font-[900] leading-[18px] text-text-primary ">
                FORGOT PASSWORD
              </Typography>

              <div className="flex w-[100%]  flex-col mt-[24px] mb-[30px] gap-[10px]">
                <TextField
                  variant={'rounded'}
                  placeholder="Enter your registered Email Id"
                  id="email"
                  className="bg-white"
                  value={values.email.trimStart()}
                  width="100%"
                  handleOnChange={(key, value) => handleChange(key, value)}
                  onBlur={() => handleOnBlur('email')}
                  errorText={touched.email && (errors.email as string)}
                />
              </div>

              <Button
                label="SUBMIT"
                type="submit"
                disabled={!isValid}
                //   isLoading={isLoading || isSubmitting}
                isLoading={isSubmitting}
                className=" px-[20px] py-[10px] text-[14px] font-[900] mb-[24px]"
              />
              {/* <Typography size={12} className="font-[700] max-w-[220px] leading-[18px] mt-[10px]">
                A reset password link has been sent to your registered Email ID
              </Typography> */}
            </form>
            <Typography size={12} className="font-[900] leading-[18px] mb-[12px] cursor-pointer">
              <span onClick={() => navigate(-1)} className="underline font-[900] text-green-light">
                {' '}
                BACK
              </span>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
