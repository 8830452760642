import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import globalSlice from 'src/store/global/global.slice'
import { RESET_STORE, RootReduxState } from 'src/store/redux.types'
import { analyticsApi } from './analytics/analytics.api.ts'
import { authApi } from './auth/auth.api'
import authSlice from './auth/auth.slice'
import { brandApi } from './brand/brand.api'
import brandSlice from './brand/brand.slice'
import { creativeApi } from './creatives/creative.api'
import creativeSlice from './creatives/creative.slice'
import snackbarSlice from './snackbar/snackbar.slice'
import OnboardingSlice from './onboarding/onboarding.slice.ts'
import { onboardingApi } from './onboarding/onboarding.api.ts'

const appReducer = combineReducers({
  globalSlice,
  snackbarSlice,
  brandSlice,
  authSlice,
  creativeSlice,
  OnboardingSlice,
  [brandApi.reducerPath]: brandApi.reducer,
  [creativeApi.reducerPath]: creativeApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [analyticsApi.reducerPath]: analyticsApi.reducer,
  [onboardingApi.reducerPath]: onboardingApi.reducer
})

const rootReducer = (state: any, action: any) => {
  if (action.type === RESET_STORE) {
    state = undefined
  }

  return appReducer(state, action)
}

const useStateSelector: TypedUseSelectorHook<RootReduxState> = useSelector
export { useStateSelector }

export default rootReducer
