import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ApiRoutes } from 'src/constants/routes'
import { prepareHeaders } from 'src/utils/headers'
import {
  ForgotPassword,
  LoginRequest,
  Password,
  ResetPassword,
  SignupRequest,
  User
} from './auth.type'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ApiRoutes.BASE_URL,
    prepareHeaders: headers => prepareHeaders(headers)
  }),
  tagTypes: [],
  endpoints: builder => ({
    login: builder.mutation<User, LoginRequest>({
      query: values => ({
        url: ApiRoutes.LOGIN,
        method: 'POST',
        body: values
      })
    }),
    signup: builder.mutation<User, SignupRequest>({
      query: values => ({
        url: ApiRoutes.SIGNUP,
        method: 'POST',
        body: values
      })
    }),
    guestSignup: builder.mutation<User, SignupRequest>({
      query: values => ({
        url: ApiRoutes.GUEST_SIGNUP,
        method: 'POST',
        body: values
      })
    }),
    user: builder.query<User, {}>({
      query: () => ({
        url: ApiRoutes.USER_ME
      })
    }),
    assumeUser: builder.mutation<User, {}>({
      query: userEmail => ({
        url: `${ApiRoutes.ASSUME_USER}?user_email=${userEmail}`,
        method: 'POST'
      })
    }),
    forgotPassword: builder.mutation<string, ForgotPassword>({
      query: values => ({
        url: ApiRoutes.FORGOT_PASSWORD,
        method: 'POST',
        body: values
      })
    }),
    changePassword: builder.mutation<string, Password>({
      query: values => ({
        url: ApiRoutes.CHANGE_PASSWORD,
        method: 'POST',
        body: values
      })
    }),
    resetPassword: builder.mutation<string, ResetPassword>({
      query: values => ({
        url: ApiRoutes.RESET_PASSWORD,
        method: 'POST',
        body: values
      })
    }),
    googleLoginUser: builder.mutation<User, string>({
      query: values => ({
        url: ApiRoutes.GOOGLE_SIGNIN,
        method: 'POST',
        body: { accessToken: values }
      })
    }),
    googleSignupUser: builder.mutation<User, string>({
      query: values => ({
        url: ApiRoutes.GOOGLE_SIGNIN,
        method: 'POST',
        body: { accessToken: values }
      })
    })
  })
})

export const {
  useLoginMutation,
  useSignupMutation,
  useGuestSignupMutation,
  useLazyUserQuery,
  useAssumeUserMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useGoogleLoginUserMutation,
  useGoogleSignupUserMutation
} = authApi
