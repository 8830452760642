import { useState } from 'react'
import { useAppDispatch } from 'src/store'
import { useLazyGetBrandByIdQuery } from 'src/store/brand/brand.api'
import { setSelectedBrand } from 'src/store/brand/brand.slice'
import { BrandsWithCreativeCount } from 'src/store/brand/brand.type'
import { RtkError } from 'src/store/redux.types'
import { useStateSelector } from 'src/store/root.reducer'
import useHandleErrorMessage from './useIsErrorMessage'

const useGetBrandById = () => {
  const dispatch = useAppDispatch()
  const handleErrorMessage = useHandleErrorMessage()
  const { selectedBrand } = useStateSelector(state => state.brandSlice)
  const [getBrand, { isLoading, error }] = useLazyGetBrandByIdQuery()
  const [brandData, setBrandData] = useState<BrandsWithCreativeCount>()

  const getBrandData = async (id: string) => {
    if (selectedBrand && id && selectedBrand?.uuid === id) {
      setBrandData(selectedBrand as BrandsWithCreativeCount)
      return selectedBrand
    } else {
      return getBrand(id)
        .then(res => {
          setBrandData(res.data)
          dispatch(setSelectedBrand(res.data as BrandsWithCreativeCount))
          return res.data
        })
        .catch((error: RtkError) => {
          if (error) {
            handleErrorMessage(error.data.error.message, true)
            return null
          }
        })
    }
  }
  return { getBrandData, data: brandData, isLoading, error }
}
export default useGetBrandById
