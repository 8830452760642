import { forwardRef, LegacyRef } from 'react'

interface Props {
  color: string
}

const ExploreIcon = ({ color = 'var(--black)' }: Props, ref: LegacyRef<SVGSVGElement>) => (
  <svg
    width={23}
    height={23}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}>
    <path
      d="M11.5 1C5.7025 1 1 5.7025 1 11.5C1 17.2975 5.7025 22 11.5 22C17.2975 22 22 17.2975 22 11.5C22 5.7025 17.2975 1 11.5 1ZM11.5 20.5C6.5275 20.5 2.5 16.4725 2.5 11.5C2.5 6.5275 6.5275 2.5 11.5 2.5C16.4725 2.5 20.5 6.5275 20.5 11.5C20.5 16.4725 16.4725 20.5 11.5 20.5ZM15.94 6.055L9.5725 9.25C9.43 9.325 9.31 9.445 9.235 9.5875L6.055 15.94C5.875 16.315 6.025 16.7575 6.4 16.945C6.505 16.9975 6.6175 17.02 6.73 17.02C6.8425 17.02 6.955 16.9975 7.06 16.945L13.4275 13.75C13.57 13.675 13.69 13.555 13.765 13.4125L16.945 7.045C17.0875 6.76 17.0275 6.4075 16.8025 6.1825C16.57 5.9575 16.225 5.9125 15.94 6.055ZM12.535 12.535L8.4025 14.5975L10.465 10.465L14.5975 8.4025L12.535 12.535Z"
      fill={color}
      stroke={color}
      strokeWidth={0.3}
    />
  </svg>
)
export default forwardRef(ExploreIcon)
