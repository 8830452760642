const LeftArrowIcon = () => (
  <svg
    width="18px"
    height="18px"
    fill="#fff"
    viewBox="0 0 512 512"
    data-name="Layer 1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M34,256,210,80l21.21,21.2L91.4,241H478v30H91.4L231.25,410.84,210,432Z" />
  </svg>
)
export default LeftArrowIcon
