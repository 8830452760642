import { LegacyRef, forwardRef } from 'react'

interface Props {
  color: string
}

const DownloadIcon = ({ color = 'var(--black)' }: Props, ref: LegacyRef<SVGSVGElement>) => (
  <svg
    width={18}
    height={17}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}>
    <g clipPath="url(#clip0_933_163574)">
      <path
        d="M9 0C8.505 0 8.1 0.405 8.1 0.9V9.522L6.039 7.461C5.688 7.11 5.121 7.11 4.77 7.461C4.419 7.812 4.419 8.379 4.77 8.73L8.325 12.285C8.487 12.474 8.73 12.591 9 12.591C9.243 12.591 9.468 12.492 9.63 12.33C9.648 12.312 9.666 12.294 9.684 12.285L13.239 8.73C13.59 8.379 13.59 7.812 13.239 7.461C12.888 7.11 12.321 7.11 11.97 7.461L9.9 9.531V0.9C9.9 0.405 9.495 0 9 0Z"
        fill={color}
      />
      <path
        d="M0.9 14.4C0.405 14.4 0 14.805 0 15.3C0 15.795 0.405 16.2 0.9 16.2H17.1C17.595 16.2 18 15.795 18 15.3C18 14.805 17.595 14.4 17.1 14.4H0.9Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_933_163574">
        <rect width={18} height={16.2} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default forwardRef(DownloadIcon)
