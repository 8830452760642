import { useState, useEffect } from 'react'

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(() => window.matchMedia('(max-width: 767px)').matches)

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width: 767px)')

    const handleMobileChange = (event: MediaQueryListEvent) => {
      setIsMobile(event.matches)
    }

    mobileMediaQuery.addListener(handleMobileChange)

    return () => {
      mobileMediaQuery.removeListener(handleMobileChange)
    }
  }, [])

  return isMobile
}

export default useIsMobile
