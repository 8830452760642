import { debounce, orderBy } from 'lodash'
import { useCallback, useEffect, useState, useRef } from 'react'
import assets from 'src/assets'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import IconButton from 'src/components/ui/iconButton'
import { TextField } from 'src/components/ui/textfield'
import { FontsData } from 'src/pages/Brand/AddEditBrand'

interface FontSelectorProps {
  handleFont: (font: string) => void
  handleClose: () => void
  fontSelect: string
  fontsData?: FontsData[]
}

interface HandleOnClickProps {
  name: string
  url: string
}

const FontSelector = ({ fontsData, handleFont, handleClose, fontSelect }: FontSelectorProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [selectedFont, setSelectedFont] = useState<string>(fontSelect)
  const [searchString, setSearchString] = useState<string>('')
  const [fontData, setFontData] = useState<{ name: string; url: string }[]>([])

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView()
    }
  }, [selectedFont])

  useEffect(() => {
    if (fontsData) {
      const allFonts = fontsData.flatMap(({ primary_font, secondary_font }: FontsData) => [
        { name: primary_font.fontName, url: primary_font.url },
        { name: secondary_font.fontName, url: secondary_font.url }
      ])

      const uniqueFonts = Array.from(new Set(allFonts.map((font: { name: string }) => font.name)))
        .map(name => allFonts.find((font: { name: string }) => font.name === name)!)
        .filter((font): font is { name: string; url: string } => !!font.url)

      setFontData(uniqueFonts)
    }
  }, [fontsData])

  const handleOnClick = ({ name, url }: HandleOnClickProps) => {
    setSelectedFont(name)
    const font = new FontFace(name, `url(${url})`)
    font.load().then(loadedFont => {
      document.fonts.add(loadedFont)
    })
    // .catch(_error => {
    //   // console.error(`Error loading font "${name}":`, error);
    // });
  }

  const handleOnChange = useCallback(
    debounce((value: string) => {
      setSearchString(value)
    }, 500),
    []
  )

  const filteredFonts = fontData.filter(
    font => font.name?.toLowerCase().includes(searchString.toLowerCase())
  )

  return (
    <div>
      <TextField
        fullWidth
        variant={'rounded'}
        placeholder="Search Font"
        className="bg-white"
        id="fonts"
        rightIcon={<img alt="search-icon" src={assets.svgs.SEARCH} className="w-5 h-5 mr-2 " />}
        handleOnChange={(_, value) => handleOnChange(value)}
      />

      <div className="max-h-[383px] mt-5 overflow-y-scroll scrollbar-hide flex flex-col mb-6">
        {filteredFonts.length > 0 ? (
          orderBy(filteredFonts, 'name').map(({ name, url }) => {
            return (
              <div
                key={name}
                ref={selectedFont === name ? containerRef : null}
                style={{ fontFamily: name }}
                onClick={() => handleOnClick({ name, url })}
                className={`rounded-lg flex  items-center justify-between cursor-pointer py-3 px-5  hover:bg-white ${
                  selectedFont === name && 'bg-white'
                } `}>
                <Typography className="flex items-center h-[32px]">{name}</Typography>
                {selectedFont === name && (
                  <IconButton size={'md'} variant={'secondary'} path={assets.svgs.TICK} />
                )}
              </div>
            )
          })
        ) : (
          <div className="mx-auto my-3">No data found!</div>
        )}
      </div>
      {filteredFonts.length > 0 && (
        <>
          <Button
            label="SAVE"
            className="ml-2 px-12 mb-3"
            onClick={() => {
              handleFont(selectedFont)
              handleClose()
            }}
          />
        </>
      )}
    </div>
  )
}

export default FontSelector
