import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PUBLIC_ROUTES } from 'src/components/Layout'
import { AppRoutes } from 'src/constants/routes'
import { useAppDispatch } from 'src/store'
import { useLazyUserQuery } from 'src/store/auth/auth.api'
import { setAccount } from 'src/store/auth/auth.slice'
import { useStateSelector } from 'src/store/root.reducer'
import { fetchCookie, removeCookie } from 'src/utils/cookies'
import useHandleErrorMessage from './useIsErrorMessage'
import { setUserForTracking } from '../amplitude'
import LogRocket from 'logrocket'

const useGetUserData = () => {
  const dispatch = useAppDispatch()
  const user = useStateSelector(state => state.authSlice.user)
  const [getUser] = useLazyUserQuery()
  const token = fetchCookie('auth_token')
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleErrorMessage = useHandleErrorMessage()

  useEffect(() => {
    const getUserData = async () => {
      if (!PUBLIC_ROUTES.includes(pathname)) {
        if (!user && token) {
          getUser({})
            .then(res => {
              if ('data' in res) {
                dispatch(setAccount(res?.data))
                if (res.data) {
                  LogRocket.identify(res.data.uuid, {
                    name: res.data.fullName,
                    email: res.data.email
                  })
                }
                Sentry.setUser({ email: res.data?.email, username: res.data?.fullName })
                if (res.data) setUserForTracking(res.data.uuid, res.data.fullName, res.data.email)
              }
            })
            .catch(() => {
              removeCookie('auth_token')
              handleErrorMessage('Session has been expired! Please login again', true)
              navigate(AppRoutes.LOGIN)
            })
        }
      }
    }
    getUserData().then()
  }, [])
}

export default useGetUserData
