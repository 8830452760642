const CropIconComponent = () => (
  <svg
    className="m-[10px]"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.72448 1.93103V3.44828H11.4762C11.7631 3.44828 12.0334 3.55862 12.2376 3.76276C12.4417 3.96138 12.5521 4.23724 12.5521 4.52414V15.2662C12.5521 15.4593 12.6293 15.6469 12.7672 15.7848C12.9052 15.9228 13.0928 16 13.2859 16H13.33C13.5231 16 13.7107 15.9228 13.8486 15.7848C13.9866 15.6469 14.0638 15.4593 14.0638 15.2662V4.28138C14.0638 2.97931 13.01 1.93103 11.7134 1.93103H3.72448ZM12.2707 14.069C12.2707 14.069 12.2707 14.0359 12.2707 14.0193V12.6069C12.2707 12.6069 12.2707 12.5683 12.2707 12.5517H4.51896C4.23207 12.5517 3.96172 12.4414 3.75758 12.2372C3.55345 12.0386 3.4431 11.7628 3.4431 11.4759V0.755862C3.4431 0.557241 3.36586 0.364138 3.22241 0.22069C3.07896 0.0772414 2.88586 0 2.68724 0C2.26793 0 1.93138 0.336552 1.93138 0.755862V11.7131C1.93138 13.0097 2.98517 14.0634 4.28724 14.0634H12.2762L12.2707 14.069ZM14.3397 12.5517V14.069H15.239C15.6583 14.069 15.9948 13.7324 15.9948 13.3131C15.9948 12.8938 15.6583 12.5517 15.239 12.5517H14.3397ZM1.65551 1.93103H0.739653C0.546549 1.93103 0.358963 2.00828 0.221032 2.14621C0.0831008 2.28414 0.00585938 2.47172 0.00585938 2.67034V2.71448C0.00585938 2.90759 0.0831008 3.09517 0.221032 3.23862C0.358963 3.37655 0.546549 3.45379 0.739653 3.45379H1.65551V1.93655V1.93103Z"
      fill="white"
    />
  </svg>
)
export default CropIconComponent
