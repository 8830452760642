import { useNavigate } from 'react-router-dom'
import assets from 'src/assets'
import LeftArrowIcon from 'src/components/LeftArrow'
import Typography from 'src/components/Typography'
import { AppRoutes } from 'src/constants/routes'
import { isGuest } from 'src/utils/cookies'

interface VariantErrorProps {
  errorMessage: string
}

const VariantError = ({ errorMessage }: VariantErrorProps) => {
  const navigate = useNavigate()
  return (
    <div className="flex gap-[10px] flex-col ">
      <div className={'z-10'}>
        <Typography size={30} className="font-[800] text-text-primary">
          OOPS!
        </Typography>
        <Typography size={30} className="font-[800] text-text-primary">
          Seems like you're facing an unknown error!
        </Typography>

        <Typography size={20} className="font-[400] text-text-secondary">
          Please stand by as our teams have been notified about the issue.
        </Typography>

        <Typography size={16} className="font-[700] text-text-primary">
          Error : {errorMessage}
        </Typography>
      </div>
      <div className="absolute bottom-0 right-0 z-[0]">
        <img
          src={assets.images.ERROR_VARIANT}
          alt="error_variant"
          className="rounded-br-[5px] opacity-70"
        />
      </div>
      {!isGuest() && (
        <div
          onClick={() => {
            navigate(AppRoutes.DASHBOARD)
          }}
          className="flex z-10 w-[fit-content] items-center gap-[10px] px-[20px] py-[8px] h-[fit-content] bg-gradient-to-b from-main-secondary hover:bg-gradient-to-r hover:from-green-light hover:to-main-secondary to-green-light text-white shadow rounded-full  cursor-pointer">
          <LeftArrowIcon />
          <Typography size={18} className="font-[600]">
            Go back to Dashboard
          </Typography>
        </div>
      )}
    </div>
  )
}

export default VariantError
