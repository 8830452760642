import { useEffect } from 'react'
import assets from 'src/assets'
import Typography from 'src/components/Typography'
import PerformanceSkeleton from 'src/components/ui/performanceSkeleton'
import { useAnalyticsQuery } from '../../../store/analytics/analytics.api.ts'
import PerformanceCard from './PerformanceCard'

const PerformanceList = () => {
  const { data: analytics, isLoading, refetch } = useAnalyticsQuery(undefined)

  useEffect(() => {
    refetch()
  }, [])
  return (
    <div className="flex flex-col gap-[10px]">
      <Typography size={14} className=" font-[900] leading-[18px] text-text-primary">
        PERFORMANCE SUMMARY
      </Typography>
      <div className="flex gap-[30px] flex-wrap">
        {!isLoading ? (
          <>
            <PerformanceCard
              count={analytics?.creativesCount || 0}
              icon={assets.svgs.BAG}
              name="CREATIVES"
            />
            <PerformanceCard
              count={analytics?.brandCount || 0}
              icon={assets.svgs.TENT}
              name="BRANDS"
            />
          </>
        ) : (
          <PerformanceSkeleton />
        )}
      </div>
    </div>
  )
}

export default PerformanceList
