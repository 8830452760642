import { forwardRef, LegacyRef } from 'react'

interface Props {
  color: string
}

const SendIcon = ({ color = 'var(--black)' }: Props, ref: LegacyRef<SVGSVGElement>) => (
  <svg
    ref={ref}
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.6018 1.03801L1.8216 5.80402C1.43564 5.90802 1.14167 6.20002 1.03768 6.58802C0.933691 6.97602 1.04168 7.37402 1.32365 7.65802C1.35965 7.69402 1.40164 7.72201 1.44964 7.74201L10.5767 11.424L14.2584 20.548C14.2784 20.596 14.3064 20.638 14.3423 20.674C14.5543 20.886 14.8323 21 15.1203 21C15.2163 21 15.3143 20.988 15.4122 20.962C15.7982 20.858 16.0922 20.566 16.1942 20.178L20.9597 2.39601C21.0637 2.01001 20.9557 1.61001 20.6737 1.32601C20.3897 1.04201 19.9898 0.936015 19.6018 1.03801ZM20.2298 2.20001L15.4642 19.982C15.4162 20.16 15.2743 20.214 15.2163 20.23C15.1643 20.244 15.0423 20.264 14.9263 20.18L11.3127 11.22L16.1342 6.40001C16.2822 6.25202 16.2822 6.01401 16.1342 5.86601C15.9862 5.71801 15.7482 5.71801 15.6002 5.86601L10.7767 10.688L1.8176 7.07202C1.73561 6.95602 1.75361 6.83401 1.76761 6.78201C1.78361 6.72201 1.8396 6.58002 2.01558 6.53402L19.7978 1.76801C19.9758 1.72001 20.0938 1.81601 20.1378 1.86001C20.1818 1.90201 20.2758 2.02201 20.2298 2.20001Z"
      fill={color}
      stroke={color}
      strokeWidth={1}
    />
  </svg>
)
export default forwardRef(SendIcon)
