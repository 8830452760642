import { GoogleOAuthProvider } from '@react-oauth/google'
import { captureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Snackbar from 'src/components/Snackbar/index.tsx'
import 'src/styles/global.css'
import App from './App.tsx'
import { initialize } from './amplitude'
import Layout from './components/Layout/index.tsx'
import store from './store'

import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

if (import.meta.env.VITE_PROJECT_ENV === 'production') {
  LogRocket.init(`${import.meta.env.VITE_LOGROCKET_APPID}`)
}
setupLogRocketReact(LogRocket)

initialize()
Sentry.init({
  dsn: `${
    import.meta.env.VITE_PROJECT_ENV === 'production' ||
    import.meta.env.VITE_PROJECT_ENV === 'staging'
      ? import.meta.env.VITE_SENTRY_DSN
      : ''
  }`,
  beforeSend(event) {
    const logRocketSession = LogRocket.sessionURL
    if (event.extra && logRocketSession !== null) {
      event.extra['LogRocket'] = logRocketSession
      return event
    } else {
      return event
    }
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true
    }),
    // @ts-ignore
    captureConsoleIntegration({ type: ['error'] })
  ],
  environment: `${import.meta.env.VITE_PROJECT_ENV}`,
  // Performance Monitoring
  replaysOnErrorSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  replaysSessionSampleRate: 0.1,
  // Session Replay
  tracePropagationTargets: [/^https:\/\/app.eacel\.ai/], // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  tracesSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
// async function enableMocking() {
//   if (!import.meta.env.VITE_IS_MOCK) {
//     return
//   }
//   const { worker } = await import('./mocks/browser')
//   return worker.start()
// }
// enableMocking().then(() => {
//   return ReactDOM.createRoot(document.getElementById('root')!).render(
//     <React.StrictMode>
//       <ReduxProvider store={store}>
//         <BrowserRouter>
//           <Snackbar />
//           <Layout>
//             <App />
//           </Layout>
//         </BrowserRouter>
//       </ReduxProvider>
//     </React.StrictMode>
//   )
// })

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <BrowserRouter>
          <Snackbar />
          <Layout>
            <App />
          </Layout>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </ReduxProvider>
  </React.StrictMode>
)
