import { LegacyRef, forwardRef } from 'react'
interface Props {
  color: string
}

const BellIcon = ({ color = 'var(--black)' }: Props, ref: LegacyRef<SVGSVGElement>) => (
  <svg
    width={23}
    height={24}
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}>
    <path
      d="M13.3066 21.2697H9.68701C9.08978 21.2697 8.60114 21.7583 8.60114 22.3556C8.60114 22.9528 9.08978 23.4415 9.68701 23.4415H13.3066C13.9038 23.4415 14.3925 22.9528 14.3925 22.3556C14.3925 21.7583 13.9038 21.2697 13.3066 21.2697Z"
      fill={color}
    />
    <path
      d="M3.76537 10.1069C3.70021 10.6317 3.4577 11.124 3.08126 11.5004L1.99177 12.5899C1.3511 13.2306 1 14.0812 1 14.9861C1 16.8538 2.52023 18.374 4.38794 18.374H18.6021C20.4698 18.374 21.99 16.8538 21.99 14.9861C21.99 14.0812 21.6389 13.2306 20.9982 12.5899L19.9087 11.4968C19.5359 11.124 19.2934 10.6281 19.2246 10.1033L18.8989 7.5225C18.4283 3.80156 15.2431 1 11.4968 1C7.75053 1 4.5653 3.80518 4.09475 7.5225L3.76899 10.1033H3.76537V10.1069ZM5.91902 10.3784L6.24479 7.79759C6.57779 5.15891 8.83641 3.17175 11.4932 3.17175C14.15 3.17175 16.4086 5.15891 16.7416 7.79759L17.0674 10.3784C17.194 11.3774 17.6574 12.3221 18.3668 13.0315L19.4563 14.121C19.6843 14.349 19.811 14.6603 19.811 14.9825C19.811 15.6521 19.2644 16.1987 18.5948 16.1987H4.38794C3.71831 16.1987 3.17175 15.6521 3.17175 14.9825C3.17175 14.6603 3.30206 14.349 3.52647 14.121L4.61597 13.0315C5.32903 12.3185 5.78872 11.3774 5.9154 10.3784H5.91902Z"
      fill={color}
    />
  </svg>
)
export default forwardRef(BellIcon)
