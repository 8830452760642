import { LegacyRef, forwardRef } from 'react'

interface Props {
  color: string
}

const ClaimIcon = ({ color = 'var(--black)' }: Props, ref: LegacyRef<SVGSVGElement>) => (
  <svg
    width={20}
    height={24}
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}>
    <path
      d="M13.705 10.705C14.095 10.315 14.095 9.68 13.705 9.29C13.315 8.9 12.68 8.9 12.29 9.29L8.99501 12.585L7.70001 11.29C7.31001 10.9 6.67501 10.9 6.28501 11.29C5.89501 11.68 5.89501 12.315 6.28501 12.705L8.28501 14.705C8.67501 15.095 9.31001 15.095 9.70001 14.705L13.7 10.705H13.705Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.35 1.06556C10.125 0.980558 9.875 0.980558 9.65 1.06556L1.65 4.06556C1.26 4.21056 1 4.58556 1 5.00056V8.50056C1 13.5356 3.37 18.2806 7.4 21.3006L9.4 22.8006C9.755 23.0656 10.245 23.0656 10.6 22.8006L12.6 21.3006C16.63 18.2806 19 13.5356 19 8.50056V5.00056C19 4.58556 18.74 4.21056 18.35 4.06556L10.35 1.06556ZM3 8.50056V5.69556L10 3.07056L17 5.69556V8.50056C17 12.9056 14.925 17.0556 11.4 19.7006L10 20.7506L8.6 19.7006C5.075 17.0556 3 12.9056 3 8.50056Z"
      fill={color}
    />
  </svg>
)
export default forwardRef(ClaimIcon)
