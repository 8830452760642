import assets from 'src/assets'
import Typography from 'src/components/Typography'

interface PreviewVariantProps {
  selectedRenderDimension: string[]
}

const PreviewDimension = ({ selectedRenderDimension }: PreviewVariantProps) => {
  const resolution =
    selectedRenderDimension[0] === 'SQUARE'
      ? '1080 x 1080'
      : selectedRenderDimension[0] === 'LANDSCAPE'
        ? '1800 x 942'
        : '1000 x 1500'
  return (
    <div>
      <div className="rounded-[5px] overflow-hidden">
        <img
          alt={'foreground-url'}
          src={selectedRenderDimension[1] ?? assets.images.PLACEHOLDER}
          className=" w-full h-[530px] object-contain"
        />
      </div>

      <div className="flex items-center justify-center ">
        {selectedRenderDimension[0] && (
          <Typography size={12} className={'font-[700] py-[15px]'}>
            {selectedRenderDimension[0]} <span className="font-[400]">({resolution ?? ''})</span>
          </Typography>
        )}
      </div>
    </div>
  )
}

export default PreviewDimension
