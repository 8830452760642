import React, { ReactElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from 'src/store'
import {
  AssetImageSelection,
  ImageSelection,
  UserAssetWithProvider
} from 'src/store/brand/brand.type'
import { setSnackbar } from 'src/store/snackbar/snackbar.slice'
import { SnackbarType } from 'src/store/snackbar/snackbar.type'
import EazelStockSection from './EazelStockSection'
import ImageSelectionPreview from './ImageSelectionPreview'
import MyAssetsSection from './MyAssetsSection'
import UploadSection from './UploadSection'
import { addCookie, fetchCookie, isGuest } from 'src/utils/cookies'
import Tour from '../../../Tour'
import { defaultOnboardingState, uploadImageSteps } from '../../../data/onboarding.ts'
import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'

interface ImagePickerProps {
  // brandId: string | undefined
  handleClose: () => void
  userAsset: UserAssetWithProvider | null
  setUserAsset: (asset: UserAssetWithProvider | null) => void
  setAspectRatio?: (ratio: number) => void
  aspectRatio?: number
}

interface ImagePickerTabContentProps {
  imageSelection: ImageSelection | null
  setImageSelection: (i: ImageSelection | null) => void
  setUserAsset: (a: UserAssetWithProvider) => void
  children: ReactElement
  handleClose: () => void
  activeTab: string
  setAspectRatio?: (ratio: number) => void
  aspectRatio?: number
  handleError: () => void
  setOnboardingStep: React.Dispatch<React.SetStateAction<number>>
}

const ImagePickerTabContent = ({
  imageSelection,
  setImageSelection,
  setUserAsset,
  handleClose,
  children,
  setAspectRatio,
  aspectRatio,
  activeTab,
  handleError,
  setOnboardingStep
}: ImagePickerTabContentProps) => {
  const dispatch = useAppDispatch()
  if (!imageSelection) {
    return children
  } else {
    return (
      <ImageSelectionPreview
        imageSelection={imageSelection}
        setUserAsset={setUserAsset}
        setImageSelection={setImageSelection}
        reset={() => {
          setOnboardingStep(prev => prev - 1)

          setImageSelection(null)
          dispatch(
            setSnackbar({
              severity: SnackbarType.SUCCESS,
              message: 'Image removed successfully!'
            })
          )
        }}
        handleClose={handleClose}
        activeTab={activeTab}
        aspectRatio={aspectRatio}
        setAspectRatio={setAspectRatio}
        handleError={handleError}
      />
    )
  }
}

const ImagePicker = ({ userAsset, aspectRatio, setAspectRatio, ...props }: ImagePickerProps) => {
  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState(isGuest() ? 'MY ASSETS' : 'UPLOAD')
  const [imageSelection, setImageSelection] = useState<ImageSelection | null>(
    userAsset ? new AssetImageSelection(userAsset) : null
  )
  const [onboardingStep, setOnboardingStep] = useState(0)
  const [run, setRun] = useState(false)

  const onBoardingCookie = fetchCookie('onboarding') ?? ''
  const onboarding = onBoardingCookie ? JSON.parse(onBoardingCookie) : defaultOnboardingState

  const handleTabClick = (tab: string) => {
    setImageSelection(null)
    setActiveTab(tab)
  }
  useEffect(() => {
    if (isGuest() && onboarding.upload) {
      setTimeout(() => {
        setRun(true)
      }, 400)
    }
  }, [])
  useEffect(() => {
    if (userAsset && !imageSelection) {
      setImageSelection(new AssetImageSelection(userAsset))
    } else if (userAsset && imageSelection?.url != userAsset.presignedUrl) {
      setImageSelection(new AssetImageSelection(userAsset))
    }
  }, [userAsset])

  const handleCallback = (data: CallBackProps) => {
    const { action, index, type, status } = data
    if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      setOnboardingStep(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setOnboardingStep(0)
      setRun(false)
      addCookie('onboarding', JSON.stringify({ ...onboarding, upload: false }))
    }
  }
  return (
    <div>
      <div className="flex tab-container justify-center gap-[50px]">
        {/* <img src={imageSelection?.dataUrl} alt="" /> */}
        {pathname !== '/my-assets' && (
          <button
            className={activeTab === 'MY ASSETS' ? 'font-[900]' : ''}
            onClick={() => handleTabClick('MY ASSETS')}>
            MY ASSETS
          </button>
        )}
        <button
          className={activeTab === 'EACEL STOCK' ? 'font-[900]' : ''}
          onClick={() => handleTabClick('EACEL STOCK')}>
          EACEL STOCK
        </button>
        <button
          className={activeTab === 'UPLOAD' ? 'font-[900]' : ''}
          onClick={() => handleTabClick('UPLOAD')}>
          UPLOAD
        </button>
      </div>

      <div className="tab-content w-[100%] lg:w-[100%] xl:w-[80%] m-auto">
        <ImagePickerTabContent
          imageSelection={imageSelection}
          setImageSelection={setImageSelection}
          handleClose={props.handleClose}
          setUserAsset={props.setUserAsset}
          setAspectRatio={setAspectRatio}
          aspectRatio={aspectRatio}
          activeTab={activeTab}
          setOnboardingStep={setOnboardingStep}
          handleError={() => {
            setOnboardingStep(prev => prev - 1)
          }}>
          <>
            {activeTab === 'EACEL STOCK' && (
              <EazelStockSection
                setImageSelection={val => {
                  setImageSelection(val)
                  setOnboardingStep(prev => prev + 1)
                }}
              />
            )}

            {activeTab === 'UPLOAD' && (
              <UploadSection
                setImageSelection={val => {
                  setImageSelection(val)
                  setOnboardingStep(prev => prev + 1)
                }}
              />
            )}

            {activeTab === 'MY ASSETS' && (
              <MyAssetsSection
                setImageSelection={val => {
                  setImageSelection(val)
                  setOnboardingStep(prev => prev + 1)
                }}
              />
            )}
          </>
        </ImagePickerTabContent>
      </div>
      <Tour
        run={run}
        stepIndex={onboardingStep}
        step={uploadImageSteps}
        callback={handleCallback}
      />
    </div>
  )
}

export default ImagePicker
