import { forwardRef, LegacyRef } from 'react'

interface Props {
  color: string
}

const ViewMoreIcon = ({ color = 'var(--black)' }: Props, ref: LegacyRef<SVGSVGElement>) => (
  <svg
    width={25}
    height={7}
    viewBox="0 0 25 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}>
    <path
      d="M21.2439 6.51447C19.7239 6.51447 18.4878 5.27835 18.4878 3.75835C18.4878 2.23835 19.7216 1 21.2439 1C22.7661 1 24 2.23612 24 3.75612C24 5.27612 22.7639 6.51224 21.2439 6.51224V6.51447ZM21.2439 1.89412C20.2156 1.89412 19.3819 2.73012 19.3819 3.75612C19.3819 4.78212 20.2179 5.61812 21.2439 5.61812C22.2699 5.61812 23.1059 4.78212 23.1059 3.75612C23.1059 2.73012 22.2699 1.89412 21.2439 1.89412ZM12.462 6.51447C10.942 6.51447 9.70588 5.27835 9.70588 3.75835C9.70588 2.23835 10.942 1 12.462 1C13.982 1 15.2181 2.23612 15.2181 3.75612C15.2181 5.27612 13.982 6.51224 12.462 6.51224V6.51447ZM12.462 1.89412C11.4338 1.89412 10.6 2.73012 10.6 3.75612C10.6 4.78212 11.436 5.61812 12.462 5.61812C13.488 5.61812 14.324 4.78212 14.324 3.75612C14.324 2.73012 13.488 1.89412 12.462 1.89412ZM3.75612 6.51447C2.23612 6.51447 1 5.27612 1 3.75612C1 2.23612 2.23612 1 3.75612 1C5.27612 1 6.51224 2.23612 6.51224 3.75612C6.51224 5.27612 5.27612 6.51224 3.75612 6.51224V6.51447ZM3.75612 1.89412C2.73012 1.89412 1.89412 2.73012 1.89412 3.75612C1.89412 4.78212 2.73012 5.61812 3.75612 5.61812C4.78212 5.61812 5.61812 4.78212 5.61812 3.75612C5.61812 2.73012 4.78212 1.89412 3.75612 1.89412Z"
      fill={color}
      stroke={color}
      strokeWidth={1}
    />
  </svg>
)
export default forwardRef(ViewMoreIcon)
