// VideoDialog.tsx

import { Dialog, DialogContent, DialogDescription } from '../ui/dialog'
import Typography from '../Typography'
import GuestAuthForm from '../../pages/GuestAuth/GuestAuthForm.tsx'
import { useEffect } from 'react'
import { trackEvent } from 'src/amplitude/index.ts'
import { events } from 'src/amplitude/data.ts'

interface GuestAuthDialogProps {
  isOpen: boolean
  fromCreative?: boolean
  handleClose: () => void
}

const GuestAuthDialog = ({ isOpen, handleClose, fromCreative = false }: GuestAuthDialogProps) => {
  useEffect(() => {
    if (isOpen) {
      trackEvent(events.ONBOARDING_SIGNUP_FORM_VIEWED, {
        trigger: fromCreative ? 'creative_download' : ''
      })
    }
  }, [isOpen])
  return (
    <Dialog
      open={isOpen}
      onOpenChange={val => {
        if (!val) {
          handleClose()
          trackEvent(events.ONBOARDING_SIGNUP_FORM_CLOSED)
        }
      }}>
      <DialogContent className="GuestModal bg-white p-0 border-none max-w-lg ">
        <DialogDescription>
          <div className="flex flex-col items-center p-4">
            <Typography
              size={20}
              className="font-[800] text-center mt-10 leading-[36px] text-text-primary flex flex-wrap pr-[10px]">
              Sign up now for more creatives
            </Typography>
            <GuestAuthForm />
          </div>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}

export default GuestAuthDialog
