import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ApiRoutes } from 'src/constants/routes'
import { prepareHeaders } from 'src/utils/headers'
import {
  Creative,
  CreativeVariantDimensionsWithStatus,
  CreativeVariantWithStatus,
  generateAdCopyTypes,
  GetMoreVariant
} from './creative.type'

export const creativeApi = createApi({
  reducerPath: 'creativeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ApiRoutes.BASE_URL,
    prepareHeaders: (headers, store) => prepareHeaders(headers, store)
  }),
  tagTypes: ['creative', 'analytics'],

  endpoints: builder => ({
    createCreatives: builder.mutation<Creative, { id: string; body: Creative }>({
      query: ({ id, body }) => ({
        url: ApiRoutes.CREATE_CREATIVE(id),
        method: 'POST',
        body
      }),
      invalidatesTags: ['analytics']
    }),
    archiveCreative: builder.mutation<string, { brandId: string; creativeId: string }>({
      query: ({ brandId, creativeId }) => ({
        url: ApiRoutes.ARCHIVE_CREATIVE(brandId, creativeId),
        method: 'POST'
      }),
      invalidatesTags: ['creative']
    }),
    getCreativeVariants: builder.query<CreativeVariantWithStatus, {}>({
      query: (creativeId: string) => {
        return {
          url: `${import.meta.env.VITE_API_BASE_URL}${ApiRoutes.GET_CREATIVE_VARIANTS(creativeId)}`,
          method: 'GET'
        }
      }
    }),
    getVariantDimensionsById: builder.query<
      CreativeVariantDimensionsWithStatus,
      { variantId: string }
    >({
      query: ({ variantId }) => {
        return {
          url: `${import.meta.env.VITE_API_BASE_URL}${ApiRoutes.GET_VARIANT_DIMENSIONS(variantId)}`,
          method: 'GET'
        }
      }
    }),

    getVariantById: builder.mutation<CreativeVariantDimensionsWithStatus, string | undefined>({
      query: (id: string) => ({ url: ApiRoutes.GET_VARIANT(id), method: 'POST' })
    }),
    getCreativeById: builder.query<Creative, string | undefined>({
      query: (creativeId: string) => ({ url: ApiRoutes.GET_CREATIVE(creativeId), method: 'GET' })
    }),
    getCreatives: builder.query<Creative[], {}>({
      query: () => ({
        url: ApiRoutes.GET_CREATIVES,
        method: 'GET'
      })
    }),
    getCreativesByBrandId: builder.query<Creative[], string | undefined>({
      query: (id: string) => ({
        url: ApiRoutes.GET_CREATIVES_BY_BRAND_ID(id),
        method: 'GET'
      })
    }),
    getMoreDimensions: builder.query<Creative[], number | undefined>({
      query: (variantId: number) => ({
        url: `${import.meta.env.VITE_API_BASE_URL}${ApiRoutes.GET_MORE_DIMENSIONS(variantId)}`,
        method: 'POST'
      })
    }),
    getMoreVariants: builder.query<Creative, { body: GetMoreVariant }>({
      query: ({ body }) => ({
        url: ApiRoutes.GET_MORE_VARIANTS,
        method: 'POST',
        body
      })
    }),
    getGenerateCreativeText: builder.query<generateAdCopyTypes[], { body: generateAdCopyTypes }>({
      query: ({ body }) => ({
        url: ApiRoutes.GENERATE_CREATIVE_TEXT,
        method: 'POST',
        body
      })
    })
  })
})

export const {
  useCreateCreativesMutation,
  useArchiveCreativeMutation,
  useLazyGetCreativeVariantsQuery,
  useLazyGetVariantDimensionsByIdQuery,
  useGetVariantByIdMutation,
  useLazyGetCreativeByIdQuery,
  useLazyGetCreativesQuery,
  useLazyGetCreativesByBrandIdQuery,
  useLazyGetMoreDimensionsQuery,
  useLazyGetMoreVariantsQuery,
  useLazyGetGenerateCreativeTextQuery
} = creativeApi
