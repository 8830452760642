import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'src/lib/utils'
const iconButton = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm font-medium transition-colors disabled:opacity-50 hover:shadow-lg',
  {
    variants: {
      variant: {
        default:
          'bg-gradient-to-b from-yellow-light to-yellow-dark hover:bg-gradient-to-t from-yellow-dark to-yellow-light',
        primary:
          'bg-gradient-to-b from-yellow-light to-yellow-dark hover:bg-gradient-to-t from-yellow-dark to-yellow-light',
        secondary: 'bg-gradient-to-b from-main-secondary to-green-light'
      },
      size: {
        default: 'h-10 w-10',
        sm: 'h-6 w-6',
        md: 'h-8 w-8',
        lg: 'h-9 w-9'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)
export interface IconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof iconButton> {
  asChild?: boolean
  path?: string
  isDiabled?: boolean
}
const IconButton = React.forwardRef<HTMLButtonElement, IconProps>(
  ({ path, className, variant, size, isDiabled = false, ...props }, ref) => {
    return (
      <button
        type="button"
        disabled={isDiabled}
        className={cn(iconButton({ variant, size, className }))}
        ref={ref}
        {...props}>
        <img
          src={path}
          alt="icon"
          style={{
            objectFit: 'cover',
            padding: '1px'
          }}
        />
      </button>
    )
  }
)
IconButton.displayName = 'Button'
export default IconButton
