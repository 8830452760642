import { ReactNode, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import assets from 'src/assets'
import Loader from 'src/components/Loader'
import Stepper from 'src/components/Stepper'
import Typography from 'src/components/Typography'
import useGetBrandById from 'src/hook/useGetBrandById'
import { useAppDispatch } from 'src/store'
import { setSelectedProjectName } from 'src/store/creatives/creative.slice'
import { useStateSelector } from 'src/store/root.reducer'
import { generateRandomNumber } from 'src/utils'
import { truncateText } from 'src/utils/truncate.ts'
import useGetCreativeById from '../../hook/useGetCreativeById.ts'

interface Props {
  children: ReactNode
}

const CreativeLayout = ({ children }: Props) => {
  const { brandId, creativeId } = useParams()
  const inputRef = useRef<HTMLInputElement>(null!)
  const [projectName, setProjectName] = useState<string>('')
  const { getBrandData, isLoading: brandLoading } = useGetBrandById()
  const { getCreativeData, isLoading: creativeLoading } = useGetCreativeById()
  const [brandName, setBrandName] = useState<string>()
  const [isEdit, setIsEdit] = useState(false)
  const dispatch = useAppDispatch()
  const BrandName = useStateSelector(state => state.brandSlice.selectedBrand?.name)

  // const ProjectName = useStateSelector(state => state.creativeSlice.projectName)

  const handleBrandData = (name: string) => {
    setBrandName(name)
    setProjectName(`${name?.replaceAll(' ', '-')}-${generateRandomNumber(5)}`)
  }

  useEffect(() => {
    if (brandId) {
      getBrandData(brandId).then(res => {
        res?.name && handleBrandData(res.name)
      })
    } else {
      handleBrandData(BrandName as string)
    }
    if (creativeId) {
      getCreativeData(creativeId).then(res => {
        res?.brand?.name && handleBrandData(res.brand.name)
      })
    }
  }, [])

  useEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEdit])

  useEffect(() => {
    if (projectName) {
      dispatch(setSelectedProjectName(projectName))
    }
  }, [projectName])

  const handleIconClick = () => {
    setIsEdit(!isEdit)
  }

  return (
    <>
      {brandLoading || creativeLoading ? (
        <div className="h-[calc(100vh-136px)] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="relative flex flex-col py-[20px] mx-4 gap-[30px] max-w-[1640px]">
          <div className="flex justify-between flex-col lg:flex-row gap-[20px]">
            <Stepper />
            <div className="flex items-center flex-col sm:flex-row justify-start  lg:justify-end  md:gap-[10px] gap-1">
              <Typography
                size={14}
                className="flex flex-col font-[700] sm:w-[247px] w-full text-ellipsis whitespace-nowrap overflow-hidden">
                BRAND: <span className="font-[300] ">{truncateText(brandName as string)}</span>
              </Typography>

              <div className="flex h-[auto] border-[1px] border-divider sm:min-h-[33px] min-h-0 " />
              <div className={'flex pr-[15px] w-full sm:w-auto'}>
                <Typography
                  size={14}
                  className="flex flex-col font-[700] sm:w-[251px] w-full text-ellipsis whitespace-nowrap overflow-hidden">
                  PROJECT:{' '}
                  <span className="font-[300] w-[250px] overflow-hidden text-ellipsis whitespace-nowrap">
                    <input
                      type="text"
                      value={!isEdit ? truncateText(projectName, 33) : projectName}
                      id="project_name"
                      ref={inputRef}
                      disabled={brandId ? !isEdit : true}
                      className="bg-transparent focus-visible:outline-none  w-full overflow-hidden text-ellipsis whitespace-nowrap no-focus-border"
                      placeholder="Type something..."
                      onChange={e => {
                        setProjectName(e.target.value)
                        dispatch(setSelectedProjectName(e.target.value))
                      }}
                      required
                    />
                  </span>
                </Typography>
                {brandId && (
                  <div className="h-[inherit] w-[20px] flex justify-center items-end">
                    <img
                      className=" cursor-pointer self-end  mb-[5px]"
                      src={!isEdit ? assets.svgs.PENCIL : assets.svgs.GREEN_TICK}
                      alt="edit"
                      onClick={() => {
                        handleIconClick()
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>{children}</div>
        </div>
      )}
    </>
  )
}

export default CreativeLayout
