import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious
} from './pagination'

export interface PaginationPropsTypes {
  totalPages: number
  currentPage: number
  onPageChange: (page: number) => void
}

const CustomPagination = ({ totalPages, currentPage, onPageChange }: PaginationPropsTypes) => {
  const renderPageNumbers = () => {
    const maxPagesToShow = 5 // Adjust as needed
    const pageNumbers = []
    const ellipsis = <PaginationEllipsis key="ellipsis" />

    if (totalPages < maxPagesToShow) {
      // If total pages are less than or equal to the maximum to show, display all pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <PaginationItem className="h-fit" key={i} onClick={() => onPageChange(i)}>
            <PaginationLink
              className={
                currentPage === i
                  ? 'border-1 bg-yellow-dark mb-1 p-0 w-[20px] h-[20px] text-[10px] font-[700]'
                  : 'border-1 bg-paper p-0 w-[20px] mb-1 h-[20px] text-[10px] font-[700]'
              }
              size={'small'}
              href="#"
              isActive={currentPage === i}>
              {i}
            </PaginationLink>
          </PaginationItem>
        )
      }
    } else {
      // If there are more pages than the maximum to show, display a subset of pages

      let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1)
      let endPage = startPage + maxPagesToShow - 2

      if (endPage > totalPages) {
        endPage = totalPages
        startPage = Math.max(endPage - maxPagesToShow + 1, 1)
      }

      for (let i = startPage; i <= endPage; i++) {
        if (i !== totalPages) {
          pageNumbers.push(
            <PaginationItem key={i} onClick={() => onPageChange(i)}>
              <PaginationLink
                className={
                  currentPage === i
                    ? 'border-1 bg-yellow-dark mb-1 p-0 w-[20px]  text-[10px] font-[700] flex self-center'
                    : 'border-1 bg-paper p-0 mb-1 w-[20px]  text-[10px] font-[700] flex self-center'
                }
                size={'small'}
                href="#"
                isActive={currentPage === i}>
                {i}
              </PaginationLink>
            </PaginationItem>
          )
        }
      }

      if (endPage < totalPages) {
        pageNumbers.push(ellipsis)
      }

      pageNumbers.push(
        <PaginationItem key={totalPages} onClick={() => onPageChange(totalPages)}>
          <PaginationLink
            className={
              currentPage === totalPages
                ? 'border-1 bg-yellow-dark p-0 mb-1 w-[20px] h-[20px] text-[10px] font-[700]'
                : 'border-1 bg-paper p-0 w-[20px] mb-1 h-[20px] text-[10px] font-[700]'
            }
            size={'small'}
            href="#"
            isActive={currentPage === totalPages}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      )
    }

    return pageNumbers
  }

  return (
    <Pagination className="flex justify-start">
      <PaginationContent className="gap-[20px]">
        <PaginationItem
          onClick={() => {
            if (currentPage > 1) {
              onPageChange(currentPage - 1)
            }
          }}>
          <PaginationPrevious href="#" />
        </PaginationItem>

        {renderPageNumbers()}

        <PaginationItem
          onClick={() => {
            if (currentPage < totalPages) {
              onPageChange(currentPage + 1)
            }
          }}>
          <PaginationNext href="#" />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )
}

export default CustomPagination
