// VideoDialog.tsx

import assets from 'src/assets'
import { Dialog, DialogContent, DialogDescription, DialogTrigger } from '../ui/dialog'

interface VideoDialogProps {
  isLargeImage?: boolean
}

const VideoDialog = ({ isLargeImage = false }: VideoDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger>
        <img src={isLargeImage ? assets.images.DEMO_CLIP : assets.images.DEMO_CLIP2} alt="" />
      </DialogTrigger>
      <DialogContent className=" p-0 border-none max-w-lg lg:max-w-5xl ">
        {/* <DialogHeader> */}
        <DialogDescription>
          <div className="h-[250px] lg:h-[594px] w-full">
            <iframe
              style={{ width: '100%', height: '100%' }}
              allowFullScreen
              src="https://www.loom.com/embed/f6bbb105f72c4399a3a26716ffb07598?sid=58242405-0aa2-4621-948d-65397c3b165a"></iframe>
          </div>
        </DialogDescription>
        {/* </DialogHeader> */}
      </DialogContent>
    </Dialog>
  )
}

export default VideoDialog
