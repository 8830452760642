import { useEffect, useRef, useState } from 'react'
import { ColorResult, SketchPicker } from 'react-color'
import Typography from 'src/components/Typography'
import { cn } from 'src/lib/utils'
import { getContrastColor } from 'src/utils'

interface ColorPickerStyle {
  sm: string
  md: string
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  name: string
  selectedColor?: string
  align?: string
  className?: string
  handleColor: (color: string) => void
  variant?: 'sm' | 'md'
}

const ColorPicker = ({
  className,
  variant = 'md',
  name,
  selectedColor = '#efefef',
  align = 'right',
  handleColor,
  ...props
}: ButtonProps) => {
  const colorPickerStyle: ColorPickerStyle = {
    sm: 'px-[12px]',
    md: 'px-[22px]'
  }
  const ref = useRef<HTMLDivElement>(null!)
  const [color, setColor] = useState<string>(selectedColor)
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  useEffect(() => {
    setColor(selectedColor)
  }, [selectedColor])

  const handleClickAway = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setDisplayColorPicker(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway)
    return () => {
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [])

  const handleChange = (color: ColorResult) => {
    setColor(color.hex)
    handleColor(color.hex as string)
  }

  return (
    <div ref={ref} className={cn('max-w-fit flex flex-col  gap-[5px] relative')}>
      <div className="flex d-flex flex-col items-center">
        <button
          type="button"
          className={cn(
            'text-[12px] min-w-[80px] py-[9px] font-[500] rounded-[5px] shadow',
            colorPickerStyle[variant]
          )}
          style={{
            backgroundColor: color,
            color: getContrastColor(color)
          }}
          {...props}
          onClick={e => {
            e.stopPropagation()
            setDisplayColorPicker(true)
          }}>
          # {color.replace('#', '').toUpperCase()}
        </button>
        <Typography variant="body2" size={12} className="items-center mt-[5px]">
          {name}
        </Typography>
      </div>
      {displayColorPicker && (
        <div
          className={cn(align === 'left' && 'right-0', 'absolute top-[45px]', className)}
          style={{ zIndex: '1' }}>
          <div
            className="fixed top-0 right-0 left-0 bottom-0"
            onClick={() => setDisplayColorPicker(false)}
          />
          <SketchPicker
            styles={{
              default: {
                Hue: {
                  direction: 'rtl'
                }
              }
            }}
            color={color}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  )
}
ColorPicker.displayName = 'ColorPicker'

export default ColorPicker
