import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { maxAsyncApiCall } from 'src/constants/index.ts'
import { useLazyGetCreativeVariantsQuery } from 'src/store/creatives/creative.api'
import { setCreativeStatus, variants } from 'src/store/creatives/creative.slice.ts'
import { CreativeVariantStatus } from 'src/store/creatives/creative.type'
import { useAppDispatch } from 'src/store/index.ts'
import { RtkError } from 'src/store/redux.types'
import { useStateSelector } from 'src/store/root.reducer'
import { trackEvent } from '../amplitude'
import { events } from '../amplitude/data.ts'
import useHandleErrorMessage from './useIsErrorMessage'
import { isGuest } from 'src/utils/cookies.ts'

const useGetVariants = () => {
  const handleErrorMessage = useHandleErrorMessage()
  const dispatch = useAppDispatch()
  const [getVariants, { error }] = useLazyGetCreativeVariantsQuery()
  // const [isLoading, setIsLoading] = useState(false)
  const [stepperLoader, setStepperLoader] = useState(false)
  const [circularLoader, setCircularLoader] = useState(true)
  const [progressLoader, setProgressLoader] = useState(false)

  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [count, setCount] = useState(0)
  const [stepCount, setStepCount] = useState(0)
  const [isRefetch, setIsRefetch] = useState(false)
  const { currentCreative } = useStateSelector(store => store.creativeSlice)
  const [loaderCount, setLoaderCount] = useState(0)

  const allVariants = useStateSelector(store => store.creativeSlice.variants)
  const { creativeId } = useParams()
  useEffect(() => {
    if (error) {
      setStepperLoader(false)
      setCircularLoader(false)
      setProgressLoader(false)
      setIsError(true)
      setErrorMsg('Something went wrong. Please try again later')
      trackEvent(events.AD_CREATION_VARIANTS_PAGE_ERRORED, {
        session_type: isGuest() ? 'guest' : 'registered',
        failure_reason: 'Something went wrong. Please try again later',
        project_name: currentCreative?.projectName
      })
    }
  }, [error])

  useEffect(() => {
    if (currentCreative?.status !== CreativeVariantStatus.PROCESSED) {
      setStepperLoader(true)
    } else {
      if (!allVariants) {
        setCircularLoader(true)
      } else {
        setCircularLoader(false)
      }
    }
  }, [])

  useEffect(() => {
    const fetchVariant = () => {
      // if (isRefetch) {
      if (currentCreative?.id) {
        getVariants(currentCreative.id)
          .then(res => {
            if ('data' in res) {
              if (res.data?.status === CreativeVariantStatus.PROCESSED) {
                if (
                  res.data.generatedCreatives.length === 1 &&
                  res.data.generatedCreatives[0].status === 'failed'
                ) {
                  setCircularLoader(false)
                  setStepperLoader(false)
                  setProgressLoader(false)
                  // setIsLoading(false)
                  setIsError(true)
                  setIsRefetch(false)
                  setErrorMsg(res.data.generatedCreatives[0].errorMessage ?? '')
                  dispatch(variants([]))
                  trackEvent(events.AD_CREATION_VARIANTS_VIEWED, {
                    creative_id: creativeId,
                    session_type: isGuest() ? 'guest' : 'registered',
                    project_name: currentCreative.projectName,
                    number_of_variants_viewed: 0,
                    failure_reason: res.data.generatedCreatives[0].errorMessage ?? ''
                  })
                } else {
                  // Update Redux here
                  dispatch(setCreativeStatus(res.data.status))
                  setStepCount(4)
                  setLoaderCount(4)

                  if (res.data.generatedCreatives && res.data.generatedCreatives.length > 0) {
                    dispatch(variants(res.data?.generatedCreatives))
                    setTimeout(() => {
                      setCircularLoader(false)
                      setStepperLoader(false)
                      setProgressLoader(false)
                      // setIsLoading(false)
                      setIsRefetch(false)
                    }, 1000)
                    trackEvent(events.AD_CREATION_VARIANTS_VIEWED, {
                      creative_id: creativeId,
                      session_type: isGuest() ? 'guest' : 'registered',
                      project_name: currentCreative.projectName,
                      number_of_variants_viewed: res.data.generatedCreatives.length
                    })
                  } else {
                    trackEvent(events.AD_CREATION_VARIANTS_PAGE_ERRORED, {
                      failure_reason: 'Empty results in the case of processed status',
                      session_type: isGuest() ? 'guest' : 'registered',
                      project_name: currentCreative?.projectName
                    })
                    setStepperLoader(false)
                    setCircularLoader(false)
                    setProgressLoader(false)
                    setIsError(true)
                    setErrorMsg('Something went wrong. Please try again later')
                  }
                }
              }
              // if (res.data?.status === CreativeVariantStatus.PROCESSING) {
              //   if (res.data.generatedCreatives) {
              //     if (loaderCount > 3) {
              //       setLoaderCount(loaderCount + 1)
              //     }
              //   }
              // }
              if (
                res.data?.status === CreativeVariantStatus.PENDING ||
                res.data?.status === CreativeVariantStatus.PROCESSING
              ) {
                if (stepCount < 3) {
                  setStepCount(stepCount + 1)
                }

                if (res.data.generatedCreatives && res.data.generatedCreatives.length > 0) {
                  setCircularLoader(false)
                  setStepperLoader(false)
                  setProgressLoader(true)
                  dispatch(variants(res.data?.generatedCreatives))
                  if (loaderCount < 3) {
                    setLoaderCount(loaderCount + 1)
                  }
                } else {
                  setProgressLoader(false)
                }

                setCount(count + 1)
              }

              // if (
              //   res.data?.status === CreativeVariantStatus.PROCESSING &&
              //   res.data.generatedCreatives.length > 0
              // ) {
              //   if (loaderCount < 3) {
              //     setLoaderCount(loaderCount + 1)
              //   }
              //   setLoaderCount(loaderCount + 1)
              // }
              if (res.data?.status === CreativeVariantStatus.FAILED) {
                trackEvent(events.AD_CREATION_VARIANTS_PAGE_ERRORED, {
                  session_type: isGuest() ? 'guest' : 'registered',
                  failure_reason: 'Failed',
                  project_name: currentCreative?.projectName
                })
                setCircularLoader(false)
                setStepperLoader(false)
                setProgressLoader(false)
                // setIsLoading(false)
                setIsError(true)
                setIsRefetch(false)
                setErrorMsg(res.data.errorMessage)
                const error = res as unknown as RtkError
                handleErrorMessage(error.data.error.message, true)
              }
            }
          })
          .catch((error: RtkError) => {
            trackEvent(events.AD_CREATION_VARIANTS_PAGE_ERRORED, {
              session_type: isGuest() ? 'guest' : 'registered',
              failure_reason: 'sessionTimeOut',
              project_name: currentCreative?.projectName
            })
            setCircularLoader(false)
            setStepperLoader(false)
            setProgressLoader(false)
            // setIsLoading(false)
            setIsError(true)
            setIsRefetch(false)
            setErrorMsg('No template found.')
            handleErrorMessage('No template found.', true)
            if (error) {
              handleErrorMessage(error.data.error.message, true)
              setCircularLoader(false)
              setStepperLoader(false)
              setProgressLoader(false)
              // setIsLoading(false)
              setIsRefetch(false)
            }
          })
      }
      // }
    }
    if (count < maxAsyncApiCall) {
      setTimeout(() => {
        !error && fetchVariant()
      }, 3000)
    } else {
      trackEvent(events.AD_CREATION_VARIANTS_PAGE_ERRORED, {
        failure_reason: 'sessionTimeOut',
        session_type: isGuest() ? 'guest' : 'registered',
        project_name: currentCreative?.projectName
      })
      Sentry.captureMessage(`${currentCreative?.uuid}`, 'error')
      setCircularLoader(false)
      setStepperLoader(false)
      setProgressLoader(false)
      // setIsLoading(false)
      setIsError(true)
      setIsRefetch(false)
      setErrorMsg('Session time out.')
      handleErrorMessage('Session time out.', true)
    }
  }, [getVariants, currentCreative?.id, count, circularLoader, isRefetch])
  const refetch = () => {
    setIsRefetch(true)
    setCount(0)
    setStepCount(0)
  }
  return {
    data: allVariants,
    error,
    isError,
    errorMsg,
    stepCount,
    loaderCount,
    refetch,
    stepperLoader,
    circularLoader,
    progressLoader
  }
}

export default useGetVariants
