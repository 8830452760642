import { debounce } from 'lodash'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import assets from 'src/assets'
import Loader from 'src/components/Loader'
import Typography from 'src/components/Typography'
import Drawer from 'src/components/ui/drawer'
import { TextField } from 'src/components/ui/textfield'
import { AppRoutes } from 'src/constants/routes'
import { Brand, BrandsWithCreativeCount } from 'src/store/brand/brand.type'
import { truncateText } from 'src/utils/truncate'
import { resetCreative } from '../../store/creatives/creative.slice.ts'
import { useAppDispatch } from '../../store'

interface BrandSelectorPropsType {
  isBrandSelection: boolean
  isOpenCreativeBrand?: boolean
  handleClose: () => void
  handleChangeBrandName: (item: Brand | null) => void
  isLoading: boolean
  brandList: BrandsWithCreativeCount[]
  selectedBrand: Brand | null
  isAllBrand: boolean
}

const BrandSelector = ({
  isBrandSelection,
  isOpenCreativeBrand = false,
  handleClose,
  handleChangeBrandName,
  isLoading,
  brandList,
  selectedBrand,
  isAllBrand
}: BrandSelectorPropsType) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [brand, setBrand] = useState<string>()

  const handleOnChange = useCallback(
    debounce((value: string) => {
      setBrand(value)
    }, 500),
    []
  )

  const filteredBrands =
    brandList?.filter(i => i.name.toLowerCase().includes(brand?.toLowerCase() || '')) || []

  return (
    <Drawer
      size="40%"
      header={'SELECT BRAND'}
      isOpen={isOpenCreativeBrand}
      onClose={() => handleClose()}>
      {isLoading ? (
        <div className="h-[calc(100vh-136px)] flex items-center justify-center">
          <Loader background="var(--cream)" />
        </div>
      ) : (
        <div className="flex  flex-col gap-[20px] mx-4 ">
          <div className="flex">
            <TextField
              fullWidth
              variant={'rounded'}
              placeholder="Search brand"
              className="bg-white"
              id="fonts"
              rightIcon={
                <img alt="search-icon" src={assets.svgs.SEARCH} className="w-5 h-5 mr-2 " />
              }
              handleOnChange={(_, value) => handleOnChange(value)}
            />
          </div>
          <div className="flex  flex-col gap-[20px] max-h-[calc(100vh-165px)]   overflow-scroll scrollbar-hide">
            {filteredBrands && filteredBrands?.length > 0 ? (
              <>
                {isAllBrand && (
                  <div
                    className="flex gap-2 w-fit cursor-pointer"
                    onClick={() => {
                      handleChangeBrandName(null)
                      handleClose()
                    }}>
                    <div className="relative flex items-center min-h-[50px]">
                      <img
                        src={assets.svgs.CREATIVE_AD}
                        alt="brand_logo"
                        className="w-[70px] h-[55px] p-[10px] rounded-[5px] bg-white object-contain"
                      />
                      {!selectedBrand && (
                        <div className="absolute left-1/2 transform -translate-x-1/2 bottom-[-6px]">
                          <img src={assets.svgs.GREEN_TICK} alt="tick" className="w-[60%] m-auto" />
                        </div>
                      )}
                    </div>
                    <Typography size={12} className="ml-1 font-[700] self-center">
                      All
                    </Typography>
                  </div>
                )}

                {filteredBrands.map(item => {
                  return (
                    <div
                      className="flex gap-2 w-fit cursor-pointer"
                      key={item.uuid}
                      onClick={() => {
                        if (isBrandSelection) {
                          handleChangeBrandName(item)
                        } else {
                          dispatch(resetCreative())
                          navigate(AppRoutes.ADD_CREATIVE(item.uuid))
                        }
                      }}>
                      <div className="relative flex items-center min-h-[50px]">
                        <img
                          src={item.logoAssets[0].presignedUrl}
                          alt="brand_logo"
                          className="w-[70px] h-[55px] object-contain"
                        />
                        {isBrandSelection && selectedBrand && selectedBrand.uuid === item.uuid && (
                          <div className="absolute  left-1/2 transform -translate-x-1/2 bottom-[-6px]">
                            <img
                              src={assets.svgs.GREEN_TICK}
                              alt="tick"
                              className="w-[60%] m-auto"
                            />
                          </div>
                        )}
                      </div>
                      <Typography size={12} className="font-[700] self-center">
                        {truncateText(item.name, 30)}
                      </Typography>
                    </div>
                  )
                })}
              </>
            ) : (
              <Typography size={12} className="font-[700] self-center">
                No brand found
              </Typography>
            )}
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default BrandSelector
