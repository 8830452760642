import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import assets from 'src/assets'
import ColorPicker from 'src/components/ColorPicker'
import Loader from 'src/components/Loader'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button.tsx'
import IconButton from 'src/components/ui/iconButton'
import { AppRoutes } from 'src/constants/routes'
import useHandleErrorMessage from 'src/hook/useIsErrorMessage'
import { useAppDispatch } from 'src/store'
import { useGetBrandsQuery } from 'src/store/brand/brand.api'
import { setSelectedBrand } from 'src/store/brand/brand.slice'
import { resetCreative } from 'src/store/creatives/creative.slice'
import { RtkError } from 'src/store/redux.types'
import { truncateText } from 'src/utils/truncate.ts'
import { trackEvent } from '../../amplitude'
import { events } from '../../amplitude/data.ts'

const BrandSection = () => {
  const navigate = useNavigate()
  const handleErrorMessage = useHandleErrorMessage()
  const dispatch = useAppDispatch()
  const {
    data: brandList,
    isLoading,
    isError,
    error
  } = useGetBrandsQuery(undefined, {
    refetchOnMountOrArgChange: false
  })

  useEffect(() => {
    if (isError && error) {
      handleErrorMessage((error as RtkError).data.error.message, true)
    }
  }, [error, isError])

  useEffect(() => {
    dispatch(resetCreative())
    trackEvent(events.USER_FLOW_MY_BRANDS_PAGE_VIEWED)
  }, [])
  return (
    <>
      {isLoading ? (
        <div className="h-[calc(100vh-136px)] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="grid gap-[30px] grid-cols-auto-fill md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 4xl:grid-cols-6">
          <div
            className="flex flex-col justify-center gap-[23px] items-center min-h-[450px] bg-white p-[20px] rounded-[5px] border-[2px] border-dashed w-[100%]  cursor-pointer "
            onClick={() => {
              trackEvent(events.BRAND_CREATION_INITIATED)
              navigate(AppRoutes.ADD_BRAND)
            }}>
            <IconButton path={assets.svgs.ADD} />
            <Typography size={16} className="font-[900]">
              ADD BRAND
            </Typography>
          </div>

          {brandList?.map(item => {
            return (
              <div
                className="h-[100%] bg-secondary  bg-secondary-primary w-[100%] p-[20px] rounded-[5px]"
                key={item.uuid}>
                <div className="flex justify-between items-start">
                  <Typography size={14} className=" font-[900] leading-[18px] text-text-primary ">
                    {truncateText(item.name, 30)}
                  </Typography>
                  <img
                    className="cursor-pointer"
                    src="assets/svgs/edit.svg"
                    alt="edit"
                    onClick={() => {
                      navigate(AppRoutes.EDIT_BRAND(item.uuid))
                      trackEvent(events.USER_FLOW_BRAND_EDIT_INITIATED, { brand_id: item.uuid })
                    }}
                  />
                </div>
                <div className="my-[10px]">
                  <img
                    src={item.logoAssets[0]?.presignedUrl}
                    alt="brand_logo"
                    className="object-contain max-w-[230px] w-auto h-[70px] "
                  />
                </div>

                <div className="flex flex-wrap gap-[10px] py-[20px] border-b-[1px] border-divider">
                  <ColorPicker
                    disabled={true}
                    name="Primary"
                    selectedColor={item.color.primary}
                    handleColor={() => null}
                    variant="sm"
                  />

                  <ColorPicker
                    disabled={true}
                    name="Secondary"
                    selectedColor={item.color.secondary}
                    handleColor={() => null}
                    variant="sm"
                  />

                  <ColorPicker
                    disabled={true}
                    name="Accent"
                    selectedColor={item.color.accent}
                    handleColor={() => null}
                    variant="sm"
                  />
                </div>

                <div className="flex justify-between gap-5 py-[15px] border-b-[1px] border-divider">
                  {/* //TODO */}
                  {/* <div className="flex flex-col gap-[10px] w-[50%] ">
                    <Typography size={12} className="font-[700] leading-[18px]">
                      TONE OF VOICE
                    </Typography>

                    <button
                      disabled={true}
                      className="px-[14px] py-[9px] bg-white text-text-primary text-[12px] font-[900] leading-[21px] w-[fit-content] ">
                      FORMAL
                    </button>
                  </div>

                  <div className="h-[auto] border-[1px] border-divider" /> */}

                  <div className="flex flex-col gap-[10px] w-[100%]">
                    <Typography size={12} className="font-[700] leading-[18px] text-text-primary">
                      FONT
                    </Typography>
                    <div className="flex gap-2 flex-wrap">
                      <button
                        disabled={true}
                        className="px-[14px] py-[9px] bg-white text-text-primary text-[12px] font-[900] leading-[21px] w-[fit-content]">
                        {item.primaryFonts}
                      </button>

                      <button
                        disabled={true}
                        className="px-[14px] py-[9px] bg-white text-text-primary text-[12px] font-[900] leading-[21px] w-[fit-content]">
                        {item.secondaryFonts}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex py-[15px] border-b-[1px] border-divider">
                  <div className="flex flex-col gap-[5px]">
                    <Typography size={12} className=" font-[700] leading-[18px] text-text-primary">
                      WEBSITE
                    </Typography>

                    <Typography size={12} className=" font-[400] leading-[18px] break-all">
                      {item.webUrl}
                    </Typography>
                  </div>
                </div>
                <div className={'flex flex-1 justify-between mt-3 flex-wrap gap-1'}>
                  <Typography size={24} className="font-[900]">
                    {item.creativesCount}
                    <span className="text-text-primary text-[12px] font-[700] leading-[18px] pl-[3px]">
                      CREATIVES
                    </span>
                  </Typography>
                  <Button
                    label="Add Creative"
                    onClick={() => {
                      dispatch(setSelectedBrand(item))
                      dispatch(resetCreative())
                      navigate(AppRoutes.ADD_CREATIVE(item.uuid))
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default BrandSection
