import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '../ui/alert-dialog'

export interface CustomAlertDialogProps {
  isOpen: boolean
  handleClose: () => void
  handleSuccess: () => void
  title?: string
  description?: string
}

const CustomAlertDialog = ({
  isOpen = false,
  handleClose,
  handleSuccess,
  title,
  description
}: CustomAlertDialogProps) => {
  return (
    <AlertDialog open={isOpen}>
      {/* <AlertDialogTrigger>Open</AlertDialogTrigger> */}
      <AlertDialogContent className="bg-white">
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className="bg-red" onClick={handleClose}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction onClick={handleSuccess}>Confirm</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default CustomAlertDialog
