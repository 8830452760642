import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import { cn } from 'src/lib/utils'
import CircularLoaderIcon from '../CircularLoaderIcon'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 px-5 py-2',
  {
    variants: {
      variant: {
        default:
          'bg-gradient-to-b from-main-secondary hover:bg-gradient-to-r hover:from-green-light hover:to-main-secondary to-green-light text-white shadow  '
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  label?: string
  fullWidth?: boolean
  isLoading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, fullWidth = false, className, variant, isLoading = false, ...props }, ref) => {
    const Comp = 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, className }))}
        style={{ width: fullWidth ? '100%' : 'unset' }}
        ref={ref}
        {...props}>
        {isLoading && (
          <div className="mr-2">
            <CircularLoaderIcon color="white" />
          </div>
        )}
        {label}
      </Comp>
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
