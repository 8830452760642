import { forwardRef, LegacyRef } from 'react'

interface Props {
  color: string
}

const LikeIcon = ({ color = 'var(--black)' }: Props, ref: LegacyRef<SVGSVGElement>) => (
  <svg
    width={22}
    height={19}
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}>
    <path
      d="M6.68182 1C3.275 1 1 3.79545 1 6.90909C1 9.82955 2.65909 12.3523 4.68636 14.3455C6.71364 16.3364 9.13409 17.8136 10.7955 18.6705C10.925 18.7364 11.0773 18.7364 11.2068 18.6705C12.8682 17.8136 15.2864 16.3364 17.3159 14.3455C19.3432 12.3545 21.0023 9.82955 21.0023 6.90909C21.0023 3.79545 18.7273 1 15.3205 1C13.3136 1 11.9795 2.02273 11.0023 3.40682C10.0227 2.02273 8.68864 1 6.68182 1ZM6.68182 1.90909C8.62955 1.90909 9.69545 2.87955 10.6091 4.40909C10.7386 4.625 11.0159 4.69545 11.2318 4.56818C11.2977 4.52955 11.3523 4.475 11.3909 4.40909C12.3045 2.87955 13.3705 1.90909 15.3182 1.90909C18.2341 1.90909 20.0909 4.22955 20.0909 6.90909C20.0909 9.58864 18.6045 11.7955 16.675 13.6909C14.8159 15.5182 12.5932 16.9068 11 17.7455C9.40682 16.9068 7.18409 15.5182 5.325 13.6909C3.39545 11.7955 1.90909 9.48182 1.90909 6.90909C1.90909 4.33636 3.76591 1.90909 6.68182 1.90909Z"
      fill={color}
      stroke={color}
      strokeWidth={1}
    />
  </svg>
)
export default forwardRef(LikeIcon)
