import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import assets from 'src/assets'
import Typography from 'src/components/Typography'
import IconButton from 'src/components/ui/iconButton'
import { AppRoutes } from 'src/constants/routes'
import useGetVariantDimensions from 'src/hook/useGetVariantDimensions'
import { trackEvent } from '../../amplitude'
import { events } from '../../amplitude/data.ts'
import TabSectionForRenderDimensions from './TabForRenderDimensions'
import PreviewDimension from './TabForRenderDimensions/PreviewDimension.tsx'
import VariantError from './VariantError'
import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'
import Tour from 'src/Tour/index.tsx'
import { defaultOnboardingState, dimensionSteps } from '../../data/onboarding.ts'
import { addCookie, fetchCookie, isGuest } from '../../utils/cookies.ts'
import { Button } from '../../components/ui/button.tsx'
import { resetCreative } from '../../store/creatives/creative.slice.ts'
import { useAppDispatch } from '../../store'
import GuestAuthDialog from '../../components/GuestAuthDialog'
import BookCallDialog from 'src/components/CallBookDialog/index.tsx'

const RenderDimensions = () => {
  const viewref = useRef<null | HTMLDivElement>(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { data: variantDimensions, isLoading, isError, errorMsg } = useGetVariantDimensions()
  const [onboardingStep, setOnboardingStep] = useState(0)
  const [run, setRun] = useState<boolean>(false)
  const [isGuestAuthModal, setIsGuestAuthModal] = useState<boolean>(false)
  const [isBookModal, setIsBookModal] = useState<boolean>(false)
  const { creativeId } = useParams()
  const [selectedRenderDimension, setSelectedRenderDimension] = useState<string[]>([])
  const onBoardingCookie = fetchCookie('onboarding') ?? ''
  const onboarding = onBoardingCookie ? JSON.parse(onBoardingCookie) : defaultOnboardingState

  useEffect(() => {
    trackEvent(events.AD_CREATION_DIMENSION_PAGE_VIEWED, {
      session_type: isGuest() ? 'guest' : 'registered'
    })
  }, [])
  useEffect(() => {
    if (
      !isError &&
      !isLoading &&
      variantDimensions?.imageUrls &&
      Object.keys(variantDimensions?.imageUrls).length > 0
    ) {
      if (isGuest() && onboarding.dimension) {
        setTimeout(() => {
          setRun(true)
        }, 1000)
      }
    }
    if (isError) {
      setRun(false)
    }
  }, [isError, isLoading, variantDimensions])

  const handleCallback = (data: CallBackProps) => {
    const { action, index, type, status } = data

    if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      setOnboardingStep(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setOnboardingStep(0)
      setRun(false)
      addCookie('onboarding', JSON.stringify({ ...onboarding, dimension: false }))
    }
  }
  useEffect(() => {
    viewref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [selectedRenderDimension])
  return (
    <div className={'relative'}>
      <div className="flex flex-col gap-5 lg:gap-0 lg:flex-row relative  h-[calc(100vh-216px)]  rounded-[5px] scrollbar-hide">
        {isError ? (
          <div className="relative flex h-full w-full justify-start pl-[20px] py-[20px] lg:px-[20rem] lg:py-[10rem] sm:px-[30px]">
            <VariantError errorMessage={errorMsg as string} />
          </div>
        ) : (
          <>
            <div
              id="eacel_onboarding_step_eleven"
              className="flex w-full lg:w-[40%] min-h-[410px] lg:min-h-[480px] overflow-y-auto  scrollbar-hide  bg-secondary-primary p-[30px]  rounded-[5px]">
              <TabSectionForRenderDimensions
                variantDimensions={variantDimensions}
                isLoading={isLoading}
                setSelectedRenderDimension={setSelectedRenderDimension}
                setRun={setRun}
                setIsGuestAuthModal={setIsGuestAuthModal}
              />
            </div>

            <div className="flex w-full lg:w-[60%] bg-secondary-primary ml-0 lg:ml-8 rounded-[5px]   min-h-[410px] scrollbar-hide lg:min-h-[480px] overflow-y-auto">
              <div
                ref={viewref}
                className="bg-secondary-primary w-full rounded-md px-[60px] py-[10px] flex flex-col gap-10">
                {selectedRenderDimension[1] && (
                  <div id="eacel_onboarding_step_twelve" className="flex justify-end mt-5">
                    <Button
                      label={'BACK to dashboard'}
                      className="self-end uppercase"
                      onClick={() => {
                        dispatch(resetCreative())
                        if (isGuest()) {
                          setRun(false)
                          setIsGuestAuthModal(true)
                        } else {
                          navigate(AppRoutes.DASHBOARD)
                        }
                      }}
                    />
                  </div>
                )}

                <PreviewDimension selectedRenderDimension={selectedRenderDimension as string[]} />
              </div>
            </div>
          </>
        )}
      </div>

      {!isError && (
        <div className="flex flex-col items-center absolute left-[-25px] top-[48%] gap-1">
          <IconButton
            type="submit"
            path={assets.svgs.PREVIOUS}
            onClick={() => navigate(`${AppRoutes.CREATIVE_VARIANTS(creativeId)}`)}
          />
          <Typography size={10} className="font-[800] text-center">
            PREVIOUS
          </Typography>
        </div>
      )}
      <Tour run={run} stepIndex={onboardingStep} step={dimensionSteps} callback={handleCallback} />
      <GuestAuthDialog
        isOpen={isGuestAuthModal}
        fromCreative
        handleClose={() => {
          setIsBookModal(true)
          setIsGuestAuthModal(false)
        }}
      />
      <BookCallDialog
        isOpen={isBookModal}
        handleClose={() => {
          isGuest() && onboarding.dimension && setRun(true)
          setIsBookModal(false)
        }}
      />
    </div>
  )
}

export default RenderDimensions
