import React from 'react'
import assets from 'src/assets'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import { ACCEPTED_IMAGE_UPLOAD_FORMATS } from 'src/constants'
import { ImageSelection, UploadImageSelection } from 'src/store/brand/brand.type'
import convertFilesToDataURL from 'src/utils/convertFileToDataURL'

interface UploadSectionProps {
  setImageSelection: (img: ImageSelection) => void
}

const UploadSection = ({ setImageSelection }: UploadSectionProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const newFilesArray: File[] = []
  const newFileList: File[] | FileList = []
  const fileSelectionHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files
    if (!fileList) {
      return
    }

    const handleFile = (file: File) => {
      return new Promise<void>(resolve => {
        const reader = new FileReader()

        reader.onload = async event => {
          const svgContent: string | ArrayBuffer | null | undefined = event.target?.result
          const parser = new DOMParser()
          const svgDocument = parser.parseFromString(svgContent as string, 'image/svg+xml')
          const svgString = new XMLSerializer().serializeToString(svgDocument)

          const img = new Image()
          img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            canvas.width = img.width
            canvas.height = img.height

            ctx?.drawImage(img, 0, 0)

            const pngDataUrl = canvas.toDataURL('image/png')

            const base64String = pngDataUrl.split(',')[1]
            const mimeType = 'image/png'
            const filename = `${file.name.split('.svg')[0]}.png`
            const byteCharacters = atob(base64String)
            const byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)
            const blob = new Blob([byteArray], { type: mimeType })
            const newFile = new File([blob], filename, { type: mimeType })

            // Push the newFile into the array
            newFilesArray.push(newFile)

            resolve() // Resolve the promise
          }

          img.src = 'data:image/svg+xml;base64,' + btoa(svgString)
        }

        reader.readAsText(file)
      })
    }

    if (fileList[0].type === 'image/svg+xml') {
      await handleFile(fileList[0])
      newFileList.push(newFilesArray[0])
    } else {
      newFileList.push(fileList[0])
    }

    const dataUrls = await convertFilesToDataURL(newFileList as File[])
    setImageSelection(new UploadImageSelection(newFileList[0], dataUrls[0]))
  }

  return (
    <div className="mx-[10px] lg:mx-[50px] my-[44px]">
      <div className="relative min-h-[340px] bg-white p-3 w-[90%] ml-auto border-[1px] border-dashed border-[#cecece] rounded-sm">
        <input
          type="file"
          accept={ACCEPTED_IMAGE_UPLOAD_FORMATS}
          multiple={false}
          onChange={fileSelectionHandler}
          className="absolute opacity-0 z-10 inset-0"
          ref={fileInputRef}
        />
        <div className="flex flex-col absolute left-[50%] top-[35%] items-center w-[150px] gap-[27px]">
          <Typography size={12} className=" font-[700] leading-[16px] text-center">
            UPLOAD OR DRAG/DROP A BACKGROUND IMAGE
          </Typography>

          <Button label="SELECT IMAGE" />
        </div>

        <img src={assets.images.WOMEN_IMAGE} alt="fancy_image" className="absolute left-[-84px]" />
      </div>
    </div>
  )
}

export default UploadSection
