import { OnboardingState } from './onboarding/onboarding.type'
import { Action, ThunkAction } from '@reduxjs/toolkit'
import { ErrorProps } from 'src/types'
import store from '.'
import { AuthSliceState } from './auth/auth.type'
import { BrandSliceState } from './brand/brand.type'
import { CreativeSliceState } from './creatives/creative.type'
import { GlobalState } from './global/global.types'
import { SnackbarSliceState } from './snackbar/snackbar.type'

export interface RtkError {
  data: {
    error: ErrorProps
    detail?: string
    message: string
    errorCode: string
  }
}

export interface RootReduxState {
  globalSlice: GlobalState
  snackbarSlice: SnackbarSliceState
  brandSlice: BrandSliceState
  creativeSlice: CreativeSliceState
  OnboardingSlice: OnboardingState
  authSlice: AuthSliceState
}

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<T = Promise<void> | void> = ThunkAction<
  T,
  RootReduxState,
  unknown,
  Action<string>
>

export const RESET_STORE = 'RESET_STORE'
