import Typography from 'src/components/Typography'
import { loginMode } from 'src/store/auth/auth.type'
import { useStateSelector } from 'src/store/root.reducer'

interface AccountInfoProps {
  handleChangePassword: () => void
}

const AccountInfo = ({ handleChangePassword }: AccountInfoProps) => {
  const user = useStateSelector(state => state.authSlice.user)
  return (
    <div className="flex flex-col gap-[30px]">
      <div className="grid gap-[30px] grid-cols-auto-fill md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 ">
        <div className="flex mt-[30px] flex-col  gap-[20px]  min-h-[450px]  p-[20px] rounded-[5px] bg-secondary-primary w-[100%]  cursor-pointer ">
          <Typography size={14} className="font-[900] leading-[18px]">
            ACCOUNT INFO
          </Typography>

          <div className="flex flex-col">
            <Typography
              size={10}
              className="flex flex-col font-[300]  sm:w-[257px] w-full text-ellipsis whitespace-nowrap overflow-hidden">
              Full Name
            </Typography>
            <Typography
              size={12}
              className="flex flex-col font-[700] sm:w-[257px] w-full text-ellipsis whitespace-nowrap overflow-hidden">
              {user ? user.fullName : '-'}
            </Typography>
          </div>

          {/*<div className="flex flex-col">*/}
          {/*  <Typography*/}
          {/*    size={10}*/}
          {/*    className="flex flex-col font-[300]  sm:w-[257px] w-full text-ellipsis whitespace-nowrap overflow-hidden">*/}
          {/*    Company*/}
          {/*  </Typography>*/}
          {/*  <Typography*/}
          {/*    size={12}*/}
          {/*    className="flex flex-col font-[700] sm:w-[257px] w-full text-ellipsis whitespace-nowrap overflow-hidden">*/}
          {/*    -*/}
          {/*  </Typography>*/}
          {/*</div>*/}

          <div className="flex flex-col">
            <Typography
              size={10}
              className="flex flex-col font-[300]  sm:w-[257px] w-full text-ellipsis whitespace-nowrap overflow-hidden">
              Email
            </Typography>
            <Typography
              size={12}
              className="flex flex-col font-[700] sm:w-[257px] w-full text-ellipsis whitespace-nowrap overflow-hidden">
              {user ? user.email : '-'}
            </Typography>
          </div>

          {/*<div className="flex flex-col">*/}
          {/*  <Typography*/}
          {/*    size={10}*/}
          {/*    className="flex flex-col font-[300]  sm:w-[257px] w-full text-ellipsis whitespace-nowrap overflow-hidden">*/}
          {/*    Time Zone*/}
          {/*  </Typography>*/}
          {/*  <Typography*/}
          {/*    size={12}*/}
          {/*    className="flex flex-col font-[700] sm:w-[257px] w-full text-ellipsis whitespace-nowrap overflow-hidden">*/}
          {/*    -*/}
          {/*  </Typography>*/}
          {/*</div>*/}

          <div className="flex justify-between flex-wrap ">
            <div className="flex flex-col">
              <Typography
                size={10}
                className="flex flex-col font-[300]  sm:max-w-[257px] w-full text-ellipsis whitespace-nowrap overflow-hidden">
                Password
              </Typography>
              <Typography
                size={12}
                className="flex flex-col font-[700] sm:max-w-[257px] w-full text-ellipsis whitespace-nowrap overflow-hidden">
                -
              </Typography>
            </div>

            {user?.loginMode === loginMode.PASSWORD && (
              <Typography
                size={12}
                onClick={() => handleChangePassword()}
                className="flex flex-col items-end justify-end text-p font-[700] flex-wrap text-green-light cursor-pointer underline">
                change
              </Typography>
            )}
          </div>
        </div>
        <div className="flex mt-[30px] flex-col  gap-[23px] items-center min-h-[450px]  p-[20px] rounded-[5px] bg-secondary-primary w-[100%]  cursor-pointer "></div>
        <div className="flex mt-[30px] flex-col  gap-[23px] items-center min-h-[450px]  p-[20px] rounded-[5px] bg-secondary-primary w-[100%]  cursor-pointer "></div>
      </div>
      <div className="flex min-h-[230px] bg-secondary-primary rounded-[5px]"></div>
    </div>
  )
}

export default AccountInfo
