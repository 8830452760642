import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'
import assets from 'src/assets'
import Loader from 'src/components/Loader'
import { TextField } from 'src/components/ui/textfield'
import useHandleErrorMessage from 'src/hook/useIsErrorMessage'
import { useLazyGetStockImagesQuery } from 'src/store/brand/brand.api'
import {
  ImageSearchQueryParams,
  ImageSelection,
  StockImageSelection,
  StockImagesPayload
} from 'src/store/brand/brand.type'
import { RtkError } from 'src/store/redux.types'
import { trackEvent } from '../../../amplitude'
import { events } from '../../../amplitude/data.ts'
import { useStateSelector } from '../../../store/root.reducer.ts'
import { isGuest } from 'src/utils/cookies.ts'

interface EazelStockSectionProps {
  setImageSelection: (img: ImageSelection) => void
}
const EazelStockSection = ({ setImageSelection }: EazelStockSectionProps) => {
  const handleErrorMessage = useHandleErrorMessage()
  const [stockImageList, setStockImageList] = useState<StockImagesPayload[]>([])
  const [searchString, setSearchString] = useState<string>('')
  const [pageNum, setPageNum] = useState<number>(1)
  const [fetchStockImages, { isLoading: stockImageLoading }] = useLazyGetStockImagesQuery()
  const { brandId } = useParams()
  const creativeDetails = useStateSelector(state => state.creativeSlice)
  useEffect(() => {
    const queryParams: ImageSearchQueryParams = {
      pageNo: pageNum,
      value: searchString
    }

    fetchStockImages(queryParams)
      .unwrap()
      .then(res => {
        trackEvent(events.AD_CREATION_EACEL_STOCK_VIEW, {
          brand_id: brandId,
          project_name: creativeDetails.projectName,
          session_type: isGuest() ? 'guest' : 'registered'
        })
        if (pageNum === 1) {
          // If it's the first page, replace the existing data
          setStockImageList(res)
        } else {
          // Otherwise, append the new data to the existing list
          setStockImageList(prevItems => [...prevItems, ...res])
        }
      })
      .catch((error: RtkError) => {
        if (error) {
          // dispatch(setSnackbar({ severity: SnackbarType.ERROR, message: error.data.error.message }))
          handleErrorMessage(error.data.error.message, true)
        }
      })
  }, [pageNum])

  // const handleOnChange = useCallback(
  //   debounce((value: string) => {
  //     setSearchString(value)
  //   }, 500),
  //   []
  // )

  const handleOnChange = (value: string) => {
    setSearchString(value)
  }

  const handleImageClick = (i: StockImagesPayload) => {
    // Clone the image object to avoid mutating the original directly
    const selectedImage = { ...i }

    // Clone the urls object and update the 'regular' property
    selectedImage.urls = {
      ...selectedImage.urls,
      regular: selectedImage.urls.regular + `&${Date.now()}`
    }

    setImageSelection(new StockImageSelection(selectedImage))
    trackEvent(events.AD_CREATION_EACEL_STOCK_SELECTED, {
      brand_id: brandId,
      project_name: creativeDetails.projectName,
      session_type: isGuest() ? 'guest' : 'registered'
    })
  }

  const handleClickSearch = () => {
    if (searchString.trim() !== '') {
      trackEvent(events.AD_CREATION_EACEL_STOCK_SEARCH, {
        brand_id: brandId,
        project_name: creativeDetails.projectName,
        search_keyword: searchString,
        session_type: isGuest() ? 'guest' : 'registered'
      })
      const params: ImageSearchQueryParams = {
        pageNo: 1,
        value: searchString
      }

      fetchStockImages(params)
        .unwrap()
        .then(res => {
          setStockImageList(res)
        })
        .catch((error: RtkError) => {
          if (error) {
            // dispatch(
            //   setSnackbar({ severity: SnackbarType.ERROR, message: error.data.error.message })
            // )
            handleErrorMessage(error.data.error.message, true)
          }
        })
    }
  }

  const fetchMoreData = () => {
    if (!stockImageLoading) {
      setPageNum(prevPageNum => prevPageNum + 1)
    }
  }

  return (
    <div className="flex  flex-col" style={{ marginTop: '20px' }}>
      <TextField
        // fullWidth
        width="66%"
        handleOnChange={(_, value) => handleOnChange(value)}
        variant={'rounded'}
        placeholder="Search Here"
        className="bg-white"
        id="fonts"
        rightIcon={
          <img
            alt="search-icon"
            src={assets.svgs.SEARCH}
            className="w-5 h-5 mr-2 cursor-pointer"
            onClick={handleClickSearch}
          />
        }
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleClickSearch()
          }
        }}
      />

      <div
        className="relative py-3 mt-[20px] overflow-auto "
        id="scrollableDiv"
        style={{ height: 'calc(100vh - 220px)' }}>
        {stockImageLoading ? (
          <div className="mt-10">
            <Loader background="none" />
          </div>
        ) : (
          <InfiniteScroll
            className="columns-1 gap-5 sm:columns-2 sm:gap-4 md:columns-3 lg:columns-3 xl:columns-3 [&>img:not(:first-child)]:mt-8 h-[100px] mb-8"
            dataLength={stockImageList.length}
            next={fetchMoreData}
            scrollThreshold={0.7}
            scrollableTarget="scrollableDiv"
            hasMore={true}
            loader={
              <div className="absolute  left-[45%] translate-y-[150px] ">
                <h4 className="text-center">Loading...</h4>
              </div>
            }>
            {stockImageList.map(image => {
              return (
                <img
                  key={image.attributeUrl}
                  src={image.urls.small}
                  // placeholder={assets.images.DUMMY_1}
                  alt={image.sponsorName}
                  className="rounded-md cursor-pointer"
                  onClick={() => {
                    handleImageClick(image)
                  }}
                />
              )
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

export default EazelStockSection
