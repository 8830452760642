import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import * as React from 'react'
import { cn } from 'src/lib/utils'
interface Props {
  children: React.ReactNode
  text: string
  side?: 'top' | 'bottom' | 'left' | 'right'
  isCreative?: boolean
}
const TooltipProvider = TooltipPrimitive.Provider
const ToolTip = TooltipPrimitive.Root
const TooltipTrigger = TooltipPrimitive.Trigger
const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-full bg-slate-900 px-2 py-0 pt-0 text-xs text-slate-50 font-[700]  border-solid border-[0.5px] border-white animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:bg-slate-50 dark:text-slate-900',
      className
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName
const Tooltip = ({ children, text, side = 'right', isCreative = false }: Props) => {
  return (
    <TooltipProvider>
      <ToolTip delayDuration={0}>
        <TooltipTrigger tabIndex={-1}>{children}</TooltipTrigger>
        <TooltipContent
          side={side}
          className={
            isCreative
              ? 'bg-gradient-to-b from-yellow-light to-yellow-dark'
              : 'bg-gradient-to-b from-tooltip-primary to-tooltip-secondary'
          }>
          <p className={isCreative ? ' text-black' : 'text-white'}>{text}</p>
        </TooltipContent>
      </ToolTip>
    </TooltipProvider>
  )
}
export { Tooltip }
