import HeaderTitle from 'src/components/HeaderTitle'
import BrandSection from './BrandSection'

const BrandPage = () => {
  return (
    <div className="flex flex-col py-[20px] mx-4 gap-[30px] max-w-[1640px]">
      <HeaderTitle primaryName="BRAND" secondaryName="LIST" />
      <BrandSection />
    </div>
  )
}

export default BrandPage
