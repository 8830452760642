import JSZip from 'jszip'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'src/components/ui/button'
import { generatedCreativesTypes } from 'src/store/creatives/creative.type'
import { trackEvent } from '../../../amplitude'
import { events } from '../../../amplitude/data.ts'
import { useStateSelector } from '../../../store/root.reducer.ts'
import DimensionsTab from './DimensionsTab'
import { isGuest } from 'src/utils/cookies.ts'

interface TabSectionForRenderDimensionsProps {
  variantDimensions: generatedCreativesTypes | undefined
  isLoading: boolean
  setSelectedRenderDimension: (item: string[]) => void
  setRun: Dispatch<SetStateAction<boolean>>
  setIsGuestAuthModal: Dispatch<SetStateAction<boolean>>
}

const TabSectionForRenderDimensions = ({
  variantDimensions, // isLoading
  isLoading,
  setSelectedRenderDimension,
  setRun,
  setIsGuestAuthModal
}: TabSectionForRenderDimensionsProps) => {
  const [activeTab, setActiveTab] = useState('DISPLAY SIZES')
  const { creativeId, variantId } = useParams()
  const [selectedDimensions, setSelectedDimensions] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const projectName = useStateSelector(state => state.creativeSlice.projectName)

  useEffect(() => {
    if (variantDimensions) {
      const dimensionToShow = Object.entries(variantDimensions?.imageUrls).find(
        i => i[0] === selectedDimensions[selectedDimensions.length - 1]
      )
      if (dimensionToShow && dimensionToShow.length > 0) {
        setSelectedRenderDimension(dimensionToShow)
      } else {
        setSelectedRenderDimension(Object.entries(variantDimensions?.imageUrls)[0])
      }
    }
  }, [selectedDimensions, variantDimensions])
  useEffect(() => {
    if (variantDimensions) {
      setSelectedDimensions(Object.entries(variantDimensions?.imageUrls)[0])
    }
  }, [variantDimensions])

  const handleVariantDimensions = (item: string) => {
    // If the item is not present, add it
    setSelectedDimensions([item])
  }

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
  }

  const handleDownload = async () => {
    if (variantDimensions) {
      setLoading(true)
      const zip = new JSZip()
      // const selectedImages =
      //   Object.entries(variantDimensions?.imageUrls).filter(i =>
      //     selectedDimensions.length > 0 ? selectedDimensions.includes(i[0]) : i[1]
      //   ) ?? []

      const imagePromises = Object.entries(variantDimensions?.imageUrls).map(
        async (imageEntry, index) => {
          return new Promise<{ blob: Blob; index: number }>((resolve, reject) => {
            const img = new Image()
            img.crossOrigin = 'Anonymous' // Set crossOrigin to avoid CORS issues

            img.onload = () => {
              const canvas = document.createElement('canvas')
              const ctx = canvas.getContext('2d')

              canvas.width = img.width
              canvas.height = img.height
              ctx?.drawImage(img, 0, 0)

              canvas.toBlob(blob => {
                if (blob) {
                  resolve({ blob, index })
                } else {
                  reject(new Error('Failed to convert canvas content to blob'))
                }
              }, 'image/jpeg') // Specify MIME type
            }

            img.onerror = () => {
              reject(new Error(`Failed to load image ${imageEntry[1]}`))
            }

            img.src = imageEntry[1]
          })
        }
      )

      try {
        const imageResults: { blob: Blob; index: number }[] = await Promise.all(imagePromises)
        imageResults.forEach(({ blob, index }) => {
          zip.file(
            `${projectName}-${Object.entries(variantDimensions?.imageUrls)[index][0]}.jpg`,
            blob
          )
        })

        zip.generateAsync({ type: 'blob' }).then(function (content) {
          const a = document.createElement('a')
          setLoading(false)
          a.href = URL.createObjectURL(content)
          a.download = `${projectName}.zip`
          a.click()
        })
        trackEvent(events.AD_CREATION_VARIANTS_DOWNLOADED, {
          creative_id: creativeId,
          session_type: isGuest() ? 'guest' : 'registered',
          variant_id: variantId,
          project_name: projectName,
          number_of_variants_downloaded: Object.entries(variantDimensions?.imageUrls).length,
          dimensions_of_variants: Object.entries(variantDimensions?.imageUrls)
        })
      } catch (error) {
        // console.error('Error downloading images:', error)
        setLoading(false)
      }
    }
  }

  const handleDownloadSingleImage = (imageUrl: string[], resolution: string) => {
    // Create an Image element
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    // Set the image source
    img.src = imageUrl[1]

    // Event listener for when the image has loaded
    img.onload = () => {
      // Create a canvas element
      const canvas = document.createElement('canvas')

      // Set canvas dimensions to match the image
      canvas.width = img.width
      canvas.height = img.height

      // Get the canvas context
      const ctx = canvas.getContext('2d')

      // Draw the image on the canvas
      ctx?.drawImage(img, 0, 0)

      // Convert the canvas content to a blob
      canvas.toBlob(blob => {
        if (blob) {
          // Create a URL for the blob
          const url = URL.createObjectURL(blob)

          // Create a link element
          const link = document.createElement('a')

          // Set link attributes
          link.href = url
          link.download = `${projectName}-${imageUrl[0]}.jpg`

          // Append link to body
          document.body.appendChild(link)

          // Simulate click on the link to trigger download
          link.click()
          trackEvent(events.AD_CREATION_VARIANTS_DOWNLOADED, {
            creative_id: creativeId,
            variant_id: variantId,
            project_name: projectName,
            session_type: isGuest() ? 'guest' : 'registered',
            number_of_variants_downloaded: 1,
            dimensions_of_variants: imageUrl[0],
            resolution: resolution
          })
          // Clean up: remove the link and revoke the URL
          document.body.removeChild(link)
          URL.revokeObjectURL(url)
        }
      }, 'image/jpeg') // specify the MIME type for the blob
    }

    // Event listener for when there's an error loading the image
    // img.onerror = () => {
    //   // console.error('Error loading image')
    //   return
    // }
  }

  return (
    <div className="w-[100%] relative bg-secondary-primary">
      <div className="flex tab-container justify-between gap-[50px]">
        <div className="flex items-center">
          <button
            className={
              activeTab === 'DISPLAY SIZES'
                ? 'font-[900] text-[12px] text-text-primary leading-[18px]'
                : ''
            }
            onClick={() => handleTabClick('DISPLAY SIZES')}>
            DISPLAY SIZES
          </button>
        </div>

        {selectedDimensions.length > 0 && (
          <div className="flex  flex-row justify-between ">
            <div className="flex flex-wrap gap-[10px] sm:gap-[30px]  h-fit  justify-center items-center">
              <Button
                isLoading={loading}
                disabled={loading}
                label={'DOWNLOAD ALL'}
                onClick={() => {
                  if (isGuest()) {
                    setRun(false)
                    setIsGuestAuthModal(true)
                  } else {
                    handleDownload()
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>

      <div className="tab-content w-[100%]">
        {activeTab === 'DISPLAY SIZES' && (
          <DimensionsTab
            isLoading={isLoading}
            setRun={setRun}
            setIsGuestAuthModal={setIsGuestAuthModal}
            handleVariantDimensions={handleVariantDimensions}
            selectedDimensions={selectedDimensions}
            variantDimensions={variantDimensions}
            handleDownloadSingleImage={handleDownloadSingleImage}
          />
        )}
      </div>
    </div>
  )
}

export default TabSectionForRenderDimensions
