export interface AuthSliceState {
  user: Omit<User, 'token'> | null
}

export interface User {
  uuid: string
  fullName: string
  email: string
  token: string
  tokenType?: string
  is_active: boolean
  role: 'regular' | 'superuser'
  assumedUser?: boolean
  loginMode: 'password' | 'google'
  status?: number
}

export interface LoginRequest {
  email: string
  password: string
}

export interface ForgotPassword {
  email: string
}

export interface Password {
  oldPassword: string
  newPassword: string
}

export interface ResetPassword {
  newPassword: string
  accessToken: string
}

export interface SignupRequest extends LoginRequest {}

export enum loginMode {
  PASSWORD = 'password',
  GOOGLE = 'google'
}
