import { Skeleton } from './skeleton'

const PerformanceSkeleton = () => {
  return (
    <>
      {Array.from({ length: 2 }).map((_, index) => {
        return (
          <div
            key={index}
            className="flex flex-col gap-1 justify-between p-5  min-h-[140px] w-[250px] border-2 border-secondary-primary rounded-[5px]">
            <div className="flex justify-end">
              <Skeleton className="h-10 w-10 flex rounded-full bg-secondary-primary" />
            </div>
            <div className="flex flex-col gap-1">
              <Skeleton className="h-3 w-[30%] bg-secondary-primary" />
              <Skeleton className="h-2 w-[40%] bg-secondary-primary" />
            </div>
          </div>
        )
      })}
    </>
  )
}

export default PerformanceSkeleton
