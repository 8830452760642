import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { useDispatch } from 'react-redux'
import { AppDispatch, RESET_STORE } from 'src/store/redux.types'
import rootReducer from 'src/store/root.reducer'
import { brandApi } from './brand/brand.api'
import { creativeApi } from './creatives/creative.api'
import { analyticsApi } from './analytics/analytics.api'
import { onboardingApi } from './onboarding/onboarding.api'
import { authApi } from './auth/auth.api'
import rtkQueryErrorHandler from './middleware/rtkQueryErrorHandler'
import LogRocket from 'logrocket'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
})

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      rtkQueryErrorHandler,
      brandApi.middleware,
      creativeApi.middleware,
      analyticsApi.middleware,
      authApi.middleware,
      onboardingApi.middleware,
      LogRocket.reduxMiddleware()
    ),
  enhancers: [sentryReduxEnhancer]
})

export const resetStore = () => ({ type: RESET_STORE })
export const useAppDispatch = () => useDispatch<AppDispatch>()
export default store
