import { ReactNode } from 'react'
import { cn } from 'src/lib/utils'

interface FontStyle {
  h1: string
  h2: string
  body1: string
  body2: string
  light: string
  error: string
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'h1' | 'h2' | 'light' | 'body1' | 'body2' | 'error'
  className?: string
  children: ReactNode
  size?: number
  onClick?: () => void
}
const Typography = ({
  variant = 'body1',
  className,
  children,
  size = 16,
  onClick,
  ...props
}: Props) => {
  const fontStyle: FontStyle = {
    h1: 'font-extrabold  ',
    h2: 'font-bold',
    body1: 'font-semibold',
    body2: 'font-normal',
    light: 'font-light',
    error: 'font-light text-error'
  }
  return (
    <div
      className={cn(fontStyle[variant], className)}
      style={{ fontSize: size }}
      onClick={onClick}
      {...props}>
      {children}
    </div>
  )
}

export default Typography
