export interface BrandSliceState {
  selectedBrand: Partial<Brand | BrandsWithCreativeCount>
  // creativeImage?: UserAssetWithProvider | null
}

export interface UserAsset {
  assetType?: string
  uuid: string
  presignedUrl: string
  brandId: string | null
  name?: string
}
export interface Brand {
  uuid?: string
  logoAssets: Array<UserAsset>
  name: string
  webUrl: string
  color: {
    primary: string
    secondary: string
    accent: string
  }
  description: string
  primaryFonts: string[]
  secondaryFonts: string[]
}

export interface BrandsWithCreativeCount extends Brand {
  creativesCount: number
}

export interface UserAssetWithProvider extends UserAsset {
  provider: string
}

export interface editAsset {
  brandId: string
}

export interface EditBrandArgs {
  brandId: string
  values: BrandsWithCreativeCount
}

export interface EditAssetArgs {
  assetId: string
  values: {
    brandId: string
  }
}

export interface ImageSearchQueryParams {
  value?: string
  pageNo: number
}

export interface CreateAssetPayload {
  fileName: string
  assetType: string
  contentType: string
  provider: string
  providerAttributionUrl: string
}

export interface CreateBrandPayload {
  uuid?: string
  logoAssets: Array<{ uuid: string }>
  name: string
  webUrl: string
  color: {
    primary: string
    secondary: string
    accent: string
  }
  description: string
  primaryFonts: string[]
  secondaryFonts: string[]
}

export interface urlsProps {
  full: string
  raw: string
  regular: string
  small: string
  thumb: string
}

export interface StockImagesPayload {
  url: string
  provider: string
  sponsorName: string
  attributeUrl: string
  providerLink: string
  sponsorLink: string
  urls: urlsProps
  file: File
}

export interface SearchStockImages {
  brandId: string
  values: BrandsWithCreativeCount
}

export type ImagePickerType = 'stock' | 'upload' | 'asset'

export interface AbstractImageSelection {
  pickerType: ImagePickerType
  url: string
  name: string
  provider: string
  attributionUrl?: string
  providerLink?: string
  sponsorLink?: string
  urls?: urlsProps
  fileType?: string
  sponsorName?: string
}

export class AssetImageSelection implements AbstractImageSelection {
  asset: UserAssetWithProvider
  attributionUrl?: string | undefined
  providerLink?: string | undefined
  sponsorLink?: string | undefined
  urls: urlsProps | undefined
  fileType?: string | undefined
  sponsorName?: string | undefined
  readonly pickerType: ImagePickerType = 'asset'
  constructor(asset: UserAssetWithProvider) {
    this.asset = asset
  }
  get url() {
    return this.asset.presignedUrl
  }

  get name() {
    return this.asset.name!
  }

  get provider() {
    return this.asset.provider
  }
}

export class StockImageSelection implements AbstractImageSelection {
  readonly stockImage: StockImagesPayload
  fileType?: string | undefined
  // name: string | undefined
  pickerType: ImagePickerType = 'stock'
  constructor(stockImage: StockImagesPayload) {
    this.stockImage = stockImage
  }
  get url() {
    return this.stockImage.url
  }

  get name() {
    return this.stockImage.sponsorName
  }

  get sponsorName() {
    return this.stockImage.sponsorName
  }

  get provider() {
    return 'unsplash'
  }

  get attributionUrl() {
    return this.stockImage.attributeUrl
  }

  get providerLink() {
    return this.stockImage.providerLink
  }

  get sponsorLink() {
    return this.stockImage.sponsorLink
  }

  get urls() {
    return this.stockImage.urls
  }
}

export class UploadImageSelection implements AbstractImageSelection {
  readonly pickerType: ImagePickerType = 'upload'
  file: File
  dataUrl: string
  attributionUrl?: string | undefined
  providerLink?: string | undefined
  sponsorLink?: string | undefined

  urls: urlsProps | undefined
  constructor(file: File, dataUrl: string) {
    this.file = file
    this.dataUrl = dataUrl
  }
  get url() {
    return this.dataUrl
  }

  get name() {
    return this.file.name
  }

  get sponsorName() {
    return this.file.name.endsWith('.jpg')
      ? this.file.name.split('.jpg')[0]
      : this.file.name.split('.png')[0]
  }

  get provider() {
    return 'user'
  }
  get fileType() {
    return this.file.type
  }
}

export type ImageSelection = AssetImageSelection | StockImageSelection | UploadImageSelection
