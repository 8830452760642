import { useEffect, useState } from 'react'
import { UserAsset } from 'src/store/brand/brand.type'

interface ImageDropdownProps {
  options: UserAsset[]
  setSelectedImage: (path: string) => void
  selectedImage: string
  handleClose: () => void
}

const ImageDropdown = ({
  options,
  setSelectedImage,
  selectedImage,
  handleClose
}: ImageDropdownProps) => {
  const [selectedId, setSelectedId] = useState<string>()

  useEffect(() => {
    if (selectedImage !== '') {
      const filterOption = options.find(i => i.presignedUrl === selectedImage)
      setSelectedId(filterOption?.uuid)
    }
  }, [])

  const handleCheckboxChange = (optionId: string, label: string) => {
    setSelectedId(optionId)
    setSelectedImage(label)
    handleClose()
  }

  return (
    <div className="flex flex-col ">
      {options.map(option => (
        <label
          key={option.uuid}
          className="inline-flex cursor-pointer items-center mt-2 gap-0 sm:gap-[26px] relative min-h-[67px]">
          <input
            id={option.presignedUrl + option.uuid}
            type="checkbox"
            className="mr-3 cursor-pointer appearance-none border bg-main-secondary rounded-full checked:border-transparent w-[25px] h-[25px]"
            value={option.uuid}
            checked={selectedId === option.uuid}
            onChange={() => handleCheckboxChange(option.uuid, option.presignedUrl)}
          />
          {selectedId === option.uuid && (
            <label
              htmlFor={option.presignedUrl + option.uuid}
              className="absolute left-[5px] cursor-pointer">
              <img src="/assets/svgs/tick.svg" alt="tick" />
            </label>
          )}
          <img
            src={option.presignedUrl}
            alt="logo"
            className="max-w-[50%] w-[100px] h-[70px] object-contain rounded-lg"
          />
        </label>
      ))}
    </div>
  )
}

export default ImageDropdown
