import { format } from 'date-fns/format'
import { truncate } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import assets from 'src/assets'
import CustomAlertDialog from 'src/components/AlertDialog'
import Typography from 'src/components/Typography'
import CustomPagination from 'src/components/ui/CustomPagination'
import CreativesSkeleton from 'src/components/ui/creativesSkeleton'
import { AppRoutes } from 'src/constants/routes'
import useHandleErrorMessage from 'src/hook/useIsErrorMessage.ts'
import { useAppDispatch } from 'src/store'
import { setSelectedBrand } from 'src/store/brand/brand.slice'
import { Brand } from 'src/store/brand/brand.type'
import {
  resetCreative,
  setCurrentCreative,
  setSelectedProjectName,
  setSelectedVariantId
} from 'src/store/creatives/creative.slice'
import { Creative } from 'src/store/creatives/creative.type.ts'
import {
  useArchiveCreativeMutation,
  useLazyGetCreativesByBrandIdQuery,
  useLazyGetCreativesQuery
} from '../../../store/creatives/creative.api.ts'
import { RtkError } from '../../../store/redux.types.ts'
import { useStateSelector } from '../../../store/root.reducer.ts'
import { getCreativeStatus } from '../../../utils/getCreativeStatus.ts'

interface CreativesPropsTypes {
  handleOpen: () => void
  brand?: Brand
}
const Creatives = ({ handleOpen, brand }: CreativesPropsTypes) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleErrorMessage = useHandleErrorMessage()
  const [creativeList, setCreativelist] = useState<Creative[] | null>(null)
  const [getCreativesByBrandId, { data: creatives }] = useLazyGetCreativesByBrandIdQuery()
  const [getCreatives, { data: allCreatives }] = useLazyGetCreativesQuery()
  const [currentPage, setCurrentPage] = useState(1)
  const [openConfirm, setOpenConfirm] = useState<{
    creativeId: string
    brandId: string
    isOpen: boolean
  }>({
    creativeId: '',
    brandId: '',
    isOpen: false
  })
  const [archiveCreative] = useArchiveCreativeMutation()
  const variants = useStateSelector(state => state.creativeSlice.variants)
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    if (brand) {
      brand?.uuid && getCreativesByBrandId(brand.uuid)
    } else {
      getCreatives({})
    }
  }, [brand])

  useEffect(() => {
    if (brand) {
      setCreativelist(creatives as Creative[])
      dispatch(setSelectedBrand(brand as Brand))
    } else {
      setCreativelist(allCreatives as Creative[])
    }
  }, [allCreatives, creatives])

  useEffect(() => {
    variants && dispatch(resetCreative())
  }, [variants])
  const handleArchiveCreative = () => {
    if (openConfirm.brandId && openConfirm.creativeId) {
      archiveCreative({ brandId: openConfirm.brandId, creativeId: openConfirm.creativeId })
        .then(res => {
          if ('data' in res) {
            openConfirm.brandId && getCreativesByBrandId(openConfirm.brandId)
            if (!brand) {
              getCreatives({})
            }
          }
        })
        .catch((error: RtkError) => {
          if (error) {
            handleErrorMessage(error.data.error.message, true)
          }
        })
    }
  }

  return (
    <>
      <CustomAlertDialog
        isOpen={openConfirm.isOpen}
        handleSuccess={() => {
          handleArchiveCreative()
          setOpenConfirm({
            creativeId: '',
            brandId: '',
            isOpen: false
          })
        }}
        handleClose={() =>
          setOpenConfirm({
            creativeId: '',
            brandId: '',
            isOpen: false
          })
        }
        description="This action will delete the file. Please confirm"
      />

      <div className="flex flex-col gap-[15px]">
        <Typography size={14} className=" font-[900] leading-[18px] text-text-primary">
          CREATIVES
        </Typography>

        <div>
          <div>
            <Typography size={10} className=" font-[600] leading-[12px] text-text-primary">
              BRAND
            </Typography>

            <div className="flex flex-col gap-[10px]">
              <div className="flex w-max gap-2 cursor-pointer" onClick={() => handleOpen()}>
                {brand ? (
                  <Typography size={12} className=" font-[900] leading-[18px] text-text-primary">
                    {truncate(brand.name.toLocaleUpperCase())}
                  </Typography>
                ) : (
                  <Typography size={12} className=" font-[900] leading-[18px] text-text-primary">
                    All
                  </Typography>
                )}

                <img src={assets.svgs.DOWN_ARROW} alt="down" />
              </div>

              <div className="grid gap-[20px] grid-cols-auto-fill md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 4xl:grid-cols-6 ">
                {creativeList ? (
                  creativeList.slice((currentPage - 1) * 12, 12 * currentPage).length > 0 &&
                  creativeList.slice((currentPage - 1) * 12, 12 * currentPage).map(creative => {
                    const getStatusData = creative.status && getCreativeStatus(creative.status)
                    return (
                      <div
                        key={creative.id}
                        className="relative w-[100%] flex flex-col p-[20px] rounded-[5px] gap-[10px] bg-secondary-primary ">
                        <div
                          className={
                            'absolute top-0 right-5 rounded-b-[5px] px-[8px] ' +
                            getStatusData?.style
                          }>
                          <Typography size={10} className="text-white font-[900] leading-[18px] ">
                            {getStatusData?.name ?? ''}
                          </Typography>
                        </div>
                        <div>
                          <Typography size={12} className=" font-[700] leading-[15px] ">
                            {creative.headline}
                          </Typography>
                          {creative.date && (
                            <Typography size={10} className=" font-[400] leading-[18px] ">
                              Created on {format(new Date(creative.date), 'do')} day of{' '}
                              {format(new Date(creative.date), 'MMM-yy')}
                            </Typography>
                          )}
                        </div>
                        <img
                          src={creative?.foregroundImageUrl as string}
                          alt="foreground_image"
                          className=" object-cover rounded-[5px] w-[200px] h-[165px] m-auto"
                        />
                        <div className="flex gap-[10px] justify-between   ">
                          <button
                            onClick={() => {
                              dispatch(setSelectedVariantId(Number(creative.variantId)))
                              dispatch(setCurrentCreative(creative))
                              dispatch(setSelectedProjectName(creative.projectName as string))
                              navigate(
                                // getStatusData?.name === 'Completed'
                                //   ? AppRoutes.CREATIVE_VARIANT_DIMENSIONS(
                                //       creative.uuid,
                                //       creative.variantId as string
                                //     )
                                //   : AppRoutes.CREATIVE_VARIANTS(String(creative.uuid))
                                AppRoutes.CREATIVE_VARIANTS(String(creative.uuid))
                              )
                            }}
                            className="flex flex-col w-[100%]  items-center px-[17px] pt-[11px] pb-[5px] rounded-[5px] bg-white">
                            <img src={assets.svgs.EDIT_DOT} alt="edit" className="m-auto" />
                            <Typography
                              size={10}
                              className="text-tooltip-primary font-[700] leading-[18px] ">
                              EDIT
                            </Typography>
                          </button>
                          <button
                            onClick={() =>
                              setOpenConfirm({
                                creativeId: creative.uuid!,
                                brandId: creative.brand?.uuid || '',
                                isOpen: true
                              })
                            }
                            className="flex flex-col items-center w-[100%]  px-[17px] pt-[11px] pb-[5px] rounded-[5px] bg-white">
                            <img src={assets.svgs.ARCHIEVE} alt="edit" className="m-auto " />
                            <Typography
                              size={10}
                              className="text-tooltip-primary font-[700] leading-[18px] ">
                              ARCHIVE
                            </Typography>
                          </button>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  // : isLoading && <CreativesSkeleton />
                  <CreativesSkeleton />
                )}
              </div>

              {creativeList &&
                creativeList.slice((currentPage - 1) * 12, 12 * currentPage).length === 0 && (
                  <div className={'flex gap-[110px]'}>
                    <div className="flex flex-col gap-[15px]">
                      {/*<Typography size={14} className=" font-[900] leading-[18px] ">*/}
                      {/*  CREATIVES*/}
                      {/*</Typography>*/}

                      <div>
                        <div>
                          {/*<Typography size={10} className=" font-[600] leading-[12px] ">*/}
                          {/*  BRAND*/}
                          {/*</Typography>*/}

                          <div className="flex flex-col gap-[10px]">
                            {/*<div className="flex gap-2 cursor-pointer">*/}
                            {/*  <Typography size={12} className=" font-[900] leading-[18px] ">*/}
                            {/*    SAMPLE BRAND NAME*/}
                            {/*  </Typography>*/}

                            {/*  /!* <img src={assets.svgs.DOWN_ARROW} alt="down" /> *!/*/}
                            {/*</div>*/}

                            <div className="flex">
                              <div className="relative w-[100%] flex flex-col p-[20px] rounded-[5px] gap-[10px] bg-secondary-primary ">
                                <div
                                  className={
                                    'absolute top-0 right-5 rounded-b-[5px] px-[8px] bg-sample'
                                  }>
                                  <Typography
                                    size={10}
                                    className="text-white font-[900] leading-[18px] ">
                                    SAMPLE
                                  </Typography>
                                </div>

                                <div>
                                  <Typography size={12} className=" font-[700] leading-[15px] ">
                                    NEW YEAR POST
                                  </Typography>
                                  <Typography size={10} className=" font-[400] leading-[18px] ">
                                    Created on {format(new Date('01-01-24'), 'do')} day of{' '}
                                    {format(new Date('01-01-24'), 'MMM-yy')}
                                  </Typography>
                                </div>
                                <img
                                  src={assets.images.DUMMY_4}
                                  alt="static_image"
                                  className=" object-cover"
                                />

                                <div className="flex gap-[10px] justify-between  ">
                                  <button className="flex flex-col w-[100%]  items-center px-[17px] pt-[11px] pb-[5px] rounded-[5px] bg-white">
                                    <img src={assets.svgs.EDIT_DOT} alt="edit" className="m-auto" />
                                    <Typography
                                      size={10}
                                      className="text-tooltip-primary font-[700] leading-[18px] ">
                                      EDIT
                                    </Typography>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        'flex flex-col relative b-[52px] flex-wrap justify-center items-center max-w-[450px]'
                      }>
                      {/* <SamplePage
                        isBrand={true}
                        primaryText={'It’s time to get creative'.toUpperCase()}
                        secondaryText={
                          'Now that you have a brand, it’s time to generate your creatives.'
                        }
                      /> */}

                      <img
                        src={assets.images.BLANK_CANVAS}
                        alt="user"
                        className={' max-w-[260px] max-h-[350px]'}
                      />

                      <Typography
                        size={24}
                        className="font-[900] leading-[18px] mt-[28px] text-center">
                        {'It’s time to get creative.'.toUpperCase()}
                      </Typography>

                      <Typography
                        size={14}
                        className="font-[400] leading-[18px] mt-[15px] text-center">
                        {'Now that you have a brand, it’s time to generate your creatives.'}
                      </Typography>
                    </div>
                  </div>
                )}
              {creativeList && (
                <>
                  {creativeList?.length > 0 && (
                    <div className="mt-[10px]">
                      <CustomPagination
                        totalPages={Math.ceil((creativeList?.length || 0) / 12)}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Creatives
