import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ApiRoutes } from 'src/constants/routes'
import { prepareHeaders } from 'src/utils/headers'

import { OnboardingReq, OnboardingRes } from './onboarding.type'

export const onboardingApi = createApi({
  reducerPath: 'onboardingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ApiRoutes.BASE_URL,
    prepareHeaders: headers => prepareHeaders(headers)
  }),
  tagTypes: [],
  endpoints: builder => ({
    onClickOnboard: builder.query<OnboardingRes, OnboardingReq>({
      query: values => ({
        url: ApiRoutes.ONCLICK_ONBOARDING,
        method: 'POST',
        body: values
      })
    })
  })
})

export const { useLazyOnClickOnboardQuery } = onboardingApi
