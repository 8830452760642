import Loader from '../../components/Loader'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { User } from '../../store/auth/auth.type.ts'
import { RtkError } from '../../store/redux.types.ts'
import { addCookie } from '../../utils/cookies.ts'
import { useAssumeUserMutation } from '../../store/auth/auth.api.ts'
import { useStateSelector } from '../../store/root.reducer.ts'
import { useAppDispatch } from '../../store'
import useHandleErrorMessage from '../../hook/useIsErrorMessage.ts'
import { AppRoutes } from '../../constants/routes.ts'
import { setAccount } from '../../store/auth/auth.slice.ts'

const AssumeUserPage = () => {
  const dispatch = useAppDispatch()
  const [params] = useSearchParams()
  const [assumeUser] = useAssumeUserMutation()
  const user = useStateSelector(state => state.authSlice.user)

  const handleErrorMessage = useHandleErrorMessage()
  const navigate = useNavigate()

  useEffect(() => {
    const userEmail = params.get('user_email')
    if (userEmail && user) {
      if (user.role === 'superuser') {
        assumeUser(userEmail).then(result => {
          const response = result as { data: User; error: RtkError }
          addCookie('auth_token', response.data.token)
          addCookie('isGuest', 'false')
          dispatch(setAccount(response))
          window.location.replace(AppRoutes.DASHBOARD)
        })
      } else {
        handleErrorMessage('You are not logged in as superuser account.', true)
        navigate(AppRoutes.DASHBOARD)
      }
    }
  }, [user])
  return (
    <div className="flex h-[calc(100vh-136px)] py-[20px] mx-4 gap-[30px] items-center justify-center max-w-[1640px]">
      <Loader />
    </div>
  )
}

export default AssumeUserPage
