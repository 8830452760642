import JoyRide, { Callback } from 'react-joyride'

interface props {
  step: any
  callback?: Callback
  stepIndex?: number
  run?: boolean
}

const Tour = ({ run, callback, step, stepIndex }: props) => {
  return (
    <>
      <JoyRide
        steps={step}
        stepIndex={stepIndex}
        continuous={true}
        run={run}
        disableCloseOnEsc={true}
        callback={callback}
        showProgress={false}
        styles={{
          tooltip: {
            background: 'rgba(75, 115, 116, 1)',
            width: 'auto',
            maxWidth: 300,
            color: '#fff'
          },
          tooltipContainer: {
            textAlign: 'left'
          },
          buttonNext: {
            background: 'rgba(255, 255, 255, 0.2)'
          },
          buttonSkip: {
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '4px',
            color: 'white',
            fontSize: '16px'
          },
          buttonBack: {
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '4px',
            color: 'white',
            fontSize: '16px'
          },

          options: {
            arrowColor: 'rgba(75, 115, 116, 1)'
          }
        }}
      />
    </>
  )
}

export default Tour
