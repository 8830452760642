import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'src/constants/routes'

const Home = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(AppRoutes.DASHBOARD)
  })

  return <></>
}

export default Home
