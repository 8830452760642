import { useNavigate } from 'react-router-dom'
import assets from 'src/assets'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import { AppRoutes } from 'src/constants/routes'
import { resetCreative } from '../../../store/creatives/creative.slice.ts'
import { useAppDispatch } from '../../../store'

interface SuccessBrandProps {
  brandId: string
  brandName: string
}

const SuccessBrand = ({ brandId, brandName }: SuccessBrandProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  return (
    <div className="flex container con py-[50px] px-[90px] gap-[16px] flex-col lg:flex-row">
      <div>
        <img
          className="min-w-[310px] min-h-[292px]"
          src={assets.images.SUCCESS_IMAGE}
          alt="success"
        />
      </div>
      <div className="mx-4">
        <div className="mt-[20px]">
          <Typography size={40} className="font-[900]">
            Yayy!!
          </Typography>

          <Typography size={16} className="font-[500] w-[326px] flex-wrap">
            BRAND <span className="font-[700]">{brandName.toUpperCase()}</span> HAS BEEN CREATED
            SUCCESSFULLY!!
          </Typography>

          {/* <div className="flex flex-col mt-[54px]">
            <Typography size={16} className="font-[800]">
              ADD AD ACCOUNTS <span className="font-[400] ">(OPTIONAL)</span>
            </Typography>

            <Typography size={14} className="font-[400]">
              It will be super easy once you have set up accounts.
            </Typography>
          </div>

          <div className="flex mt-[25px] gap-[40px]">
            <div className="flex flex-col gap-[6px]">
              <img src={assets.svgs.DRIVE} alt="drive" />
              <Typography size={12} className="font-[900] text-green-light">
                CONNECT
              </Typography>
            </div>
            <div className="flex flex-col gap-[6px]">
              <img src={assets.svgs.META} alt="meta" />
              <Typography size={12} className="font-[900] text-green-light">
                CONNECT
              </Typography>
            </div>

            <div className="flex flex-col gap-[6px]">
              <img src={assets.svgs.AMAZON} alt="amazon" />
              <Typography size={12} className="font-[900] text-green-light">
                CONNECT
              </Typography>
            </div> */}
          {/* </div> */}
        </div>

        <div className="flex flex-wrap mt-6 gap-[22px] items-center">
          <Button
            label="Generate Creative"
            onClick={() => {
              dispatch(resetCreative())
              navigate(AppRoutes.ADD_CREATIVE(brandId))
            }}
          />
          <Button
            label="Dashboard"
            onClick={() => navigate(AppRoutes.DASHBOARD)}
            className="pr-[47px] pl-[47px]"
          />
        </div>
      </div>
    </div>
  )
}

export default SuccessBrand
