import { v4 as uuidv4 } from 'uuid'
import { Skeleton } from './skeleton'

const CreativesSkeleton = () => {
  return (
    <>
      {Array.from({ length: 12 }).map(i => {
        const uniqueId = uuidv4()
        return (
          <div
            key={`${uniqueId}-${i}`}
            className="flex flex-col gap-1  p-5  min-h-[200px] w-[250px] border-2 border-secondary-primary rounded-[5px]">
            <Skeleton className="h-3 w-[60%] bg-secondary-primary" />
            <Skeleton className="h-2 w-full bg-secondary-primary" />
            <div className="space-y-2 mt-2">
              <Skeleton className="h-[150px] w-full bg-secondary-primary" />
            </div>
            <div className="flex gap-4 mt-2">
              <Skeleton className="h-11 w-[50%] bg-secondary-primary" />
              <Skeleton className="h-11 w-[50%] bg-secondary-primary" />
            </div>
          </div>
        )
      })}
    </>
  )
}

export default CreativesSkeleton
