import assets from 'src/assets'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'

interface OnBoardingSuccessProps {
  getStartedClickHandle: () => void
}

const OnBoardingSuccess = ({ getStartedClickHandle }: OnBoardingSuccessProps) => {
  return (
    <div className="flex container py-[50px] px-[90px] gap-[16px] flex-col lg:flex-row">
      <div>
        <img
          className="min-w-[310px] min-h-[292px]"
          src={assets.images.SUCCESS_IMAGE}
          alt="success"
        />
      </div>
      <div className="flex flex-col gap-[10px] mt-[20px]">
        <Typography size={40} className="font-[900] leading-9">
          Welcome to Eacel.ai
        </Typography>

        <Typography size={14} className="font-[700] max-w-[400px] flex-wrap">
          Your Eacel.ai account is live.
        </Typography>

        <Typography size={14} className="font-[700] max-w-[400px] flex-wrap">
          Get ready to create AI-powered performance creatives.
        </Typography>

        <div className="flex flex-col mt-6 gap-[22px] items-start">
          <Button label="GET STARTED" onClick={getStartedClickHandle} />
        </div>
      </div>
    </div>
  )
}

export default OnBoardingSuccess
