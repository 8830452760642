import { CSSProperties, useEffect, useRef, useState } from 'react'
import { PixelCrop } from 'react-image-crop'
import { useParams } from 'react-router-dom'
import assets from 'src/assets'
import CropIconComponent from 'src/components/CropIcon'
import Loader from 'src/components/Loader/index.tsx'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import { Tooltip } from 'src/components/ui/tooltip.tsx'
import useHandleErrorMessage from 'src/hook/useIsErrorMessage'
import useUploadAsset from 'src/hook/useUploadAsset'
import { assetsType } from 'src/pages/Brand/AddEditBrand/BrandLogoUploader'
import { useAppDispatch } from 'src/store'
import {
  AssetImageSelection,
  CreateAssetPayload,
  ImageSelection,
  UploadImageSelection,
  UserAssetWithProvider
} from 'src/store/brand/brand.type'
import { setSnackbar } from 'src/store/snackbar/snackbar.slice'
import { SnackbarType } from 'src/store/snackbar/snackbar.type'
import { imageUrlToFile } from 'src/utils/convertImageUrltoFile'
import { fetchCookie, isGuest } from 'src/utils/cookies'
import { trackEvent } from '../../../amplitude'
import { events } from '../../../amplitude/data.ts'
import { ApiRoutes } from '../../../constants/routes.ts'
import { useStateSelector } from '../../../store/root.reducer.ts'
import CropImage from './CropImage'

export type ImageSelectionPreviewProps = {
  imageSelection: ImageSelection
  submitBtnTxt?: string
  allowEdit?: boolean
  setUserAsset: (asset: UserAssetWithProvider) => void
  reset: () => void
  handleClose: () => void
  activeTab: string
  setAspectRatio?: (ratio: number) => void
  setImageSelection: (i: ImageSelection | null) => void
  aspectRatio?: number
  handleError: () => void
}

type ImageEditProps = {
  setCropMode: (m: boolean) => void
  rotateImage: (delta: number) => void
  reset: () => void
  isLoadingImage: boolean
  BackgroundRemoverHandler: () => void
  // ResetOriginalImageHandler: () => void
}

export interface ProcessedImage {
  blob: Blob
  width: number
  height: number
}

const EditIcons = ({
  setCropMode,
  rotateImage,
  reset,
  isLoadingImage,
  BackgroundRemoverHandler // ResetOriginalImageHandler
}: ImageEditProps) => {
  return (
    <>
      <div className="absolute top-4 right-6 flex gap-[15px] justify-center">
        <div
          onClick={() => setCropMode(true)}
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.60)' }}
          className="flex justify-center items-center rounded-full cursor-pointer h-[37px] w-[37px]">
          <Tooltip text="Crop" isCreative={true}>
            <CropIconComponent />
          </Tooltip>
        </div>

        <div
          onClick={() => rotateImage(90)}
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.60)' }}
          className="flex justify-center items-center rounded-full cursor-pointer h-[37px] w-[37px]">
          <Tooltip text="Rotate" isCreative={true}>
            <img src={assets.svgs.REVERSE} alt="reverse" />
          </Tooltip>
        </div>

        <div
          onClick={reset}
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.60)' }}
          className="flex justify-center items-center rounded-full cursor-pointer h-[37px] w-[37px]">
          <Tooltip text="Remove" isCreative={true}>
            <img src={assets.svgs.CROSS_TRASH} alt="reverse" />
          </Tooltip>
        </div>

        <div
          onClick={() => {
            if (!isLoadingImage) {
              BackgroundRemoverHandler()
            }
          }}
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.60)' }}
          className="flex justify-center items-center rounded-full cursor-pointer h-[37px] w-[37px]">
          <Tooltip text="Remove Background" isCreative={true}>
            <img src={assets.svgs.BACKGROUND_REMOVER} alt="reverse" />
          </Tooltip>
        </div>
      </div>

      {/* <div className="absolute bottom-0 right-0 mr-3 mb-3">
        <div
          // onClick={() => ResetOriginalImageHandler()}
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.60)' }}
          className="rounded-full flex cursor-pointer">
          <img src={assets.svgs.RESET} alt="reverse" style={{ margin: '10px 11px' }} />
        </div>
      </div> */}
    </>
  )
}

const processImage = async (
  imgElement: HTMLImageElement,
  rotationAngle: number,
  cropPosOrNull: PixelCrop | undefined
): Promise<ProcessedImage> => {
  const resizeWidthRatio = imgElement.naturalWidth / imgElement.width
  const resizeHeightRatio = imgElement.naturalHeight / imgElement.height
  const cropPos = cropPosOrNull
    ? {
        x: cropPosOrNull.x * resizeWidthRatio,
        y: cropPosOrNull.y * resizeHeightRatio,
        width: cropPosOrNull.width * resizeWidthRatio,
        height: cropPosOrNull.height * resizeHeightRatio
      }
    : {
        x: 100,
        y: 100,
        width: imgElement.naturalWidth ?? 100,
        height: imgElement.naturalHeight ?? 100
      }

  const canvas = window.OffscreenCanvas
    ? new OffscreenCanvas(cropPos.width, cropPos.height)
    : document.createElement('canvas')
  canvas.width = cropPos.width
  canvas.height = cropPos.height

  const ctx = canvas.getContext('2d')! as
    | CanvasRenderingContext2D
    | OffscreenCanvasRenderingContext2D

  // Translate context to center of canvas to rotate around center
  ctx.translate(cropPos.width / 2, cropPos.height / 2)
  ctx.rotate((rotationAngle * Math.PI) / 180) // Convert degrees to radians for rotation
  ctx.translate(-cropPos.width / 2, -cropPos.height / 2)

  // Draw the image onto the canvas with applied rotation and clipping
  // Note: You might need to adjust the drawImage parameters based on your specific clipping and rotation needs
  ctx.drawImage(
    imgElement,
    cropPos.x,
    cropPos.y,
    cropPos.width,
    cropPos.height,
    0,
    0,
    cropPos.width,
    cropPos.height
  )
  // Convert canvas to Blob
  return new Promise<ProcessedImage>((resolve, reject) => {
    if (window.OffscreenCanvas) {
      ;(canvas as OffscreenCanvas)
        .convertToBlob()
        .then(blob => {
          if (blob) {
            resolve({ blob, width: cropPos.width, height: cropPos.height })
          } else {
            reject(new Error('Blob conversion failed'))
          }
        })
        .catch(reject)
    } else {
      ;(canvas as HTMLCanvasElement).toBlob(
        blob => {
          if (blob) {
            resolve({ blob, width: cropPos.width, height: cropPos.height })
          } else {
            reject(new Error('Blob conversion failed'))
          }
        },
        'image/png' // Adjust MIME type as needed
      )
    }
  })
}

export interface requestOptions {
  method: string
  headers: Headers
  body: FormData
  redirect: string
}

const ImageSelectionPreview = ({
  imageSelection,
  allowEdit = true,
  setUserAsset,
  reset,
  handleClose,
  setImageSelection,
  activeTab,
  setAspectRatio,
  aspectRatio,
  handleError
}: ImageSelectionPreviewProps) => {
  const { brandId } = useParams()
  const dispatch = useAppDispatch()
  const handleErrorMessage = useHandleErrorMessage()
  const [isCropMode, setCropMode] = useState(false)
  const [imgRotationAngle, setImgRotationAngle] = useState(0)
  const [cropPos, setCropPos] = useState<PixelCrop | undefined>()
  const [isImageUploading, setIsImageUploading] = useState(false)
  const imageRef = useRef<HTMLImageElement>(null!)
  const [imgStyle, setImgStyle] = useState<CSSProperties>({})
  const { uploadAsset } = useUploadAsset()
  // const [, setOriginalImage] = useState<ImageSelection>()
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const creativeDetails = useStateSelector(state => state.creativeSlice)
  const rotateImage = (delta: number) => {
    setImgRotationAngle(angle => angle + delta)
    trackEvent(events.AD_CREATION_IMAGE_EDIT, {
      brand_id: brandId,
      project_name: creativeDetails.projectName,
      session_type: isGuest() ? 'guest' : 'registered',
      activity: 'rotated'
    })
  }

  const handleUpload = async () => {
    trackEvent(events.AD_CREATION_IMAGE_UPLOAD_INITIATED, {
      brand_id: brandId,
      project_name: creativeDetails.projectName,
      session_type: isGuest() ? 'guest' : 'registered'
    })
    const imgBlob = await processImage(imageRef.current, imgRotationAngle, cropPos)
    if (imgBlob.height > 700 || imgBlob.width > 700) {
      if (aspectRatio && aspectRatio < 2) {
        setIsImageUploading(true)
        if (imageSelection.pickerType === 'asset' && imgRotationAngle === 0 && !cropPos) {
          const assetImageSelection = imageSelection as AssetImageSelection
          setUserAsset(assetImageSelection.asset)
          setIsImageUploading(false)
          handleClose()

          return
        }

        const dataTransfer = new DataTransfer()
        dataTransfer.items.add(
          new File([imgBlob.blob], imageSelection.name, {
            type:
              imageSelection.pickerType === 'upload'
                ? (imageSelection.fileType as string)
                : imgBlob.blob.type
          })
        )
        const createAssetPayload: CreateAssetPayload = {
          fileName:
            imageSelection.pickerType === 'upload'
              ? imageSelection.name
              : `${imageSelection.name}.${imgBlob.blob.type.split('/')[1]}`,
          assetType: brandId ? assetsType.FOREGROUND : assetsType.Other,
          contentType:
            imageSelection.pickerType === 'upload'
              ? (imageSelection.fileType as string)
              : imgBlob.blob.type,
          provider: imageSelection.provider,
          providerAttributionUrl: imageSelection.attributionUrl || ''
        }

        try {
          const uploadedAssets = await uploadAsset([createAssetPayload], dataTransfer.files)
          setUserAsset(uploadedAssets[0] as UserAssetWithProvider)
          if (brandId) {
            trackEvent(events.AD_CREATION_IMAGE_UPLOAD_SUCCESS, {
              brand_id: brandId,
              session_type: isGuest() ? 'guest' : 'registered',
              project_name: creativeDetails.projectName,
              assets_details: {
                image_source: imageSelection.pickerType,
                asset_type: createAssetPayload.assetType,
                file_type: createAssetPayload.contentType
              }
            })
          } else {
            trackEvent(events.USER_FLOW_IMAGE_UPLOAD_SUCCESS, {
              brand_id: brandId,
              assets_details: {
                image_source: imageSelection.pickerType,
                asset_type: createAssetPayload.assetType,
                file_type: createAssetPayload.contentType
              }
            })
          }
        } catch (error) {
          if (!brandId) {
            handleErrorMessage('Error while uploading foreground image', true)
            handleError()
          }
          trackEvent(events.AD_CREATION_IMAGE_UPLOAD_ERROR, {
            brand_id: brandId,
            session_type: isGuest() ? 'guest' : 'registered',
            project_name: creativeDetails.projectName,
            failure_reason: error
          })
        } finally {
          if (!brandId) {
            dispatch(
              setSnackbar({
                severity: SnackbarType.SUCCESS,
                message: 'Image Uploaded Successfully!'
              })
            )
          }
          setIsImageUploading(false)
          handleClose()
        }
      } else {
        handleErrorMessage('Image aspect ratio (w:h) must be 2:1 or lower.', true)
        handleError()
      }
    } else {
      handleErrorMessage('Please Upload an Image with a minimum resolution of 700px x 700px.', true)
      handleError()
    }
  }

  useEffect(() => {
    const handleImageLoad = () => {
      // Early exit if imageRef.current is null or its parentElement is null
      if (!imageRef.current || !imageRef.current.parentElement) {
        return
      }

      const style: CSSProperties = {} // Assuming you're declaring CSSProperties type somewhere else
      const imageWidth = imageRef.current.width
      const imageHeight = imageRef.current.height
      const ratio = imageRef.current.naturalWidth / imageRef.current.naturalHeight

      if (setAspectRatio) setAspectRatio(Number(ratio.toFixed(2)))
      // Safely accessing parentElement now, as we've checked it's not null
      imageRef.current.parentElement.style.width = `${imageWidth}px`

      if (cropPos) {
        style.clipPath = `inset(${cropPos.y}px ${imageWidth - cropPos.x - cropPos.width}px  ${
          imageHeight - cropPos.y - cropPos.height
        }px ${cropPos.x}px)`
      }
      if (imgRotationAngle) {
        style.transform = `rotate(${imgRotationAngle}deg)`
      }
      setImgStyle(style)
      // Now you can do whatever you need with the width and height
    }

    // The !imageRef.current.complete check ensures the image is loaded before calling handleImageLoad
    if (imageRef.current && !imageRef.current.complete) {
      imageRef.current.onload = handleImageLoad
    } else {
      // Image is already loaded, so call the function immediately
      handleImageLoad()
    }
  }, [isCropMode, imgRotationAngle, cropPos])

  const BackgroundRemoverHandler = async () => {
    setIsLoadingImage(true)
    const auth_token = fetchCookie('auth_token') || ''
    // setOriginalImage(imageSelection)
    if (imageSelection) {
      imageUrlToFile(
        imageSelection.url,
        imageSelection.pickerType === 'stock'
          ? `${imageSelection.sponsorName}.jpg`
          : imageSelection.name
      ).then(response => {
        if (auth_token) {
          // const result = getRemoveBackground(response) as unknown as Blob
          // const file = new File([result], response.name, { type: 'image/png' })
          // const dataUrl = URL.createObjectURL(result)
          // setImageSelection(new UploadImageSelection(file, dataUrl))
          const headers = new Headers()
          headers.append('authorization', `Bearer ${auth_token}`)

          const formdata = new FormData()
          formdata.append('input_image', response, response.name)

          const requestOptions: RequestInit = {
            method: 'POST',
            headers,
            body: formdata,
            redirect: 'follow'
          }

          fetch(
            `${import.meta.env.VITE_ML_API_BASE_URL}${ApiRoutes.REMOVE_BACKGROUND}`,
            requestOptions
          )
            .then(response => response.blob())
            .then(async result => {
              const file = new File(
                [result],
                imageSelection.pickerType === 'stock'
                  ? `${response.name}`
                  : `${response.name.substring(0, response.name.lastIndexOf('.'))}.png`,
                {
                  type: 'image/png'
                }
              )

              const dataUrl = URL.createObjectURL(result)
              trackEvent(events.AD_CREATION_IMAGE_EDIT, {
                session_type: isGuest() ? 'guest' : 'registered',
                brand_id: brandId,
                project_name: creativeDetails.projectName,
                activity: 'bgRemoved'
              })

              setImageSelection(new UploadImageSelection(file, dataUrl))
              setIsLoadingImage(false) // This sets the data URL to the React state
            })
            .catch(error => handleErrorMessage(error.message, true))
        }
      })
    }
  }

  // const ResetOriginalImageHandler = () => {
  //   setImageSelection(originalImage)
  // }

  return (
    <div id="eacel_onboarding_step_seven" className="mx-[10px] lg:mx-[50px] my-[44px]">
      <div className="flex flex-col relative items-center  h-[370px] m-auto gap-[20px] bg-white  overflow-hidden border-[1px] border-dashed border-[#cecece] rounded-sm">
        {isCropMode ? (
          <CropImage
            rotationAngle={imgRotationAngle}
            url={
              imageSelection.pickerType !== 'stock'
                ? (imageSelection.url as string)
                : (imageSelection.urls?.regular as string)
            }
            cropPos={cropPos}
            setCropPos={setCropPos}
            setCropMode={setCropMode}
          />
        ) : (
          <>
            <div
              className=" h-full flex justify-end items-end"
              style={
                imgStyle.clipPath
                  ? {
                      clipPath: imgStyle.clipPath
                    }
                  : {}
              }>
              {isLoadingImage ? (
                <Loader />
              ) : (
                <img
                  ref={imageRef}
                  src={
                    imageSelection.pickerType !== 'stock'
                      ? imageSelection.url
                      : imageSelection.urls?.regular
                  }
                  crossOrigin="anonymous"
                  style={imgStyle.transform ? { transform: imgStyle.transform } : {}}
                  alt="img"
                  className="absolute h-full object-contain"
                />
              )}
            </div>

            {allowEdit && (
              <EditIcons
                setCropMode={setCropMode}
                rotateImage={rotateImage}
                reset={reset}
                BackgroundRemoverHandler={BackgroundRemoverHandler}
                // ResetOriginalImageHandler={ResetOriginalImageHandler}
                isLoadingImage={isLoadingImage}
              />
            )}
          </>
        )}
      </div>

      {activeTab === 'EACEL STOCK' && !isCropMode && (
        <Typography
          size={12}
          className="flex flex-wrap justify-center italic text-right text-black px-[15px] py-[5px] z-1">
          Photo by
          <a
            href={imageSelection?.sponsorLink}
            className="font-[700] ml-1 mr-1 underline"
            target="_blank"
            rel="noopener noreferrer">
            {imageSelection.sponsorName}
          </a>{' '}
          on
          <a
            href={imageSelection?.providerLink}
            className="font-[700] ml-1 underline capitalize"
            target="_blank"
            rel="noopener noreferrer">
            {imageSelection.provider}
          </a>
        </Typography>
      )}

      <div className="flex justify-center mt-[30px]" onClick={() => handleUpload()}>
        <Button
          disabled={isCropMode || !imageSelection || isImageUploading || isLoadingImage}
          label={isImageUploading ? 'UPLOADING...' : 'UPLOAD'}
          className="px-[30px] py-[10px]"
        />
      </div>
    </div>
  )
}

export default ImageSelectionPreview
