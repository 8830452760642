import { useEffect } from 'react'
import { Toaster, toast } from 'sonner'
import { useAppDispatch } from 'src/store'
import { useStateSelector } from 'src/store/root.reducer'
import { setSnackbar } from 'src/store/snackbar/snackbar.slice'
import { SnackbarType } from 'src/store/snackbar/snackbar.type'

const getSnackbarStyles = (variant: SnackbarType) => {
  switch (variant) {
    case 'success':
      return {
        color: 'var(--toast-success)'
      }
    case 'error':
      return {
        color: 'var(--toast-error)'
      }
    case 'warning':
      return {
        color: 'var(--toast-draft)'
      }
    default:
      return {
        color: 'var(--toast-success)'
      }
  }
}

const Snackbar = () => {
  const { isOpen, autoClose, message, severity, position } = useStateSelector(
    state => state.snackbarSlice
  )
  const { color } = getSnackbarStyles(severity ?? SnackbarType.SUCCESS)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isOpen) return
    const showToast = () => {
      toast(
        <div className="flex gap-8 w-full justify-between items-center select-none">
          <div className="text-white  ml-3  line-clamp-2 font-medium">{message}</div>
          <div
            onClick={() => toast.dismiss()}
            style={{ color }}
            className="rounded-full bg-white  min-h-[30px] min-w-[30px]  text-md flex justify-center items-center cursor-pointer ">
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.18342 1.18342C1.42634 0.940489 1.82084 0.940489 2.06377 1.18342L4.99966 4.11931L7.93763 1.18342C8.18056 0.940489 8.57506 0.940489 8.81799 1.18342C9.06091 1.42634 9.06091 1.82084 8.81799 2.06377L5.88209 4.99966L8.81799 7.93556C9.06091 8.17848 9.06091 8.57298 8.81799 8.81591C8.57506 9.05884 8.18056 9.05884 7.93763 8.81591L5.00174 5.88002L2.06585 8.81591C1.82292 9.05884 1.42842 9.05884 1.18549 8.81591C0.942565 8.57298 0.942565 8.17848 1.18549 7.93556L4.12139 4.99966L1.18342 2.06377C0.940489 1.82084 0.940489 1.42634 1.18342 1.18342Z"
                fill={color}
                stroke={color}
                strokeWidth="0.5"
              />
            </svg>
          </div>
        </div>,
        {
          style: {
            background: color,
            borderRadius: '150px'
          },
          position: position,
          duration: 5000
        }
      )
    }
    showToast()
    setTimeout(() => {
      dispatch(setSnackbar({ isOpen: false }))
      toast.dismiss()
    }, autoClose)
  }, [isOpen])

  return <Toaster />
}

export default Snackbar
