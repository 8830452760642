import * as Amplitude from '@amplitude/analytics-browser'
import { memoize } from 'lodash'

export const initialize = memoize(() => {
  if (import.meta.env.VITE_PROJECT_ENV !== 'production') return
  const key = import.meta.env.VITE_AMPLITUDE_API_KEY
  if (!key) return
  Amplitude.init(key, {
    defaultTracking: {
      pageViews: false,
      fileDownloads: false
    }
  })
})

export const trackEvent = (name: string, data?: Record<string, any>) => {
  if (import.meta.env.VITE_PROJECT_ENV !== 'production') return
  // initialize()
  Amplitude.track(name, data)
}

export const setUserForTracking = (id: string, name: string, email: string) => {
  if (import.meta.env.VITE_PROJECT_ENV !== 'production') return
  // initialize()
  const identifyEvent = new Amplitude.Identify()
  identifyEvent.set('user_id', id)
  identifyEvent.set('full_name', name)
  identifyEvent.set('email', email)
  Amplitude.identify(identifyEvent)
}

export const clearSession = () => {
  if (import.meta.env.VITE_PROJECT_ENV !== 'production') return
  Amplitude.flush()
}
