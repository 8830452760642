interface FontFieldProps {
  name: string
  handleClick: () => void
}

const FontField = ({ name = 'default', handleClick }: FontFieldProps) => {
  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className="flex items-end pb-[5px] text-[12px] font-[700]  gap-[14px] text-text-primary text-left px-2 border-b-[1px] relative border-common-black after:absolute after:right-0 after:border-r-[1px] after:h-1 after:inline-block after:border-black after:bottom-0 before:absolute before:border-r-[1px] before:h-1 before:inline-block before:left-0 before:border-black before:bottom-0">
        {name}
        <span>
          <img className="inline-block" src="/assets/svgs/downArrow.svg" alt="down_logo" />
        </span>
      </button>
    </>
  )
}

export default FontField
