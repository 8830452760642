import { LegacyRef, forwardRef } from 'react'

interface Props {
  color: string
}

const SwitchIcon = ({ color = 'var(--black)' }: Props, ref: LegacyRef<SVGSVGElement>) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}>
    <path
      d="M15.76 2.55404L15.7609 2.55469C18.17 4.35133 19.6 7.21104 19.6 10.2128C19.6 15.5025 15.2897 19.8128 10 19.8128C4.71028 19.8128 0.4 15.5025 0.4 10.2128C0.4 7.21104 1.83 4.35133 4.23913 2.55469L4.24 2.55404C4.74071 2.17851 5.46955 2.28564 5.85021 2.79319L6.16052 2.56046L5.85021 2.79319C6.2256 3.29371 6.1187 4.02219 5.61164 4.40297C3.76498 5.77703 2.70638 7.89628 2.70638 10.2128C2.70638 14.2209 5.99185 17.5064 10 17.5064C14.0073 17.5064 17.2936 14.243 17.2936 10.2128C17.2936 7.89625 16.235 5.77699 14.3883 4.40293C13.8813 4.02214 13.7744 3.29369 14.1498 2.79319C14.5305 2.28564 15.2593 2.17851 15.76 2.55404Z"
      fill={color}
    />
    <path
      d="M11.1532 11.1702C11.1532 11.8004 10.6301 12.3234 9.99997 12.3234C9.36982 12.3234 8.84678 11.8004 8.84678 11.1702V1.55319C8.84678 0.923042 9.36982 0.4 9.99997 0.4C10.6301 0.4 11.1532 0.923042 11.1532 1.55319V11.1702Z"
      fill={color}
    />
  </svg>
)
export default forwardRef(SwitchIcon)
