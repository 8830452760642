import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import HeaderTitle from 'src/components/HeaderTitle'
import { Button } from 'src/components/ui/button'
import Drawer from 'src/components/ui/drawer'
import { TextField } from 'src/components/ui/textfield'
import { AppRoutes } from 'src/constants/routes'
import useIsMobile from 'src/hook/useIsMobile'
import { useAppDispatch } from 'src/store'
import { useChangePasswordMutation } from 'src/store/auth/auth.api'
import { setSnackbar } from 'src/store/snackbar/snackbar.slice'
import { SnackbarType } from 'src/store/snackbar/snackbar.type'
import { removeCookie } from 'src/utils/cookies'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import AccountInfo from './AccountInfo'

const Account = () => {
  const isMobile = useIsMobile()
  // const user = useStateSelector(state => state.authSlice.user)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false)
  const [changePassword, { isLoading }] = useChangePasswordMutation()

  const {
    values,
    setFieldValue,
    errors,
    touched,
    setFieldTouched,
    isValid,
    handleSubmit,
    isSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: toFormikValidationSchema(
      z
        .object({
          oldPassword: z.string({ required_error: 'Please enter password' }),
          newPassword: z
            .string({ required_error: 'Please enter password' })
            .refine(value => value.length >= 8, {
              message: 'Password must be 8 or more characters long.'
            }),
          confirmPassword: z.string({ required_error: 'Please enter password' })
        })
        .refine(data => data.newPassword === data.confirmPassword, {
          message: 'Password must be same',
          path: ['confirmPassword']
        })
    ),
    onSubmit: values => {
      // const { confirmPassword, ...updateValues } = values
      changePassword(values).then(() => {
        setIsChangePasswordOpen(false)
        dispatch(
          setSnackbar({
            message: 'Successfully! Password Changed',
            severity: SnackbarType.SUCCESS
          })
        )
        resetForm()
        removeCookie('auth_token')
        navigate(AppRoutes.LOGIN)
      })
    },
    enableReinitialize: true,
    validateOnMount: true
  })

  const handleChange = (key: string, value: string) => {
    setFieldValue(key, value)
  }

  const handleOnBlur = (key: string) => {
    setFieldTouched(key, true)
  }

  return (
    <>
      <Drawer
        size={isMobile ? '100%' : '30%'}
        header={'CHANGE PASSWORD'}
        isOpen={isChangePasswordOpen}
        onClose={() => setIsChangePasswordOpen(false)}>
        <form
          id="change-pasword-form"
          name="change-password-form"
          onSubmit={handleSubmit}
          className="flex flex-col gap-[10px] mx-[20px]">
          <TextField
            type="password"
            variant={'rounded'}
            placeholder="Enter your Old Password"
            id="oldPassword"
            className="bg-white"
            value={values.oldPassword.trimStart()}
            width="60%"
            handleOnChange={(key, value) => handleChange(key, value)}
            onBlur={() => handleOnBlur('oldPassword')}
            errorText={touched.oldPassword && (errors.oldPassword as string)}
          />

          <TextField
            type="password"
            variant={'rounded'}
            placeholder="Enter New Password"
            id="newPassword"
            className="bg-white"
            value={values.newPassword.trimStart()}
            width="60%"
            handleOnChange={(key, value) => handleChange(key, value)}
            onBlur={() => handleOnBlur('newPassword')}
            errorText={touched.newPassword && (errors.newPassword as string)}
          />

          <TextField
            type="password"
            variant={'rounded'}
            placeholder="Retype new Password"
            id="confirmPassword"
            className="bg-white"
            value={values.confirmPassword.trimStart()}
            width="60%"
            handleOnChange={(key, value) => handleChange(key, value)}
            onBlur={() => handleOnBlur('confirmPassword')}
            errorText={touched.confirmPassword && (errors.confirmPassword as string)}
          />

          <div>
            <Button
              label="CHANGE"
              disabled={!isValid}
              isLoading={isLoading || isSubmitting}
              className="w-fit px-[30px] py-[10px] text-[14px]  font-[900] my-[20px] xl:my-[30px]"
            />
          </div>
        </form>
      </Drawer>
      <div className="max-w-[1640px] my-[30px] mx-[20px]">
        <HeaderTitle primaryName="MY" secondaryName="ACCOUNT" />
        <AccountInfo handleChangePassword={() => setIsChangePasswordOpen(true)} />
      </div>
    </>
  )
}

export default Account
