const ErrorFallback = () => {
  return (
    <div
      className={
        'flex flex-1 h-full min-h-[600px] self-center justify-center items-center gap-2 flex-col'
      }>
      <h1 className={'font-bold text-[30px]'}>Something went wrong.</h1>
      <p className={'font-normal text-[16px]'}>
        We're sorry for the inconvenience. Please try again later or contact support if the problem
        persists.
      </p>
    </div>
  )
}
export default ErrorFallback
