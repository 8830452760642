import { useGoogleLogin } from '@react-oauth/google'
import { useFormik } from 'formik'
import LogRocket from 'logrocket'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import assets from 'src/assets'
import CircularLoaderIcon from 'src/components/CircularLoaderIcon.tsx'
import GoogleIcon from 'src/components/GoogleIcon.tsx'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import { TextField } from 'src/components/ui/textfield'
import { AppRoutes } from 'src/constants/routes'
import useHandleErrorMessage from 'src/hook/useIsErrorMessage'
import { useAppDispatch } from 'src/store'
import { useLoginMutation } from 'src/store/auth/auth.api'
import { setAccount } from 'src/store/auth/auth.slice'
import { User } from 'src/store/auth/auth.type'
import { RtkError } from 'src/store/redux.types'
import { addCookie } from 'src/utils/cookies'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { setUserForTracking, trackEvent } from '../../amplitude'
import { events } from '../../amplitude/data.ts'
import OnBoardingSuccess from './OnBoardingSuccess.tsx'
import TagManager from 'react-gtm-module'

const Login = () => {
  const dispatch = useAppDispatch()
  const handleErrorMessage = useHandleErrorMessage()
  const location = useLocation()
  const navigate = useNavigate()
  const [isGoogleLoading, setIsGoogleLoading] = useState(false)
  const [login, { isLoading }] = useLoginMutation()
  // const [googleLoginUser] = useGoogleLoginUserMutation()
  const [isSuccess, setIsSuccess] = useState(false)
  const [registerResponse, setRegisterResponse] = useState<User | null>(null)
  const handleChange = (key: string, value: string) => {
    setFieldValue(key, value)
  }
  const handleOnBlur = (key: string) => {
    setFieldTouched(key, true)
  }
  useEffect(() => {
    trackEvent(events.ONBOARDING_LOGIN_VIEWED)
    const tagManagerArgs = {
      gtmId: import.meta.env.VITE_GOOGLE_GTMID
    }

    TagManager.initialize(tagManagerArgs)
  }, [])
  const {
    values,
    setFieldValue,
    touched,
    errors,
    isValid,
    setFieldTouched,
    handleSubmit,
    isSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: toFormikValidationSchema(
      z.object({
        email: z.string({ required_error: 'Please enter email.' }).email({
          message: 'Invalid email  '
        }),
        password: z.string({ required_error: 'Please enter password' })
      })
    ),
    onSubmit: values => {
      login(values)
        .then(res => {
          const response = res as { data: User; error: RtkError }
          if (response.error) {
            trackEvent(events.ONBOARDING_LOGIN_FAILURE, {
              failure_reason: response.error.data.message,
              method: 'email'
            })
            handleErrorMessage(response.error.data.message, true)
          }
          if (response.data) {
            addCookie('auth_token', response?.data?.token)
            addCookie('isGuest', 'false')
            dispatch(setAccount(response.data))
            LogRocket.identify(response.data.uuid, {
              name: response.data.fullName,
              email: response.data.email
            })
            setUserForTracking(response.data.uuid, response.data.fullName, response.data.email)
            trackEvent(events.ONBOARDING_LOGIN_SUCCESS, {
              method: 'email'
            })
            navigate(location.state ? location.state.from : AppRoutes.HOME)
          }
        })
        .catch((error: RtkError) => {
          if (error) {
            trackEvent(events.ONBOARDING_LOGIN_FAILURE, {
              failure_reason: error.data.error.message,
              method: 'email'
            })
            handleErrorMessage(error.data.error.message, true)
          }
        })
        .finally(() => {
          resetForm()
        })
    },
    enableReinitialize: true,
    validateOnMount: true
  })

  const loginGoogleApi = useGoogleLogin({
    onSuccess: async tokenResponse => {
      // const tokenResponse = response.toJSON()
      const accessToken = tokenResponse.access_token
      fetch(`${import.meta.env.VITE_API_BASE_URL}/google-login`, {
        method: 'POST',
        body: JSON.stringify({ accessToken }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async res => {
          const response = (await res.json()) as User
          // const response = data

          if (res.status === 200) {
            if (response) {
              addCookie('auth_token', response.token)
              addCookie('isGuest', 'false')
              dispatch(setAccount(response))
              LogRocket.identify(response.uuid, {
                name: response.fullName,
                email: response.email
              })
              setUserForTracking(response.uuid, response.fullName, response.email)
              trackEvent(events.ONBOARDING_LOGIN_SUCCESS, {
                method: 'google_sso'
              })
              navigate(location.state ? location.state.from : AppRoutes.HOME)
            }
            setIsSuccess(false)
          } else {
            setIsSuccess(true)
            setRegisterResponse(response)
            trackEvent(events.ONBOARDING_SIGNUP_SUCCESS, {
              method: 'google_sso'
            })
          }

          if ((response && res.status !== 200) || res.status !== 201) {
            const errorResponse = (await res.json()) as RtkError
            trackEvent(events.ONBOARDING_LOGIN_FAILURE, {
              failure_reason: errorResponse.data.message,
              method: 'google_sso'
            })
            handleErrorMessage(errorResponse.data.message, true)
          }
        })
        .catch((error: RtkError) => {
          if (error && error.data && error.data.error && error.data.error.message) {
            trackEvent(events.ONBOARDING_LOGIN_FAILURE, {
              failure_reason: error.data.error.message,
              method: 'google_sso'
            })
            handleErrorMessage(error.data.error.message, true)
          }
        })
        .finally(() => {
          setIsGoogleLoading(false)
        })
    },
    onNonOAuthError: () => {
      setIsGoogleLoading(false)
    },
    onError: err => {
      setIsGoogleLoading(false)
      err.error && handleErrorMessage(err.error, true)
    }
  })
  return (
    <>
      {isSuccess ? (
        <div className="h-[calc(100vh-136px)] flex items-center justify-center">
          <OnBoardingSuccess
            getStartedClickHandle={() => {
              if (registerResponse) {
                addCookie('auth_token', registerResponse.token)
                addCookie('isGuest', 'false')
                dispatch(setAccount(registerResponse))
                LogRocket.identify(registerResponse.uuid, {
                  name: registerResponse.fullName,
                  email: registerResponse.email
                })
                setUserForTracking(
                  registerResponse.uuid,
                  registerResponse.fullName,
                  registerResponse.email
                )
              } else {
                navigate(AppRoutes.LOGIN)
              }
            }}
          />
        </div>
      ) : (
        <div className="flex m-auto items-center h-[100vh] px-[0px] sm:px-[80px] py-[0px] sm:py-[90px] max-w-[1640px] overflow-auto">
          <div className="relative h-fit flex flex-col xl:flex-row justify-end gap-4 xl:gap-0 rounded-[5px] bg-paper w-full  p-[30px] ">
            <div className="relative xl:absolute left-[0px] w-[50%]">
              <img src={assets.images.ROBOT} alt="user" className="w-[80%]" />
            </div>
            <div className="h-full w-[100%] xl:w-[68%] rounded-[5px] flex flex-col md:flex-row justify-between bg-white pt-[50px] pr-[20px] sm:pr-[60px] pb-[10px] sm:pb-[50px] pl-[20px] md:pl-[3rem] xl:pl-[8rem]">
              <div className="py-3 h-full w-[100%]">
                <img src={assets.svgs.LOGO} alt="img" />
                <Typography
                  size={26}
                  className="font-[400] mt-10 leading-[36px] text-text-primary flex flex-wrap pr-[10px]">
                  Welcome back to Eacel!
                </Typography>
                <Typography
                  size={16}
                  className=" text-text-primary font-[300] pr-[20px]  leading-[20px] max-w-[360px] break-after-all">
                  Login to generate your newest batch of ad creatives
                </Typography>
              </div>
              <div className="h-[auto]  border-[0px] md:border-[1px] border-divider md:min-h-[33px] min-h-0 md:none" />
              <form
                id="login-form"
                name="login-form"
                onSubmit={e => {
                  e.preventDefault()
                  handleSubmit()
                }}
                className="w-[100%] py-[30px] md:py-[10px] pl-[0px] md:pl-[50px]">
                <Typography size={14} className="font-[900] leading-[18px] text-text-primary ">
                  LOGIN
                </Typography>

                <div className="flex w-[100%]  flex-col mt-[24px] mb-[30px] gap-[10px]">
                  <TextField
                    variant={'rounded'}
                    placeholder="Enter Your Email ID"
                    id="email"
                    className="bg-white"
                    value={values.email.trimStart()}
                    width="100%"
                    handleOnChange={(key, value) => handleChange(key, value)}
                    onBlur={() => handleOnBlur('email')}
                    errorText={touched.email && (errors.email as string)}
                  />

                  <TextField
                    type="password"
                    variant={'rounded'}
                    placeholder="Enter Password"
                    id="password"
                    className="bg-white"
                    value={values.password.trimStart()}
                    width="100%"
                    handleOnChange={(key, value) => handleChange(key, value)}
                    onBlur={() => handleOnBlur('password')}
                    errorText={touched.password && (errors.password as string)}
                  />
                </div>

                <Typography
                  size={12}
                  className="flex justify-end font-[400] leading-[18px] text-text-primary mb-[12px]">
                  <span
                    className="underline font-[900] text-green-light cursor-pointer"
                    onClick={() => navigate(AppRoutes.FORGOT_PASSWORD)}>
                    {' '}
                    Forgot Password
                  </span>
                </Typography>
                <div className="flex flex-col gap-5 items-start my-[20px] xl:my-[55px] ">
                  <Button
                    label="LOGIN"
                    type="submit"
                    disabled={!isValid}
                    isLoading={isLoading || isSubmitting}
                    className=" px-[20px] py-[10px] text-[14px] font-[900] "
                  />

                  <Typography
                    size={14}
                    className="flex justify-end font-[900] leading-[18px] text-text-primary ml-8">
                    OR
                  </Typography>

                  <button
                    type={'button'}
                    className="flex gap-[10px] items-center border-[1px] px-6 py-[10px] rounded-full"
                    onClick={() => {
                      setIsGoogleLoading(true)
                      loginGoogleApi()
                    }}>
                    <GoogleIcon />
                    <Typography
                      size={13}
                      className="flex justify-end font-[900] leading-[18px] text-text-primary ">
                      Continue with Google
                    </Typography>
                    {isGoogleLoading && <CircularLoaderIcon />}
                  </button>
                </div>

                <div className="mb-5 ">
                  {/* <Button
                type="button"
                label="Sign in with Google"
                onClick={() => {
                  setIsGoogleLoading(true)
                  loginGoogleApi()
                }}
                isLoading={isGoogleLoading}
                className=" px-[20px] py-[10px] text-[14px] font-[900] mb-[24px]"
              /> */}
                </div>
                <Typography
                  size={12}
                  className="font-[400] leading-[18px] text-text-primary mb-[0px]">
                  Not A Member?{' '}
                  <span
                    className="underline font-[900] text-green-light cursor-pointer"
                    onClick={() => {
                      // navigate(AppRoutes.SIGN_UP)
                      window.location.replace(AppRoutes.SIGN_UP)
                      // trackEvent(events.ONBOARDING_SIGNUP_INITIATED, {
                      //   method: 'email'
                      // })
                    }}>
                    Sign Up
                  </span>
                </Typography>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Login
