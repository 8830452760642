export default async function convertFilesToDataURL(
  fileList: FileList | File[]
): Promise<string[]> {
  const promises: Promise<string>[] = []

  // Iterate over each file in the FileList
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i]
    promises.push(convertFileToDataURL(file)) // Convert each file to a data URL and push the promise to the array
  }

  // Wait for all promises to resolve using Promise.all()
  return Promise.all(promises)
}

// Helper function to convert a single file to a data URL
export function convertFileToDataURL(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const dataURL = reader.result as string
      resolve(dataURL) // Resolve the promise with the data URL
    }
    reader.onerror = () => {
      reject(reader.error) // Reject the promise if an error occurs
    }
    reader.readAsDataURL(file) // Read the file as a data URL
  })
}
