const AppRoutes = {
  HOME: '/',
  LOGIN: '/login',
  SIGN_UP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  MY_ASSETS: '/my-assets',
  ACCOUNT: '/account',
  ERROR: '/error',
  BRAND_LIST: '/brands',
  DASHBOARD: '/dashboard',
  ADD_BRAND: '/brands/create',
  ONBOARDING: '/onboarding',
  ASSUME_USER: '/admin/assume-user',
  EDIT_BRAND: (brandId = ':brandId') => `/brands/${brandId}/edit`,
  ADD_CREATIVE: (brandId = ':brandId') => `/brands/${brandId}/creative`,
  CREATIVE_VARIANTS: (creativeId = ':creativeId') => `/creative/${creativeId}/variants`,
  CREATIVE_VARIANT_DIMENSIONS: (creativeId = ':creativeId', variantId = ':variantId') =>
    `/creative/${creativeId}/variant-dimensions/${variantId}`
}

export const getCookieDomain = () => {
  switch (import.meta.env.VITE_PROJECT_ENV) {
    case 'production':
      return '.eacel.ai'
    case 'staging':
      return '.eacel.ai'
    case 'development':
      return '.eacel.ai'
    default:
      return 'localhost'
  }
}
export const getApiBaseUrlRoute = () => {
  if ((localStorage.getItem('VITE_API_BASE_URL') || '').trim()) {
    return localStorage.getItem('VITE_API_BASE_URL')
  } else if (import.meta.env.VITE_IS_MOCK === 'true') {
    return '/'
  } else {
    return import.meta.env.VITE_API_BASE_URL
  }
}

const ApiRoutes = {
  BASE_URL: getApiBaseUrlRoute(),
  GET_BRANDS: '/brands',
  EDIT_BRAND: (brandId: string) => `/brands/${brandId}`,
  CREATE_BRAND: '/brands',
  GET_BRAND: (brandId: string) => `/brands/${brandId}`,
  CREATE_CREATIVE: (brandId: string) => `/brands/${brandId}/creatives`,
  // GET_CREATIVE_VARIANTS: (creativeId: string) => `/creative/${creativeId}/variants`,
  GET_CREATIVE_VARIANTS: (creativeId: string) =>
    `/api/creative-request/${creativeId}/generated-creatives/`,
  GET_MORE_VARIANTS: '/api/creative-request/generate_more_creatives_via_django/',
  ARCHIVE_CREATIVE: (brandId: string, creativeId: string) =>
    `brands/${brandId}/creatives/${creativeId}/archive`,
  // GET_VARIANT_DIMENSIONS: (creativeId: string, variantId: string) =>
  //   `/creative/${creativeId}/variant/${variantId}`,
  GET_VARIANT_DIMENSIONS: (variantId: string) => `/api/generated-creative/${variantId}/`,
  GET_VARIANT: (id: string) => `/variant/${id}`,
  // GET_CREATIVE: (id: string) => `/creative/${id}`,
  GET_CREATIVE: (creativeId: string) => `/creatives/uuid/${creativeId}`,
  GET_CREATIVES: `/creatives`,
  GET_CREATIVES_BY_BRAND_ID: (id: string) => `/brands/${id}/creatives`,
  GET_MORE_DIMENSIONS: (variantId: number) =>
    `/api/generated-creative/${variantId}/generate_more_dimensions/`,
  LOGIN: '/login',
  SIGNUP: '/signup',
  GOOGLE_SIGNUP: '/google-login',
  GUEST_SIGNUP: '/guest-user-signup',
  GUEST_GOOGLE_SIGNUP: '/guest-user-google-signup',
  ANALYTICS: '/count',
  USER_ME: '/user',
  ASSUME_USER: '/assume-user',
  USER_ASSETS: '/assets',
  EDIT_ASSET: (assetId: string) => `/assets/${assetId}`,
  STOCK_IMAGES: (value: string, pageNo: number) => `/stock-images?query=${value}&page=${pageNo}`,
  STOCK_IMAGES_WITHOUT_QUERY: (pageNo: number) => `/stock-images?page=${pageNo}`,
  SEARCH_STOCK_IMAGES: (value: string, pageNo: number) =>
    `/search-stock-images?query=${value}&page=${pageNo}`,
  GET_IMAGE_BY_BRAND_ID: (brandId: string) => `/brand/${brandId}/assets`,
  REMOVE_BACKGROUND: '/api/remove_image_background/',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  RESET_PASSWORD: '/reset-password',
  GOOGLE_SIGNIN: '/google-login',
  GENERATE_CREATIVE_TEXT: '/generate-creative-text',
  ONCLICK_ONBOARDING: '/oneclick-onboard',
  SAMPLE: '/dummy-500'
}

export { ApiRoutes, AppRoutes }
