import NavigationMenu from '../NavigationMenu'

const SideBar = () => {
  return (
    <div className="flex flex-col mt-[20px] ml-[20px] justify-start items-center">
      <img className="h-5 w-5" src="/assets/svgs/sidebar.svg" alt="side-logo" />
      <NavigationMenu />
    </div>
  )
}

export default SideBar
