import Typography from 'src/components/Typography'

interface Props {
  primaryName: string
  secondaryName?: string
}

const HeaderTitle = ({ primaryName, secondaryName }: Props) => {
  return (
    <>
      <Typography size={24} className="font-[900] leading-[18px] text-text-primary">
        {primaryName} {secondaryName}
      </Typography>
    </>
  )
}

export default HeaderTitle
