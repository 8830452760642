import MainNavigation from './navigation'
import { themeTypes } from './themes'
import * as Sentry from '@sentry/react'
import ErrorFallback from './components/ErrorFallBack'

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <div className={`${themeTypes[0].name} `}>
        <MainNavigation />
      </div>
    </Sentry.ErrorBoundary>
  )
}

export default App
