import { FC, LegacyRef, Ref, useRef } from 'react'

interface OptionalIconProps {
  isSelected?: boolean
  iconPath: FC<React.SVGProps<SVGSVGElement>>
  handleChange: (element: string) => void
}

const OptionalIcon = ({ isSelected = false, iconPath, handleChange }: OptionalIconProps) => {
  const ref = useRef<LegacyRef<SVGSVGElement>>(null!)
  const Image = iconPath

  return (
    <div
      className={`grid justify-center items-center cursor-pointer shadow-md  w-[48px] h-[46px] rounded-full px-[13px] py-[11px] ${
        isSelected ? 'bg-[#608382]' : 'bg-white'
      }`}
      onClick={() => {
        handleChange((ref.current as unknown as HTMLDivElement)!.outerHTML as string)
      }}>
      <Image color={isSelected ? 'white' : 'var(--black)'} ref={ref as Ref<SVGSVGElement>} />
    </div>
  )
}

export default OptionalIcon
