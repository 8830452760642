import { Skeleton } from './skeleton'

const VariantSkeleton = () => {
  return (
    <div className="flex flex-col gap-1  p-2  w-full  rounded-[5px]">
      <Skeleton className="min-h-[180px] w-full  bg-secondary-primary" />
    </div>
  )
}

export default VariantSkeleton
