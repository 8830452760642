import { useEffect, useState } from 'react'
import assets from 'src/assets'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import { trackEvent } from '../../../amplitude'
import { events } from '../../../amplitude/data.ts'

interface ColorPallettesPropsTypes {
  handleCombinationFontChange: (key1: string, key2: string, value: string[]) => void
  handleClose: () => void
  fontsData: any[]
}

const PresetFonts = ({
  handleClose,
  handleCombinationFontChange,
  fontsData
}: ColorPallettesPropsTypes) => {
  const [selectedId, setSelectedId] = useState<number>()

  const selectedFontCombination = fontsData.find((_i, index) => index === selectedId)

  useEffect(() => {
    trackEvent(events.BRAND_CREATION_FONTS_PAIR_VIEWED)
  }, [])
  return (
    <>
      <div
        className="flex flex-col gap-5 cursor-pointer h-[70%] overflow-auto scrollbar-hide"
        style={{ maxHeight: ' calc(100vh - 200px)' }}>
        {fontsData.map((i, index) => {
          return (
            <div
              className="flex gap-[3px]"
              onClick={() => {
                setSelectedId(index)
              }}
              key={index}>
              <div
                className="relative bg-white p-[20px] rounded-[10px] font-[500]"
                style={{
                  backgroundColor: selectedId === index ? '#598081' : '',
                  color: selectedId === index ? '#fff' : '',
                  fontFamily: i.primary_font.fontName
                }}>
                <Typography size={16}>{i.primary_font.fontName}</Typography>
                {selectedId === index && (
                  <div className="absolute right-[-16px] top-[40%]">
                    <img src={assets.svgs.GREEN_TICK} alt="tick" className="w-[60%] m-auto" />
                  </div>
                )}
              </div>

              <div
                className="bg-white p-[20px] rounded-[10px]"
                style={{
                  backgroundColor: selectedId === index ? '#598081' : '',
                  color: selectedId === index ? '#fff' : '',
                  fontFamily: i.secondary_font.fontName
                }}>
                <Typography size={16}>{i.secondary_font.fontName}</Typography>
              </div>
            </div>
          )
        })}
      </div>
      <Button
        disabled={selectedId === undefined}
        label="SAVE"
        onClick={() => {
          if (selectedFontCombination) {
            handleCombinationFontChange('primaryFonts', 'secondaryFonts', [
              selectedFontCombination.primary_font.fontName,
              selectedFontCombination.secondary_font.fontName
            ])
          }
          handleClose()
        }}
        fullWidth={false}
        className="flex my-10 text-[14px] font-[900] px-[30px] py-[7px]"
      />
    </>
  )
}

export default PresetFonts
