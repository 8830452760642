import assets from 'src/assets'
import { AppRoutes } from './routes'
import { events } from '../amplitude/data.ts'

export enum Menu {
  DASHBOARD = 'Dashboard',
  BRANDLIST = 'Brands',
  CREATIVE_AD = 'Creative Ad',
  BRIEFCASE = 'INSIGHTS',
  MONITOR = 'CREATIVES',
  BOOK = 'ASSETS'
}

export const NavigationMenuOptions = [
  {
    id: 1,
    name: Menu.DASHBOARD,
    icon: assets.svgs.DASHBOARD,
    route: AppRoutes.DASHBOARD,
    trackEvent: null
  },
  {
    id: 2,
    name: Menu.BRANDLIST,
    icon: assets.svgs.CREATIVE_AD,
    route: AppRoutes.BRAND_LIST,
    trackEvent: null
  },
  {
    id: 5,
    name: Menu.BOOK,
    icon: assets.svgs.BOOK,
    route: AppRoutes.MY_ASSETS,
    trackEvent: events.USER_FLOW_MY_ASSETS_PAGE_INITIATED
  }
  // {
  //   id: 3,
  //   name: Menu.BRIEFCASE,
  //   icon: assets.svgs.BRIEFCASE,
  //   route: AppRoutes.
  // }
]
