import { ReactNode } from 'react'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle
} from 'src/components/ui/sheet'

interface Props {
  children?: ReactNode
  header: string | ReactNode
  isOpen: boolean
  onClose: () => void
  side?: 'top' | 'bottom' | 'left' | 'right'
  size?: string
  id?: string
}

const Drawer = ({
  children,
  header,
  isOpen = false,
  side = 'right',
  size = '60%',
  onClose,
  id
}: Props) => {
  return (
    <Sheet open={isOpen}>
      <SheetContent id={id} side={side} size={size} onClose={onClose}>
        <SheetHeader>
          <SheetTitle>{header}</SheetTitle>
          <SheetDescription>{children}</SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  )
}

export default Drawer
