export const events = {
  ONBOARDING_SIGNUP_INITIATED: 'onBoarding_signUpInitiated',
  ONBOARDING_SIGNUP_VIEWED: 'onBoarding_signUpViewed',
  ONBOARDING_SIGNUP_SUCCESS: 'onBoarding_signUpSuccess',
  ONBOARDING_SIGNUP_FAILURE: 'onBoarding_signUpFailure',
  ONBOARDING_LOGIN_VIEWED: 'onBoarding_loginPageViewed',
  ONBOARDING_LOGIN_INITIATED: 'onBoarding_loginInitiated',
  ONBOARDING_LOGIN_SUCCESS: 'onBoarding_loginSuccess',
  ONBOARDING_LOGIN_FAILURE: 'onBoarding_loginFailure',
  ONBOARDING_LOGOUT_FAILURE: 'onBoarding_logout',
  ONBOARDING_BRAND_URL_SUBMITTED: 'onboarding_BrandUrlSubmitted',
  ONBOARDING_SIGNUP_FORM_VIEWED: 'onboardingSignUpFormViewed',
  ONBOARDING_SIGNUP_FORM_CLOSED: 'onboardingSignUpFormClosed',
  ONBOARDING_BOOKING_FORM_VIEWED: 'onboardingBookingFormViewed',
  ONBOARDING_BOOKING_FORM_STARTED: 'onboardingBookingFormStarted',

  BRAND_CREATION_INITIATED: 'brandCreation_initiation',
  BRAND_CREATION_VIEWED: 'brandCreation_viewed',
  BRAND_CREATION_COLOUR_PALETTE_SELECTED: 'brandCreation_colourPaletteSelected',
  BRAND_CREATION_COLOUR_PALETTE_VIEWED: 'brandCreation_colourPalettesViewed',
  BRAND_CREATION_MANUAL_FONT_SELECTED: 'brandCreation_manualFontSelected',
  BRAND_CREATION_FONTS_PAIR_VIEWED: 'brandCreation_fontPairViewed',
  BRAND_CREATION_TEXT_FIELD_ADDED: 'brandCreation_textFieldAdded',
  BRAND_CREATION_FONTS_PAIR_SELECTED: 'brandCreation_fontPairSelected',
  BRAND_CREATION_BRAND_CREATED_SUCCESS: 'brandCreation_brandCreatedSuccess',
  BRAND_CREATION_BRAND_CREATED_FAILURE: 'brandCreation_brandCreatedFailure',
  USER_FLOW_BRAND_EDIT_INITIATED: 'userFlow_brandEditInitiated',
  USER_FLOW_BRAND_EDIT_SUCCESS: 'userFlow_brandEditSuccess',
  USER_FLOW_BRAND_EDIT_FAILURE: 'userFlow_brandEditFailure',
  AD_CREATION_VIEWED: 'adCreation_viewed',
  AD_CREATION_EACEL_STOCK_VIEW: 'adCreation_eacelStockViewed',
  AD_CREATION_EACEL_STOCK_SEARCH: 'adCreation_eacelStockSearch',
  AD_CREATION_EACEL_STOCK_SELECTED: 'adCreation_eacelStockSelected',
  AD_CREATION_IMAGE_UPLOAD_INITIATED: 'adCreation_imageUploadInitiated',
  AD_CREATION_MY_ASSET_IMAGE_SELECTED: 'adCreation_myAssetImageSelected',
  AD_CREATION_IMAGE_UPLOAD_SUCCESS: 'adCreation_imageUploadSuccess',
  AD_CREATION_IMAGE_UPDATE_INITIATED: 'adCreation_imageUpdateInitiated',
  AD_CREATION_IMAGE_UPLOAD_ERROR: 'adCreation_imageUploadError',
  AD_CREATION_IMAGE_EDIT: 'adCreation_imageEdit',
  //AD_CREATION_IMAGE_EDIT_SUCCESS: 'adCreation_imageEditSuccess',
  AD_CREATION_TEXT_FIELD_ADDED: 'adCreation_textFieldAdded',
  AD_CREATION_TEXT_FIELD_VALIDATION_ERROR: 'adCreation_textFieldValidationError',
  AD_CREATION_LOGO_SELECTION_SUCCESS: 'adCreation_logoSelectionSuccess',
  AD_CREATION_VARIANTS_PAGE_ERRORED: 'adCreation_variantsPageErrored',
  AD_CREATION_VARIANTS_VIEWED: 'adCreation_variantsViewed',
  AD_CREATION_VIEW_MORE_VARIANTS: 'adCreation_viewMoreVariants',
  AD_CREATION_VARIANT_SELECTED: 'adCreation_variantSelected',
  AD_CREATION_DIMENSION_PAGE_VIEWED: 'adCreation_dimensionsPageViewed',
  AD_CREATION_DIMENSIONS_RENDERED: 'adCreation_dimensionsRendered',
  AD_CREATION_VARIANTS_DOWNLOADED: 'adCreation_variantsDownloaded',
  AD_CREATION_TEMPLATE_VARIANT_DISLIKED: 'adCreation_templateVariantDisliked',
  AD_CREATION_ALTERNATE_AD_COPY_REQUESTED: 'adCreation_alternateAdCopyRequested',
  AD_CREATION_ALTERNATE_AD_COPY_SELECTED: 'adCreation_alternateAdCopySelected',
  USER_FLOW_DASHBOARD_VIEWED: 'userFlow_dashboardViewed',
  USER_FLOW_MY_ASSETS_PAGE_INITIATED: 'userFlow_myAssetsPageInitiated',
  USER_FLOW_MY_ASSETS_PAGE_VIEWED: 'userFlow_myAssetsPageViewed',
  USER_FLOW_MY_ASSETS_BRAND_SELECTED: 'userFlow_myAssetsBrandSelected',
  USER_FLOW_IMAGE_UPLOAD_SUCCESS: 'userFlow_imageUploadSuccess',
  USER_FLOW_IMAGE_UPLOAD_INITIATED: 'userFlow_imageUploadInitiated',
  USER_FLOW_MY_BRANDS_PAGE_VIEWED: 'userFlow_myBrandsPageViewed'
}
