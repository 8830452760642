import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import assets from 'src/assets/index.ts'
import StepperLoader from 'src/components/SteeperLoader'
import Typography from 'src/components/Typography/index.tsx'
import { Button } from 'src/components/ui/button.tsx'
import VariantSkeleton from 'src/components/ui/variantSkeleton.tsx'
import { AppRoutes } from 'src/constants/routes'
import useGetVariants from 'src/hook/useGetVariants'
import { useAppDispatch } from 'src/store'
import { useLazyGetMoreDimensionsQuery } from 'src/store/creatives/creative.api'
import { setSelectedVariantId } from 'src/store/creatives/creative.slice'
import { CreativeSliceState, CreativeVariantStatus } from 'src/store/creatives/creative.type'
import { useStateSelector } from 'src/store/root.reducer'
import { setSnackbar } from 'src/store/snackbar/snackbar.slice.ts'
import { SnackbarType } from 'src/store/snackbar/snackbar.type.ts'
import { trackEvent } from '../../amplitude'
import { events } from '../../amplitude/data.ts'
import Loader from '../../components/Loader'
import PreviewVariant from './PreviewVariant.tsx'
import VariantError from './VariantError'
import { Progress } from '../../components/ui/progress.tsx'
import { defaultOnboardingState, generateVariantSteps } from '../../data/onboarding.ts'
import Tour from '../../Tour'
import { addCookie, fetchCookie, isGuest } from '../../utils/cookies.ts'
import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'

const CreateVariant = () => {
  const { selectedVariantId, projectName, currentCreative } = useStateSelector(
    state => state.creativeSlice
  )
  const user = useStateSelector(state => state.authSlice.user)
  // const onboardingData = useStateSelector(state => state.OnboardingSlice)

  const [variantId, setVariantId] = useState<CreativeSliceState['selectedVariantId'] | null>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { creativeId, brandId } = useParams()
  const {
    data: variants,
    isError,
    errorMsg,
    stepCount,
    loaderCount,
    stepperLoader,
    circularLoader,
    progressLoader
  } = useGetVariants()
  const [getDimensions] = useLazyGetMoreDimensionsQuery()
  const [isLoadingSkeleton, setLoadingSkeleton] = useState(true)
  const [onboardingStep, setOnboardingStep] = useState(0)
  const [run, setRun] = useState<boolean>(false)
  // const [getMoreVariants] = useLazyGetMoreVariantsQuery()
  // const [isLoadMoreBtn, setIsLoadMoreBtn] = useState(true)
  // const [loader, setLoader] = useState(false)
  // const handleErrorMessage = useHandleErrorMessage()
  // const [count, setCount] = useState<number>(0)
  const onBoardingCookie = fetchCookie('onboarding') ?? ''
  const onboarding = onBoardingCookie ? JSON.parse(onBoardingCookie) : defaultOnboardingState

  useEffect(() => {
    if (
      variants &&
      variants.length > 0 &&
      !selectedVariantId &&
      !stepperLoader &&
      !progressLoader &&
      !circularLoader
    ) {
      dispatch(
        setSnackbar({
          message: `${variants.length} variants successfully generated`,
          severity: SnackbarType.SUCCESS
        })
      )
    }
    if (variants && variants.length > 0 && variantId === null) {
      setVariantId(variants[0].id)
      setTimeout(() => {
        isGuest() && onboarding.variants && setRun(true)
      }, 3000)
    }
  }, [variants])

  const handleOnNext = () => {
    if (variantId) {
      getDimensions(variantId)
      dispatch(setSelectedVariantId(variantId))
      navigate(AppRoutes.CREATIVE_VARIANT_DIMENSIONS(creativeId, String(variantId)))
    }
  }

  const handleImageLoad = () => {
    setLoadingSkeleton(false)
  }

  // setInterval(() => {
  //   if (count < 5) {
  //     setCount(count + 1)
  //   } else {
  //     setCount(0)
  //   }
  // }, 15000)

  // const handleMoreVariants = async () => {
  //   trackEvent(events.AD_CREATION_VIEW_MORE_VARIANTS)
  //   setLoader(true)
  //   const body = {
  //     creative_id: currentCreative?.id ?? 0
  //   }
  //   await getMoreVariants({ body }).then(res => {
  //     if (res.isError) {
  //       setIsLoadMoreBtn(false)
  //       handleErrorMessage('No templates remaining', true)
  //     }
  //     if (!res.isError) {
  //       setLoader(false)
  //       refetch()
  //     }
  //   })
  // }

  useEffect(() => {
    // setTimeout(() => {
    //
    // }, 5000)
    // return clearTimeout(interval)
  }, [])

  const handleCallback = (data: CallBackProps) => {
    const { action, index, type, status } = data

    if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      setOnboardingStep(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setOnboardingStep(0)
      setRun(false)
      addCookie('onboarding', JSON.stringify({ ...onboarding, variants: false }))
    }
  }
  return (
    <>
      {isError ? (
        <div className="relative  h-[calc(100vh-216px)] flex rounded-[5px]  justify-start pl-[20px] py-[20px] lg:px-[20rem] lg:py-[10rem] sm:px-[30px] bg-secondary-primary">
          <VariantError errorMessage={errorMsg as string} />
        </div>
      ) : (
        <>
          {stepperLoader && currentCreative?.status === CreativeVariantStatus.PENDING && (
            <div className="flex bg-secondary-primary h-[calc(100vh-216px)] min-h-[400px] justify-center items-center">
              <StepperLoader currentStep={stepCount} />
            </div>
          )}
          {circularLoader && currentCreative?.status === CreativeVariantStatus.PROCESSING && (
            <div className="flex bg-secondary-primary h-[calc(100vh-216px)] min-h-[400px] justify-center items-center">
              <Loader background={'var(--cream)'} />
            </div>
          )}

          {!stepperLoader && !circularLoader && (
            <div className="flex flex-col lg:flex-row gap-[30px] h-[calc(100vh-216px)] ">
              <div
                id="eacel_onboarding_step_nine"
                className="p-[30px] w-[100%] lg:w-[60%] min-h-[480px] overflow-auto  bg-secondary-primary rounded-[5px] scrollbar-hide">
                <div className="grid gap-[30px] grid-cols-auto-fill sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-5">
                  {variants?.map((item, i) => {
                    return (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setSelectedVariantId(item.id))
                          setVariantId(item.id)
                          trackEvent(events.AD_CREATION_VARIANT_SELECTED, {
                            brand_id: brandId,
                            project_name: projectName,
                            session_type: isGuest() ? 'guest' : 'registered',
                            creative_id: creativeId,
                            variant_id: item.id
                          })
                        }}
                        style={{
                          // opacity: (variantId && (variantId === item.id ? 1 : 0.4)) || 1,
                          border:
                            (variantId && (variantId === item.id ? '2px solid #598081' : 'none')) ||
                            'none',
                          boxShadow:
                            (variantId &&
                              (variantId === item.id
                                ? '0px 0px 10px 6px rgba(0, 0, 0, 0.25)'
                                : 'rgba(0, 0, 0, 0.25) 0px 0px 5px 1px')) ||
                            'none'
                        }}
                        className="relative flex flex-col w-[100%] h-[100%] bg-white cursor-pointer rounded-[5px] overflow-hidden ">
                        <div className="h-[100%] min-h-[186px] w-[100%] ">
                          {isLoadingSkeleton && <VariantSkeleton />}
                          <img
                            src={item.imageUrls.SQUARE}
                            alt="image_variant"
                            className="h-[100%] w-[100%] object-contain"
                            style={{ display: isLoadingSkeleton ? 'none' : 'block' }}
                            onLoad={handleImageLoad}
                          />
                        </div>

                        <div className="flex items-center justify-between px-[10px] min-h-[40px] ">
                          <Typography size={10} className={'font-[900] py-[15px]'}>
                            {i + 1}
                          </Typography>
                          <div className="flex gap-1 items-center">
                            {user?.role === 'superuser' && (
                              <Button
                                label={'Send for review'}
                                className="flex py-[2px] px-[8px] text-[10px] font-[800] "
                                onClick={() => {
                                  dispatch(
                                    setSnackbar({
                                      message: `Review sent`,
                                      severity: SnackbarType.SUCCESS
                                    })
                                  )
                                  trackEvent(events.AD_CREATION_TEMPLATE_VARIANT_DISLIKED, {
                                    session_type: isGuest() ? 'guest' : 'registered',
                                    template_Id: item.template,
                                    creative_id: item.adCreativeRequest,
                                    variant_Id: item.id
                                  })
                                }}
                              />
                            )}
                            {variantId && !isNaN(Number(variantId)) && variantId === item.id && (
                              <img src={assets.svgs.GREEN_TICK} alt="tick" />
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {/* {isLoadMoreBtn && (
                  <Button
                    label={!loader ? 'Generate more variants' : 'Generate more variants...'}
                    disabled={loader}
                    className="flex m-auto mt-8"
                    onClick={handleMoreVariants}
                  />
                )} */}
                {progressLoader && currentCreative?.status === CreativeVariantStatus.PROCESSING && (
                  <Progress
                    value={
                      loaderCount === 0 ? 25 : loaderCount === 1 ? 50 : loaderCount === 2 ? 75 : 100
                    }
                    className="w-[100%] mt-5"
                  />
                )}
              </div>

              <div className="relative w-[100%] lg:w-[40%] min-h-[410px] lg:min-h-[480px] ">
                <PreviewVariant
                  selectedVariant={
                    variants?.find(i => i.id === variantId)?.imageUrls.SQUARE as string
                  }
                  handleViewMoreDimensions={handleOnNext}
                />
              </div>
            </div>
          )}
        </>
      )}
      <Tour
        run={run}
        stepIndex={onboardingStep}
        step={generateVariantSteps}
        callback={handleCallback}
      />
    </>
  )
}

export default CreateVariant
