import { createSlice } from '@reduxjs/toolkit'
import { AuthSliceState } from './auth.type'

const initialState: AuthSliceState = {
  user: null
}
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccount: (state, { payload }) => {
      state.user = payload
    }
  }
})
export const { setAccount } = authSlice.actions
export default authSlice.reducer
