import { useState } from 'react'
import { useAppDispatch } from 'src/store'
import { useLazyGetCreativeByIdQuery } from 'src/store/creatives/creative.api'
import { setCurrentCreative } from 'src/store/creatives/creative.slice'
import { Creative } from 'src/store/creatives/creative.type'
import { RtkError } from 'src/store/redux.types'
import { useStateSelector } from 'src/store/root.reducer'
import useHandleErrorMessage from './useIsErrorMessage'

const useGetCreativeById = () => {
  const dispatch = useAppDispatch()
  const handleErrorMessage = useHandleErrorMessage()
  const { currentCreative } = useStateSelector(state => state.creativeSlice)
  const [getCreative, { isLoading, error }] = useLazyGetCreativeByIdQuery()
  const [creativeData, setCreativeData] = useState<Creative>()

  const getCreativeData = async (id: string) => {
    if (currentCreative && id && currentCreative?.uuid === id) {
      setCreativeData(currentCreative)
      return currentCreative
    } else {
      return getCreative(id)
        .then(res => {
          if ('data' in res) {
            setCreativeData(res.data)
            dispatch(setCurrentCreative(res.data as Creative))
            return res.data
          }
        })
        .catch((error: RtkError) => {
          if (error) {
            handleErrorMessage(error.data.error.message, true)
            return null
          }
        })
    }
  }
  return { getCreativeData, data: creativeData, isLoading, error }
}
export default useGetCreativeById
