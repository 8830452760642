import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import assets from 'src/assets'
import Typography from 'src/components/Typography'
import VideoDialog from 'src/components/VideoDialog/index.tsx'
import useHandleErrorMessage from 'src/hook/useIsErrorMessage.ts'
import { useGetBrandsQuery } from 'src/store/brand/brand.api'
import { Brand, BrandsWithCreativeCount } from 'src/store/brand/brand.type'
import { RtkError } from 'src/store/redux.types'
import { trackEvent } from '../../amplitude'
import { events } from '../../amplitude/data.ts'
import { AppRoutes } from '../../constants/routes.ts'
import BrandSelector from './BrandSelector'
import Creatives from './Creatives'
import PerformanceList from './PerformanceList'
import SamplePage from './SamplePage.tsx'
import { isGuest } from 'src/utils/cookies.ts'
import { useStateSelector } from 'src/store/root.reducer.ts'
import GuestAuthDialog from 'src/components/GuestAuthDialog/index.tsx'
import BookCallDialog from 'src/components/CallBookDialog/index.tsx'
import { resetCreative } from '../../store/creatives/creative.slice.ts'
import { useAppDispatch } from '../../store'

const Dashboard = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleErrorMessage = useHandleErrorMessage()
  const [isOpenCreativeBrand, setIsOpenCreativeBrand] = useState(false)
  const [isBrandOpen, setIsBrandOpen] = useState(false)
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>()
  // const [isVideoPlay, setIsVideoPlay] = useState(false)
  const [isBookModal, setIsBookModal] = useState<boolean>(false)
  const [isAllBrand, setIsAllBrand] = useState(true)
  const BrandId = useStateSelector(state => state?.brandSlice?.selectedBrand?.uuid)
  const {
    data: brandList,
    isLoading,
    isError,
    error
  } = useGetBrandsQuery(undefined, {
    refetchOnMountOrArgChange: false
  })
  const [isGuestAuthModal, setIsGuestAuthModal] = useState<boolean>(isGuest() ? true : false)

  useEffect(() => {
    if (isGuest()) {
      if (BrandId) {
        dispatch(resetCreative())
        navigate(AppRoutes.ADD_CREATIVE(BrandId))
      }
    }
    trackEvent(events.USER_FLOW_DASHBOARD_VIEWED)
  }, [])

  useEffect(() => {
    if (isError && error) {
      handleErrorMessage((error as RtkError).data.error.message, true)
    }
  }, [error, isError])

  const handleClose = () => {
    setIsAllBrand(false)
    setIsOpenCreativeBrand(false)
    setIsBrandOpen(false)
  }

  const handleChangeBrandName = (brand: Brand | null) => {
    if (brand) {
      setIsAllBrand(false)
      setSelectedBrand(brand as Brand)
      setIsBrandOpen(false)
      handleClose()
    } else {
      setIsAllBrand(true)
      setSelectedBrand(null)
    }
  }
  const isBrandEmpty = useMemo(() => brandList && brandList.length === 0, [brandList])
  return (
    <>
      <BrandSelector
        brandList={brandList as BrandsWithCreativeCount[]}
        isOpenCreativeBrand={isOpenCreativeBrand}
        isBrandSelection={isBrandOpen}
        isLoading={isLoading}
        handleClose={handleClose}
        handleChangeBrandName={handleChangeBrandName}
        selectedBrand={selectedBrand ?? null}
        isAllBrand={isAllBrand}
      />

      <div className={`flex  flex-1 ${'flex-col'} py-[20px] mx-4 gap-[50px] max-w-[1640px]`}>
        {!isBrandEmpty && (
          <div className="relative flex gap-[20px] flex-wrap">
            {!isBrandEmpty && (
              <div
                onClick={() => {
                  trackEvent(events.BRAND_CREATION_INITIATED)
                  navigate(AppRoutes.ADD_BRAND)
                }}
                className="flex flex-col items-center justify-end gap-2 cursor-pointer rounded-[5px] min-w-[130px] max-w-[1640px] max-h-130px bg-gradient-to-b from-main-primary to-yellow-dark p-[30px]">
                <img src={assets.svgs.ADD} alt="add" className="w-[22px]" />
                <Typography size={14} className="font-[900] text-text-primary">
                  BRAND
                </Typography>
              </div>
            )}
            <div
              onClick={() => {
                setIsAllBrand(false)
                if (!isBrandEmpty) {
                  setIsOpenCreativeBrand(true)
                } else {
                  trackEvent(events.BRAND_CREATION_INITIATED)
                  navigate(AppRoutes.ADD_BRAND)
                }
              }}
              className="flex flex-col items-center justify-end gap-2 cursor-pointer rounded-[5px] max-w-[1640px] max-h-130px bg-gradient-to-b from-main-primary to-yellow-dark p-[30px]">
              <img src={assets.svgs.ADD} alt="add" className="w-[22px]" />
              <Typography size={14} className="font-[900] text-text-primary">
                {!isBrandEmpty ? 'CREATIVE' : 'BRAND'}
              </Typography>
            </div>

            <div className="relative w-[245px] h-[135px] ">
              <VideoDialog />
            </div>
          </div>
        )}
        {!isBrandEmpty && <PerformanceList />}
        {!isBrandEmpty ? (
          <Creatives
            handleOpen={() => {
              setIsAllBrand(true)
              setIsOpenCreativeBrand(true)
              setIsBrandOpen(true)
            }}
            brand={selectedBrand as Brand}
          />
        ) : (
          <SamplePage
            primaryText={"Let's get started".toUpperCase()}
            secondaryText={'Start By Setting up your brand.'}
          />
        )}
      </div>
      <GuestAuthDialog
        isOpen={isGuestAuthModal}
        handleClose={() => {
          setIsBookModal(true)
          setIsGuestAuthModal(false)
        }}
      />
      <BookCallDialog
        isOpen={isBookModal}
        handleClose={() => {
          setIsBookModal(false)
          setIsGuestAuthModal(true)
        }}
      />
    </>
  )
}

export default Dashboard
