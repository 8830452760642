export const creativeSteps = [
  {
    // target: '#creative_step',
    target: '#eacel_onboarding_step_two',
    title: 'AD COPY',
    content:
      'Add a copy of your choice or Click Generate Alternative Ad Copy and we will do the magic',
    disableBeacon: true,
    spotlightClicks: true,
    hideCloseButton: true,
    disableOverlayClose: true,
    hideBackButton: true,
    placement: 'right',
    showSkipButton: true,

    locale: {
      next: 'Next'
    }
  },
  {
    target: '#eacel_onboarding_step_three',
    disableOverlayClose: true,
    spotlightClicks: true,
    title: 'CTA',
    content: 'Have a better copy for your call to action? Go ahead and add it now',
    disableBeacon: true,
    hideCloseButton: true,
    hideBackButton: false,
    showSkipButton: true,
    placement: 'right',

    locale: {
      back: 'Back',
      next: 'Next'
    }
  },
  {
    target: '#eacel_onboarding_step_four',
    spotlightClicks: true,
    title: 'LOGO',
    content: 'We have selected a logo for you.Click here to Update.',
    disableBeacon: true,
    hideCloseButton: true,
    hideBackButton: false,
    disableOverlayClose: true,
    showSkipButton: true,
    placement: 'top',

    locale: {
      back: 'Back',
      next: 'Next'
    }
  },
  {
    target: '#eacel_onboarding_step_five',
    title: 'Foreground image',
    content: 'We have selected an image for your ad, Click here to update it.',
    spotlightClicks: true,
    disableBeacon: true,
    hideCloseButton: true,
    hideBackButton: false,
    disableOverlayClose: true,
    hideFooter: false,
    showSkipButton: true,
    placement: 'top',

    locale: {
      back: 'Back',
      next: 'Next'
    }
  },
  {
    target: '#eacel_onboarding_step_eight',
    title: '',
    content: 'If you have filled all the data please click on Generate to proceed',
    disableBeacon: true,
    disableOverlayClose: true,
    spotlightClicks: true,
    hideCloseButton: true,
    showSkipButton: true,
    placement: 'bottom',

    locale: {
      back: 'Back',
      last: 'Finish'
    }
  }
]

export const uploadImageSteps = [
  {
    target: '#eacel_onboarding_step_six',
    title: 'FOREGROUND IMAGE',
    content:
      'We have fetched images from your brand. Alternatively, you can go though Eacel Stock or Upload an image from your device.',
    disableBeacon: true,
    disableOverlayClose: false,
    spotlightClicks: true,
    hideCloseButton: true,
    showSkipButton: true,
    placement: 'left-start',

    locale: {
      next: ''
    }
  },
  {
    target: '#eacel_onboarding_step_seven',
    title: '',
    content: 'Click on Upload to proceed',
    disableBeacon: true,
    disableOverlayClose: false,
    spotlightClicks: true,
    hideCloseButton: true,
    hideBackButton: true,
    placement: 'top',

    locale: {
      // close: 'Close',
      last: 'Finish'
    }
  }
]

export const generateVariantSteps = [
  {
    target: '#eacel_onboarding_step_nine',
    title: '',
    content: 'Choose a creative of your choice',
    disableBeacon: true,
    disableOverlayClose: true,
    spotlightClicks: true,
    hideBackButton: true,
    hideCloseButton: true,
    showSkipButton: true,
    placement: 'right-start',
    locale: {
      back: 'Back',
      next: 'Next'
    }
  },
  {
    target: '#eacel_onboarding_step_ten',
    title: '',
    content: 'Generate more dimensions for other ad platforms',
    disableBeacon: true,
    disableOverlayClose: true,
    spotlightClicks: true,
    hideCloseButton: true,
    hideFooter: false,
    showSkipButton: true,
    placement: 'left',
    locale: {
      back: 'Back',
      last: 'Finish'
    }
  }
]

export const dimensionSteps = [
  {
    target: '#eacel_onboarding_step_eleven',
    title: '',
    content: 'Now you can download your creative',
    disableBeacon: true,
    disableOverlayClose: true,
    spotlightClicks: true,
    hideBackButton: true,
    hideCloseButton: true,
    showSkipButton: true,
    placement: 'right-start',
    locale: {
      next: '',
      last: 'Finish'
    }
  }
  // {
  //   target: '#eacel_onboarding_step_twelve',
  //   title: '',
  //   content: 'Hurray! you have created your very First creative. Click to view',
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   spotlightClicks: true,
  //   hideCloseButton: true,
  //   hideFooter: false,
  //   last: true,
  //   placement: 'left',
  //   locale: {
  //     back: 'Back',
  //     next: 'Next',
  //     last: ''
  //   }
  // }
]

export const defaultOnboardingState = {
  creative: true,
  upload: true,
  variants: true,
  dimension: true
}
