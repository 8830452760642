import { useEffect, useState } from 'react'
import assets from 'src/assets'
import ColorPicker from 'src/components/ColorPicker'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import { DUMMY_COLOR_OPTIONS } from 'src/constants'
import { trackEvent } from 'src/amplitude'
import { events } from 'src/amplitude/data.ts'

interface colorTypes {
  primary: string
  secondary: string
  accent: string
}

interface ColorPallettesPropsTypes {
  handleChange: (key: string, value: string) => void
  handleClose: () => void
  colors: colorTypes
}

const ColorPallettes = ({ handleChange, handleClose, colors }: ColorPallettesPropsTypes) => {
  const [selectedId, setSelectedId] = useState<number>()
  const handleSaveColors = () => {
    handleChange(
      'color.primary',
      selectedId ? DUMMY_COLOR_OPTIONS[selectedId - 1].primary : colors.primary
    )
    handleChange(
      'color.secondary',
      selectedId ? DUMMY_COLOR_OPTIONS[selectedId - 1].secondary : colors.secondary
    )
    handleChange(
      'color.accent',
      selectedId ? DUMMY_COLOR_OPTIONS[selectedId - 1].accent : colors.accent
    )
    if (selectedId) {
      trackEvent(events.BRAND_CREATION_COLOUR_PALETTE_SELECTED, {
        selected_palette_name: DUMMY_COLOR_OPTIONS[selectedId - 1].name
      })
    }
    handleClose()
  }
  useEffect(() => {
    trackEvent(events.BRAND_CREATION_COLOUR_PALETTE_VIEWED)
  }, [])
  return (
    <div className="flex flex-col gap-10">
      <div className="">
        <Typography
          size={14}
          className="flex font-[700] leading-[18px] mb-[10px] text-text-primary">
          YOUR PALETTE
        </Typography>
        <div className="flex gap-[20px] flex-wrap">
          <div className="flex gap-[20px] flex-wrap">
            <ColorPicker
              name="Primary"
              selectedColor={
                selectedId ? DUMMY_COLOR_OPTIONS[selectedId - 1].primary : colors.primary
              }
              handleColor={() => {}}
              disabled
            />
            <ColorPicker
              name="Secondary"
              selectedColor={
                selectedId ? DUMMY_COLOR_OPTIONS[selectedId - 1].secondary : colors.secondary
              }
              handleColor={() => {}}
              disabled
            />
            <ColorPicker
              name="Accent"
              selectedColor={
                selectedId ? DUMMY_COLOR_OPTIONS[selectedId - 1].accent : colors.accent
              }
              handleColor={() => {}}
              disabled
            />
          </div>
        </div>
      </div>
      <div>
        <Typography
          size={14}
          className="flex font-[700]  leading-[18px] mb-[10px] text-text-primary">
          RECOMMENDED
        </Typography>

        <div
          className="flex gap-[30px] flex-wrap overflow-auto scrollbar-hide"
          style={{ maxHeight: ' calc(100vh - 420px)' }}>
          {DUMMY_COLOR_OPTIONS.map(i => {
            return (
              <div
                style={{ backgroundColor: i.id === selectedId ? 'white' : 'transparent' }}
                className="relative flex flex-col cursor-pointer  p-[10px] w-fit rounded-[5px] "
                onClick={() => setSelectedId(i.id)}
                key={i.id}>
                <div className="flex">
                  <div
                    style={{ backgroundColor: i.primary }}
                    className="w-[40px] h-[40px]  rounded-tl-[5px] rounded-bl-[5px] "
                  />
                  <div style={{ backgroundColor: i.secondary }} className="w-[40px] h-[40px]  " />
                  <div
                    style={{ backgroundColor: i.accent }}
                    className="w-[40px] h-[40px]  rounded-tr-[5px] rounded-br-[5px] "
                  />
                </div>

                <Typography size={12} className="font-[700] leading-[18px] mt-2  text-text-primary">
                  {i.name}
                </Typography>

                {i.id === selectedId && (
                  <div className="absolute left-1/2 transform -translate-x-1/2 bottom-[-6px]">
                    <img src={assets.svgs.GREEN_TICK} alt="tick" className="w-[60%] m-auto" />
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <Button
          disabled={!selectedId}
          label="SAVE COLORS"
          onClick={() => handleSaveColors()}
          className="flex mt-10 text-[14px] font-[900] "
        />
      </div>
    </div>
  )
}

export default ColorPallettes
