export interface Creative {
  uuid?: string
  id?: number
  brand?: {
    uuid: string
    name: string | null
  }
  headline: string | null
  punchline: string | null
  ctaText: string | null
  ctaIcon?: string | null
  logoAssetId?: string | null
  foregroundAssetId?: string | null
  status?: CreativeVariantStatus
  date?: string | null
  variantId?: string | null
  projectName?: string | null
  foregroundImageUrl?: string | null
}

export interface CreativeVariant {
  uuid: string
  creativeId: string
  url: string
}

export interface GetMoreVariant {
  creative_id: number
}

export interface selectedFile {
  input_image: File
}

export interface CreativeVariantWithDimensions extends CreativeVariant {
  dimensions: CreativeVariantWithDimensions[]
}
export enum CreativeVariantStatus {
  PENDING = 'pending',
  PROCESSED = 'processed',
  PROCESSING = 'processing',
  FAILED = 'failed'
}

export type CreativeVariantWithStatus =
  | {
      status: CreativeVariantStatus.PROCESSING
      generatedCreatives: generatedCreativesTypes[]
    }
  | {
      status: CreativeVariantStatus.FAILED
      errorMessage: string
    }
  | {
      status: CreativeVariantStatus.PROCESSED
      generatedCreatives: generatedCreativesTypes[]
    }
  | {
      status: CreativeVariantStatus.PENDING
      generatedCreatives: generatedCreativesTypes[]
    }

export type CreativeVariantDimensionsWithStatus =
  | {
      status: CreativeVariantStatus.PROCESSING
    }
  | {
      status: CreativeVariantStatus.PENDING
    }
  | {
      id: number
      status: CreativeVariantStatus.PROCESSED
      imageUrls: ImageUrlsType
      errorMessage: string | null
    }
  | {
      status: CreativeVariantStatus.FAILED
      errorMessage: string
    }

export interface CreativeVariantWithDimensions {
  uuid: string
  creativeId: string
  variantId: string
  url: string
  size: VariantSize
  brandName?: string
}
export enum VariantSize {
  POTRAIT = 'Potrait',
  LANDSCAPE = 'Landscape',
  SQUARE = 'Square'
}

export interface CreativeSliceState {
  currentCreative: Creative | null
  selectedVariantId?: number | null
  projectName?: string | null
  variants?: generatedCreativesTypes[] | null
}

export interface generatedCreativesTypes {
  id: number
  status: string
  imageUrls: ImageUrlsType
  errorMessage: string | null
  template?: number
  adCreativeRequest?: number
}

export type ImageUrlsType =
  | {
      SQUARE: string
    }
  | {
      SQUARE: string
      LANDSCAPE: string
      PORTRAIT: string
    }

export interface generateAdCopyTypes {
  headline: string
  punchline: string
}
