import { googleLogout } from '@react-oauth/google'
import * as Sentry from '@sentry/react'
import { useNavigate } from 'react-router-dom'
import assets from 'src/assets'
import { AppRoutes } from 'src/constants/routes'
import { useAppDispatch } from 'src/store'
import { RESET_STORE } from 'src/store/redux.types'
import { removeCookie } from 'src/utils/cookies'
import { clearSession, trackEvent } from '../../amplitude'
import { events } from '../../amplitude/data.ts'

type NavBarProps = {
  isGuest: boolean
}
const Navbar = ({ isGuest }: NavBarProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleOnLogout = () => {
    googleLogout()
    Sentry.setUser(null)
    removeCookie('auth_token')
    removeCookie('isGuest')
    removeCookie('onboarding')

    dispatch({ type: RESET_STORE })
    trackEvent(events.ONBOARDING_LOGOUT_FAILURE)
    clearSession()
    // navigate(AppRoutes.LOGIN)
    window.location.replace(AppRoutes.LOGIN)
  }
  return (
    <div className="flex justify-between py-3 mx-4  border-b-2 border-text-secondary-600 pr-[40px]">
      <img
        src="/assets/svgs/logo.svg"
        alt="logo"
        onClick={() => !isGuest && navigate(AppRoutes.DASHBOARD)}
        className="cursor-pointer w-[107px] h-[34px]"
      />

      {!isGuest && (
        <div className="flex justify-center gap-2">
          <img
            src={assets.svgs.USER}
            // src="/assets/svgs/user.svg"
            alt="user-logo"
            width={30}
            onClick={() => navigate(AppRoutes.ACCOUNT)}
            className="cursor-pointer"
          />
          <img
            src={assets.svgs.LOGOUT}
            onClick={() => handleOnLogout()}
            alt="logout-logo"
            width={40}
            className="cursor-pointer"
          />
        </div>
      )}
    </div>
  )
}

export default Navbar
