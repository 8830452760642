import * as Sentry from '@sentry/react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { AppRoutes } from 'src/constants/routes'
import useGetUserData from 'src/hook/useGetUserData'
import Account from 'src/pages/Account'
import ForgotPassword from 'src/pages/Auth/ForgotPassword'
import Login from 'src/pages/Auth/Login'
import ResetPassword from 'src/pages/Auth/ResetPasswors'
import SignUp from 'src/pages/Auth/SignUp'
import BrandPage from 'src/pages/Brand'
import AddEditBrand from 'src/pages/Brand/AddEditBrand'
import CreativeLayout from 'src/pages/Creative'
import CreateVariant from 'src/pages/Creative/CreateVariant'
import GenerateCreative from 'src/pages/Creative/GenerateCreative'
import RenderDimensions from 'src/pages/Creative/RenderDimensions'
import Dashboard from 'src/pages/Dashboard'
import Home from 'src/pages/Home'
import MyAssets from 'src/pages/MyAssets'
import PrivateRouteOutlet from './PrivateRoute'
import PublicRouteOutlet from './PublicRoute'
import AssumeUserPage from 'src/pages/AssumeUser'
import Onboarding from 'src/pages/Onboarding'
import OnboardingRoute from './OnboardingRoute'
import ErrorPage from '../pages/Error'

const MainNavigation = () => {
  useGetUserData()
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

  return (
    <SentryRoutes>
      <Route element={<PrivateRouteOutlet />}>
        <Route path={AppRoutes.DASHBOARD} element={<Dashboard />} />
        <Route path={AppRoutes.ASSUME_USER} element={<AssumeUserPage />} />
        <Route path={AppRoutes.HOME} element={<Home />} />
        <Route path={AppRoutes.MY_ASSETS} element={<MyAssets />} />
        <Route path={AppRoutes.ACCOUNT} element={<Account />} />
        <Route path={AppRoutes.BRAND_LIST} element={<BrandPage />} />
        <Route path={AppRoutes.ADD_BRAND} element={<AddEditBrand />} />
        <Route path={AppRoutes.EDIT_BRAND()} element={<AddEditBrand />} />
        <Route path={AppRoutes.ERROR} element={<ErrorPage />} />
        <Route
          element={
            <CreativeLayout>
              <Outlet />
            </CreativeLayout>
          }>
          <Route path={AppRoutes.ADD_CREATIVE()} element={<GenerateCreative />} />
          <Route path={AppRoutes.CREATIVE_VARIANTS()} element={<CreateVariant />} />
          <Route path={AppRoutes.CREATIVE_VARIANT_DIMENSIONS()} element={<RenderDimensions />} />
        </Route>
      </Route>
      <Route element={<PublicRouteOutlet />}>
        <Route
          path={AppRoutes.LOGIN}
          element={
            <>
              <Login />
            </>
          }
        />
        <Route path={AppRoutes.SIGN_UP} element={<SignUp />} />
        <Route path={AppRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={AppRoutes.RESET_PASSWORD} element={<ResetPassword />} />
      </Route>
      <Route element={<OnboardingRoute />}>
        <Route path={AppRoutes.ONBOARDING} element={<Onboarding />} />
      </Route>
    </SentryRoutes>
  )
}

export default MainNavigation
