import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { trackEvent } from 'src/amplitude'
import { events } from 'src/amplitude/data'
import Loader from 'src/components/Loader'
import { useAppDispatch } from 'src/store'
import { setSelectedBrand } from 'src/store/brand/brand.slice'
import { Brand } from 'src/store/brand/brand.type'
import { setCurrentCreative } from 'src/store/creatives/creative.slice'
import { Creative } from 'src/store/creatives/creative.type'
import { useLazyOnClickOnboardQuery } from 'src/store/onboarding/onboarding.api'
import { addCookie, removeCookie } from 'src/utils/cookies'
import Typography from '../../components/Typography'
import assets from '../../assets'
import { AppRoutes } from '../../constants/routes.ts'
import { defaultOnboardingState } from '../../data/onboarding.ts'
import { SnackbarType } from 'src/store/snackbar/snackbar.type.ts'
import { setSnackbar } from 'src/store/snackbar/snackbar.slice.ts'
import { URL_REGEX } from 'src/utils/regex.ts'

const isValidUrl = (url: string) => {
  return URL_REGEX.test(url)
}

const Onboarding = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const brandUrl = searchParams.get('brandurl')
  const [getBrandByUrl] = useLazyOnClickOnboardQuery()
  const getBrand = async () => {
    await getBrandByUrl({ brandUrl: brandUrl as string }).then(res => {
      addCookie('isGuest', 'true')
      addCookie('auth_token', res?.data?.user?.token as string)
      addCookie('onboarding', JSON.stringify(defaultOnboardingState))

      const creativeData = {
        ...res?.data?.creative,
        foregroundAssetId: res?.data?.creative_image?.uuid,
        foregroundImageUrl: res?.data?.creative_image?.presignedUrl
      }

      dispatch(setCurrentCreative(creativeData as Creative))
      dispatch(setSelectedBrand(res?.data?.brand as Brand))

      // const creativeImage = res?.data?.creative_image
      // if (creativeImage) {
      //   dispatch(setCreativeImage(creativeImage))
      // }

      trackEvent(events.ONBOARDING_BRAND_URL_SUBMITTED, {
        brandURL: brandUrl,
        entryPoint: 'landingPage',
        session_type: 'guest'
      })
      navigate(AppRoutes.ADD_CREATIVE(res?.data?.brand?.uuid))
    })
  }

  // const convertToBase64 = async (url: string | URL) => {
  //   try {
  //     const response = await fetch(url)
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch image')
  //     }
  //     const blob = await response.blob()
  //     return new Promise<string>((resolve, reject) => {
  //       const reader = new FileReader()
  //       reader.onloadend = () => {
  //         if (typeof reader.result === 'string') {
  //           resolve(reader.result)
  //         } else {
  //           reject(new Error('Failed to read image'))
  //         }
  //       }
  //       reader.onerror = () => {
  //         reject(new Error('Failed to read image'))
  //       }
  //       reader.readAsDataURL(blob)
  //     })
  //   } catch (error: any) {
  //     throw new Error('Failed to load image: ' + error.message)
  //   }
  // }

  useEffect(() => {
    removeCookie('auth_token')
    removeCookie('isGuest')
    removeCookie('onboarding')

    if (brandUrl) {
      if (isValidUrl(brandUrl)) {
        getBrand().then()
      } else {
        dispatch(
          setSnackbar({
            isOpen: true,
            autoClose: 3000,
            message: 'Brand URL is not valid',
            severity: SnackbarType.ERROR,
            position: 'top-center'
          })
        )
        navigate(AppRoutes.LOGIN)
      }
    }
  }, [brandUrl])
  return (
    <div className="h-screen flex-1 flex flex-col items-center justify-center">
      <div className={'flex h-[170px]'}>
        <Loader />
      </div>
      <Typography className={'text-center uppercase font-bold'} size={21}>
        Your <span className={'text-main-secondary'}> brand creative</span> is on its way
      </Typography>

      <div className={'flex flex-col items-center justify-center mt-6 gap-4'}>
        <div className={'flex gap-2 items-center justify-center'}>
          <img src={assets.svgs.CIRCLE_BULLETS_ICON} />
          <Typography size={14} className={'text-center font-semibold'}>
            Fetching your brand information
          </Typography>
        </div>
        <div className={'flex gap-2 items-center justify-center'}>
          <img src={assets.svgs.CIRCLE_BULLETS_ICON} />
          <Typography size={14} className={'text-center font-semibold'}>
            Leveraging AI to generate ad copies
          </Typography>
        </div>
        <div className={'flex gap-2 items-center justify-center'}>
          <img src={assets.svgs.CIRCLE_BULLETS_ICON} />
          <Typography size={14} className={'text-center font-semibold'}>
            We are curating your ad creative
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default Onboarding
