import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'src/store'
import { useCreateAssetMutation, useEditAssetMutation } from 'src/store/brand/brand.api'
import { CreateAssetPayload, UserAssetWithProvider } from 'src/store/brand/brand.type'
import { useStateSelector } from 'src/store/root.reducer'
import { setSnackbar } from 'src/store/snackbar/snackbar.slice'
import { SnackbarType } from 'src/store/snackbar/snackbar.type'
import { convertFileToDataURL } from 'src/utils/convertFileToDataURL'
import { ErrorType, parseRTKError, RTKError } from 'src/utils/rtkErrorParser'

const uploadImage = async (url: string, file: File) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type
      },
      body: file
    })
    if (!response.ok) {
      throw {
        type: 'HTTP_ERROR',
        httpStatus: response.status,
        error: `Upload image failed with status code: ${response.status} and response: ${response.text}`,
        details: response.text
      }
    }
  } catch (error) {
    throw {
      type: 'FETCH_ERROR',
      error: 'Network Request Failed while trying to upload Image',
      details: {}
    }
  }
}

export class UploadError extends Error implements RTKError {
  details: Record<string, RTKError>
  type: ErrorType = 'CUSTOM_ERROR'
  error: string

  constructor(errors: Record<string, RTKError>) {
    super(`Error while uploading some assets. Please try again`)
    this.error = `Error while uploading some assets. Please try again`
    this.details = errors
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

const useUploadAsset = () => {
  const { brandId } = useParams()
  const dispatch = useAppDispatch()
  const [isUploading, setIsUploading] = useState(false)
  const [createAsset] = useCreateAssetMutation()
  const [editAsset] = useEditAssetMutation()

  const BrandId = useStateSelector(state => state.brandSlice.selectedBrand?.uuid)

  const uploadAsset = async (
    assetRequestList: CreateAssetPayload[],
    fileList: FileList | File[]
  ) => {
    setIsUploading(true)
    let userAssets: UserAssetWithProvider[]
    try {
      userAssets = await createAsset(assetRequestList).unwrap()
    } catch (error) {
      throw parseRTKError(error)
    }
    const errors: Record<string, RTKError> = {}
    const promises = userAssets.map(async (asset, index) => {
      try {
        await uploadImage(asset.presignedUrl, fileList[index])
        const url = await convertFileToDataURL(fileList[index])
        return { ...asset, presignedUrl: url }
      } catch (error) {
        errors[fileList[index].name] = error as RTKError
        return asset
      }
    })
    const uploadedAssets: UserAssetWithProvider[] = await Promise.all(promises)
    if (uploadedAssets.length > 0) {
      if (!brandId && BrandId) {
        const body = {
          brandId: BrandId
        }
        editAsset({ assetId: userAssets[0].uuid, values: body }).then(res => {
          if (res) {
            if (!brandId) {
              dispatch(
                setSnackbar({
                  severity: SnackbarType.SUCCESS,
                  message: 'Logo uploaded successfully!'
                })
              )
            }
          }
        })
      }
    }
    if (Object.keys(errors).length > 0) {
      const error = new UploadError(errors)
      throw error
    }

    setIsUploading(false)
    return uploadedAssets
  }

  return { uploadAsset, isUploading }
}

export default useUploadAsset
