import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import assets from '../../assets'

interface LoaderProps {
  currentStep: number
}

const StepBreakPoint = ({ isProcessed }: { isProcessed: boolean }) => {
  return (
    <div
      className={`h-[40px] w-[40px] lg:h-[57px] lg:w-[57px] rounded-full flex items-center justify-center ${
        isProcessed ? 'bg-main-primary' : 'bg-text-secondary'
      }`}>
      {isProcessed && <img src={assets.svgs.CHECKED_ICON} />}
    </div>
  )
}

const StepperLoader = ({ currentStep }: LoaderProps) => {
  const getProgress = () => {
    switch (currentStep) {
      case 0:
        return 10
      case 1:
        return 25
      case 2:
        return 50
      case 3:
        return 75
      case 4:
        return 100
      default:
        return 0
    }
  }
  return (
    <div className="flex items-center h-[200px] w-[200px] md:h-[300px] md:w-[300px] lg:h-[500px] lg:w-[500px]  rounded-full justify-center relative">
      <div
        className={
          'absolute h-[195px] w-[195px] md:h-[295px] md:w-[295px] lg:h-[492px] gap-5 lg:w-[492px] border-[1px] rounded-full  border-dashed border-black'
        }
      />
      <CircularProgressbar
        value={getProgress()}
        text={``}
        strokeWidth={2}
        className={'z-[0]'}
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 0,
          strokeLinecap: 'round',
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'

          // Text size
          textSize: '16px',

          // How long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: 1,

          // Can specify path transition in more detail, or remove it entirely
          // pathTransition: 'none',

          // Colors
          pathColor: `#284d4e`,
          trailColor: 'transparent',
          backgroundColor: 'transparent'
        })}
      />
      <div className={'absolute flex flex-col gap-4 z-[2]'}>
        <img
          src={assets.images.PROGRESS_LOADER_VECTOR}
          className={
            'h-[100px] w-[100px] md:h-[150px] md:w-[150px] lg:h-[350px] lg:w-[350px] mt-[-30px] lg:mt-[-50px]'
          }
        />
        <div className={'flex items-center flex-col gap-1'}>
          <p className={`font-light text-[10px] ${currentStep <= 1 ? 'selectedStepper' : ''}`}>
            Analysing
          </p>
          <p className={`font-light text-[10px] ${currentStep === 2 ? 'selectedStepper' : ''}`}>
            Crafting Components
          </p>
          <p className={`font-light text-[10px] ${currentStep >= 3 ? 'selectedStepper' : ''}`}>
            Generating Variants
          </p>
        </div>
      </div>
      <div className={'absolute top-[-20px]'}>
        <StepBreakPoint isProcessed={currentStep >= 0} />
      </div>
      <div className={'absolute right-[-20px]'}>
        <StepBreakPoint isProcessed={currentStep >= 1} />
      </div>
      <div className={'absolute bottom-[-20px]'}>
        <StepBreakPoint isProcessed={currentStep >= 3 || currentStep === 2} />
      </div>
      <div className={'absolute left-[-20px]'}>
        <StepBreakPoint isProcessed={currentStep >= 4 || currentStep === 3} />
      </div>
    </div>
  )
}

export default StepperLoader
