import React from 'react'
import { useLocation } from 'react-router-dom'
import { AppRoutes } from 'src/constants/routes'
import Navbar from '../Navbar'
import SideBar from '../SideBar'
import { isGuest } from '../../utils/cookies.ts'

interface Props {
  children: React.ReactNode
}

export const PUBLIC_ROUTES = [
  AppRoutes.LOGIN,
  AppRoutes.SIGN_UP,
  AppRoutes.FORGOT_PASSWORD,
  AppRoutes.RESET_PASSWORD,
  AppRoutes.ONBOARDING
]

const Layout = ({ children }: Props) => {
  const { pathname } = useLocation()

  if (PUBLIC_ROUTES.includes(pathname)) {
    return <>{children}</>
  }

  return (
    <div className="flex relative">
      {!isGuest() && <SideBar />}
      <div className={'flex flex-1 flex-col '}>
        <Navbar isGuest={isGuest()} />
        <div
          className={`content-height child-content pr-[40px] overflow-auto  scrollbar-hide ${
            isGuest() ? 'ml-6' : ''
          }`}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Layout
