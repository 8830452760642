import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SnackbarSliceState, SnackbarType } from './snackbar.type'

const initialState: SnackbarSliceState = {
  isOpen: false,
  autoClose: 5100,
  message: '',
  severity: SnackbarType.SUCCESS,
  position: 'bottom-center'
}

const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (state, { payload }: PayloadAction<SnackbarSliceState>) => {
      const { isOpen, message, autoClose, severity, position } = payload
      state.isOpen = isOpen || !!message ? true : false
      state.autoClose = autoClose ?? 5100
      state.message = message ?? ''
      state.severity = severity ?? SnackbarType.SUCCESS
      state.position = position ?? 'bottom-center'
    }
  }
})
export const { setSnackbar } = snackbar.actions
export default snackbar.reducer
